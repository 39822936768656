<template>
  <div class="chat" style="z-index: 1000">
    <div class="chat-messages custom-scrollbar" id="scrollerrr_chats">
      <div class="no-messages" v-if="$root.messages == '' || !$root.messages">
        <i class="material-icons">forum</i>
        <span>No messages yet</span>
      </div>
      
      <div v-else v-for="(message, index) in filteredMessages"
           :key="index"
           class="chat-message"
           ref="chatMessages"
           :class="{'mentioned': message.text.includes(`@${$root.user.name}`)}">
        
        <div class="message-avatar">
          <img @click="$root.viewUserProfile(message.username)"
               :src="`${this.$root.API_PREFIX}/api/DisplayPicture?name=${message.username}`"
               alt="User Profile"
               v-tooltip="'View profile'"
               class="profile-picture" />
        </div>

        <div class="message-content">
          <div class="message-header">
            <template v-if="message.label_no">
              <LabelComponent :labelNo="message.label_no" />
            </template>
            <NewLevelBadgeComponent
                class="level-badge"
                :username="message.username" />
            <span class="username inlineMSG"
                  @click="$root.viewUserProfile(message.username)"
                  v-tooltip="'View profile'">
              {{ message.username }}
            </span>
            <div class="message-actions">
              <button @click="toggleMessageMenu($event, message.username)" 
                      class="action-dots"
                      v-tooltip="'Message actions'">
                <i class="material-icons">more_vert</i>
              </button>
              <!-- Message Actions Menu -->
              <div v-if="showMessageMenu && selectedUser === message.username" 
                   class="message-menu"
                   :style="messageMenuPosition">
                <button @click="viewProfile" class="menu-item">
                  <i class="material-icons">person</i>
                  {{ selectedUser === $root.user.name ? 'View Your Profile' : 'View Profile' }}
                </button>
                <button @click="mentionUser(message.username)" 
                        class="menu-item"
                        v-if="selectedUser !== $root.user.name">
                  <i class="material-icons">alternate_email</i>
                  Mention
                </button>
                <button @click="toggleMute" 
                        class="menu-item"
                        v-if="selectedUser !== $root.user.name">
                  <i class="material-icons">{{ isMuted ? 'volume_up' : 'volume_off' }}</i>
                  {{ isMuted ? 'Unmute' : 'Mute' }}
                </button>
              </div>
            </div>
          </div>
          <div class="message-text" 
               :class="{
                 'expanded': expandedMessages[message.id], 
                 'can-expand': isMessageExpandable(message.text)
               }">
            <span v-html="processMessageText(message.text)"></span>
            <button v-if="isMessageExpandable(message.text)"
                    @click="toggleMessageExpand(message.id)"
                    class="expand-button">
              {{ expandedMessages[message.id] ? 'Show Less' : 'Show More' }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="chat-footer">
      <div class="chat-input-container">
        <div class="input-wrapper">
          <input v-model="newMessage"
                 @keyup.enter="sendMessage"
                 @input="handleInput"
                 ref="messageInput"
                 class="chat-input-field"
                 placeholder="Type a message..."
                 :disabled="!$root.logged_in"
                 maxlength="200" />
          
          <!-- Mention Suggestions -->
          <div v-if="showMentionSuggestions && mentionSuggestions.length > 0" 
               class="mention-suggestions">
            <div v-for="user in mentionSuggestions.slice(0, 3)" 
                 :key="user"
                 @click="selectMention(user)"
                 class="mention-suggestion">
              <img :src="`${this.$root.API_PREFIX}/api/DisplayPicture?name=${user}`" 
                   alt="User Avatar" 
                   class="mention-avatar" />
              <span>{{ user }}</span>
            </div>
          </div>
          
          <div class="input-actions">
            <div class="emoji-picker-container">
              <button @click="showEmojiPicker = !showEmojiPicker" 
                      class="action-button emoji-button"
                      :class="{ 'active': showEmojiPicker }">
                <i class="material-icons">emoji_emotions</i>
              </button>
              
              <div v-if="showEmojiPicker" class="emoji-picker">
                <div class="emoji-grid">
                  <div v-for="emoji in availableEmojis" 
                       :key="emoji.code" 
                       class="emoji-item"
                       @click="insertEmoji(emoji.code)">
                    <img :src="require(`@/assets/chatEmojis/${emoji.code}.${['joker', 'cards', 'gems', 'cute'].includes(emoji.code) ? 'png' : 'gif'}`)" 
                         :alt="emoji.code"
                         width="24" 
                         height="24">
                  </div>
                </div>
              </div>
            </div>
            
            <button @click="sendMessage" 
                    class="action-button send-button"
                    :disabled="!$root.logged_in">
              <i class="material-icons">send</i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- User Context Menu -->
    <div v-if="showUserMenu" 
         class="user-context-menu" 
         :style="userMenuPosition">
      <div class="menu-header">
        <img :src="`${this.$root.API_PREFIX}/api/DisplayPicture?name=${selectedUser}`" 
             alt="User" 
             class="menu-avatar">
        <span class="menu-username">{{ selectedUser }}</span>
      </div>
      <div class="menu-options">
        <button @click="viewProfile" class="menu-option">
          <i class="material-icons">person</i>
          {{ selectedUser === $root.user.name ? 'View Your Profile' : 'View Profile' }}
        </button>
        <button @click="tipUser" 
                class="menu-option"
                v-if="selectedUser !== $root.user.name">
          <i class="material-icons">attach_money</i>
          Send Tip
        </button>
        <button @click="toggleMute" 
                class="menu-option"
                v-if="selectedUser !== $root.user.name">
          <i class="material-icons">{{ isMuted ? 'volume_up' : 'volume_off' }}</i>
          {{ isMuted ? 'Unmute User' : 'Mute User' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import LabelComponent from "@/components/LabelComponent.vue";
import NewLevelBadgeComponent from "@/components/NewLevelBadgeComponent.vue";


export default {
  data() {
    return {
      chatMessages: [],
      messages: [
        {
          text: "Hello, how are you?",

          username: "321e",
        },
        {
          text: "I am doing well, thanks!",

          username: "ewqe32",
        },
        // Add more message objects here
      ],
      newMessage: "",
      onlineUsers: 10, // Set this number according to your implementation
      showEmojiPicker: false,
      availableEmojis: [
        { code: 'rage', label: 'Rage' },
        { code: 'chicken', label: 'Chicken' },
        { code: 'smile', label: 'Smile' },
        { code: 'cash', label: 'Cash' },
        { code: 'bag', label: 'Bag' },
        { code: 'bunny', label: 'Bunny' },
        { code: 'shroom', label: 'Shroom' },
        { code: 'joker', label: 'Joker' },
        { code: 'cards', label: 'Cards' },
        { code: 'gems', label: 'Gems' },
        { code: 'cute', label: 'Cute' }
      ],
      showUserMenu: false,
      selectedUser: null,
      userMenuPosition: {
        top: '0px',
        left: '0px'
      },
      mutedUsers: [],
      showMessageMenu: false,
      messageMenuPosition: {
        top: '0px',
        left: '0px'
      },
      expandedMessages: {},
      showMentionSuggestions: false,
      mentionSuggestions: [],
      mentionQuery: '',
      recentChatters: new Set(),
    };
  },
  components: { LabelComponent, NewLevelBadgeComponent },
  sockets: {
    add_message: function () {
     
      const element = document.getElementById("scrollerrr_chats");


      if (element) {
        setTimeout(
          function () {
            element.scrollBy(0, element.scrollHeight);
          }.bind(this),
          10
        );
      }
    },
  },
  mounted() {
    const element = document.getElementById("scrollerrr_chats");
    // this.scrollChatToBottom()
    if (element) {
      setTimeout(
        function () {
          element.scrollBy(0, element.scrollHeight);
        }.bind(this),
        510
      );
    }

    // Add click outside listener
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    // Remove click outside listener
    document.removeEventListener('click', this.handleClickOutside);
  },
  computed: {
    filteredMessages() {
      return this.$root.messages?.filter(message => !this.mutedUsers.includes(message.username)) || [];
    },
    isMuted() {
      return this.selectedUser && this.mutedUsers.includes(this.selectedUser);
    }
  },
  created() {
    // Load muted users by checking individual cookies
    this.mutedUsers = [];
    const allCookies = this.$cookies.keys();
    allCookies.forEach(key => {
      if (key.startsWith('muted_')) {
        const username = key.replace('muted_', '');
        this.mutedUsers.push(username);
      }
    });
  },
  methods: {
    handleClickOutside(event) {
      const messageMenu = document.querySelector('.message-menu');
      const actionDots = document.querySelector('.action-dots');
      const emojiPicker = document.querySelector('.emoji-picker');
      const emojiButton = document.querySelector('.emoji-button');
      const userMenu = document.querySelector('.user-context-menu');
      
      if (messageMenu && !messageMenu.contains(event.target) && 
          (!actionDots || !actionDots.contains(event.target))) {
        this.showMessageMenu = false;
      }
      
      if (emojiPicker && !emojiPicker.contains(event.target) && 
          !emojiButton.contains(event.target)) {
        this.showEmojiPicker = false;
      }
      
      if (userMenu && !userMenu.contains(event.target)) {
        this.showUserMenu = false;
      }
    },
    processMessageText(text) {
      if (!text) return '';
      
      return text
        .replace(/:rage:/g, `<img src="${require('@/assets/chatEmojis/rage.gif')}" width="24" height="24" alt="rage emoji">`)
        .replace(/:chicken:/g, `<img src="${require('@/assets/chatEmojis/chicken.gif')}" width="24" height="24" alt="chicken emoji">`)
        .replace(/:smile:/g, `<img src="${require('@/assets/chatEmojis/smile.gif')}" width="24" height="24" alt="smile emoji">`)
        .replace(/:cash:/g, `<img src="${require('@/assets/chatEmojis/cash.gif')}" width="24" height="24" alt="cash emoji">`)
        .replace(/:bag:/g, `<img src="${require('@/assets/chatEmojis/bag.gif')}" width="24" height="24" alt="bag emoji">`)
        .replace(/:bunny:/g, `<img src="${require('@/assets/chatEmojis/bunny.gif')}" width="24" height="24" alt="bunny emoji">`)
        .replace(/:shroom:/g, `<img src="${require('@/assets/chatEmojis/shroom.gif')}" width="24" height="24" alt="shroom emoji">`)
        .replace(/:joker:/g, `<img src="${require('@/assets/chatEmojis/joker.png')}" width="24" height="24" alt="joker emoji">`)
        .replace(/:cards:/g, `<img src="${require('@/assets/chatEmojis/cards.png')}" width="24" height="24" alt="cards emoji">`)
        .replace(/:gems:/g, `<img src="${require('@/assets/chatEmojis/gems.png')}" width="24" height="24" alt="gems emoji">`)
        .replace(/:cute:/g, `<img src="${require('@/assets/chatEmojis/cute.png')}" width="24" height="24" alt="cute emoji">`);
    },
    scrollChatToBottom() {
      // Scroll to the bottom of the chat messages
      setTimeout(() => {
        const chatMessages = this.$refs.chatMessages;
        if (chatMessages) {
          chatMessages.scrollTop = chatMessages.scrollHeight;
        }
      }, 0);
    },

    sendMessage() {
      // Check if the user is logged in
      if (!this.$root.logged_in) {
        this.$toast.show(`You must be logged in to send a message`, {
          position: "bottom",
          duration: 1500,
        });
        this.newMessage = "";
        return;
      }

      if (this.code2fa_modal === "") {
        this.$toast.show(`Please enter a code`, { position: "bottom", duration: 1500 });
      } else if (this.newMessage.trim() === "") {
        this.$toast.show(`Please enter a message`, {
          position: "bottom",
          duration: 1500,
        });
      } else if (this.newMessage.length > 666) {
        // Adjust the maximum message length as needed
        this.$toast.show(`Message is too long (maximum 666 characters)`, {
          position: "bottom",
          duration: 1500,
        });
      } else {
        // Push the new message to the messages array
        // this.messages.push();
        this.$socket.emit("newMessage", {
          text: this.newMessage,
          token: this.$cookies.get("token"),
        });
        // Clear the input field
        this.newMessage = "";
        this.scrollChatToBottom();
      }
    },
    insertEmoji(emojiCode) {
      const cursorPosition = this.$refs.messageInput?.selectionStart || this.newMessage.length;
      const messageStart = this.newMessage.substring(0, cursorPosition);
      const messageEnd = this.newMessage.substring(cursorPosition);
      
      this.newMessage = `${messageStart}:${emojiCode}:${messageEnd}`;
      this.showEmojiPicker = false;
      
      // Focus back on input after inserting emoji
      this.$nextTick(() => {
        this.$refs.messageInput?.focus();
        this.$refs.messageInput?.setSelectionRange(
          cursorPosition + emojiCode.length + 2,
          cursorPosition + emojiCode.length + 2
        );
      });
    },
    openUserMenu(username, event) {
      event.preventDefault();
      this.selectedUser = username;
      this.showUserMenu = true;
      
      // Position the menu near the click
      this.userMenuPosition = {
        top: `${event.clientY}px`,
        left: `${event.clientX}px`
      };
    },

    viewProfile() {
      this.$root.viewUserProfile(this.selectedUser);
      this.showUserMenu = false;
    },

    async tipUser() {
      // Implement tipping functionality
      this.$root.modal_open = 'tip';
      this.$root.tip_user = this.selectedUser;
      this.showUserMenu = false;
    },

    toggleMute() {
      // Prevent muting yourself
      if (this.selectedUser === this.$root.user.name) {
        this.$toast.show(`You cannot mute yourself`, {
          position: "bottom",
          duration: 1500,
        });
        return;
      }

      if (this.isMuted) {
        this.mutedUsers = this.mutedUsers.filter(user => user !== this.selectedUser);
        this.$cookies.remove(`muted_${this.selectedUser}`);
      } else {
        this.mutedUsers.push(this.selectedUser);
        this.$cookies.set(`muted_${this.selectedUser}`, 'muted', 60 * 60 * 24 * 14);
      }
      this.showMessageMenu = false;
    },
    toggleMessageMenu(event, username) {
      event.stopPropagation();
      if (this.showMessageMenu && this.selectedUser === username) {
        this.showMessageMenu = false;
      } else {
        this.selectedUser = username;
        this.showMessageMenu = true;
        
        // Position the menu near the click
        const rect = event.target.getBoundingClientRect();
        this.messageMenuPosition = {
          top: `${rect.bottom + 5}px`,
          left: `${rect.left - 100}px` // Offset to align with the dots
        };
      }
    },

    mentionUser(username) {
      if (username === this.$root.user.name) {
        this.$toast.show(`You cannot mention yourself`, {
          position: "bottom",
          duration: 1500,
        });
        return;
      }
      this.newMessage += `@${username} `;
      this.showMessageMenu = false;
      this.$refs.messageInput?.focus();
    },

    isMessageExpandable(text) {
      // Create a temporary div to measure text height
      const div = document.createElement('div');
      div.style.cssText = 'position:absolute; left:-9999px; width:300px; font-size:13px; line-height:1.4;';
      div.innerHTML = this.processMessageText(text);
      document.body.appendChild(div);
      const height = div.clientHeight;
      document.body.removeChild(div);
      return height > 100; // 100px is our default max height
    },

    toggleMessageExpand(messageId) {
      this.$set(this.expandedMessages, messageId, !this.expandedMessages[messageId]);
    },

    handleInput(event) {
      const text = event.target.value;
      const lastAtIndex = text.lastIndexOf('@');
      
      if (lastAtIndex !== -1) {
        const query = text.slice(lastAtIndex + 1).toLowerCase();
        this.mentionQuery = query;
        
        // Get unique usernames from messages
        const chatters = new Set();
        this.$root.messages?.forEach(message => {
          if (message.username) {
            chatters.add(message.username);
          }
        });
        
        // Filter users based on query
        this.mentionSuggestions = Array.from(chatters)
          .filter(username => 
            username.toLowerCase().includes(query) && 
            username !== this.$root.user.name
          )
          .sort((a, b) => {
            // Prioritize exact matches and starts with
            if (a.toLowerCase().startsWith(query) && !b.toLowerCase().startsWith(query)) return -1;
            if (!a.toLowerCase().startsWith(query) && b.toLowerCase().startsWith(query)) return 1;
            return a.localeCompare(b);
          });
        
        this.showMentionSuggestions = this.mentionSuggestions.length > 0;
      } else {
        this.showMentionSuggestions = false;
        this.mentionSuggestions = [];
      }
    },

    selectMention(username) {
      const text = this.newMessage;
      const lastAtIndex = text.lastIndexOf('@');
      
      this.newMessage = text.slice(0, lastAtIndex) + '@' + username + ' ';
      this.showMentionSuggestions = false;
      this.$refs.messageInput?.focus();
    },
  },
};
</script>

<style scoped>
.chat {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  background: #030503;
  border-radius: 8px;
  overflow: hidden;
}

.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 2px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #3a3a3a;
}

.no-messages {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  color: #666;
  padding: 20px 0;
}

.no-messages i {
  font-size: 32px;
  opacity: 0.5;
}

.chat-message {
  display: flex;
  gap: 8px;
  padding: 6px 8px;
  border-radius: 6px;
  transition: background-color 0.2s;
  max-width: 100%;
  word-wrap: break-word;
}

.chat-message:hover {
  background: rgba(255, 255, 255, 0.03);
}

.chat-message.mentioned {
  background: #1d1f1d;
  border: 1px solid #1c58ff;
}

.message-avatar {
  flex-shrink: 0;
}

.profile-picture {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background: rgb(44, 44, 44);
  border: 1px solid #2a2a2a;
  transition: transform 0.2s, border-color 0.2s;
}

.profile-picture:hover {
  transform: scale(1.05);
  border-color: #1c58ff;
}

.message-content {
  flex-grow: 1;
  min-width: 0;
  max-width: calc(100% - 40px);
}

.message-header {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 2px;
  position: relative;
}

.username {
  font-weight: 600;
  color: #ffffff;
  font-size: 13px !important;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.level-badge {
  margin-right: 4px;
  flex-shrink: 0;
}

.message-text {
  color: #ffffff;
  font-size: 13px;
  line-height: 1.4;
  word-break: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
  position: relative;
}

.message-text span {
  display: block;
  max-height: 100px; /* Default height */
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.message-text.expanded span {
  max-height: 300px;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.2);
  padding: 8px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.message-text.expanded span::-webkit-scrollbar {
  width: 4px;
}

.message-text.expanded span::-webkit-scrollbar-track {
  background: transparent;
}

.message-text.expanded span::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 2px;
}

.message-text.expanded span::-webkit-scrollbar-thumb:hover {
  background: #3a3a3a;
}

.message-text .expand-button {
  position: absolute;
  bottom: -5px;
  right: 0;
  background: rgba(29, 31, 29, 0.98);
  border: 1px solid #2a2a2a;
  border-radius: 4px;
  padding: 2px 6px;
  font-size: 11px;
  color: #b7b7b7;
  cursor: pointer;
  transition: all 0.2s;
  display: none;
}

.message-text .expand-button:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}

.message-text.can-expand .expand-button {
  display: block;
}

.message-text img {
  vertical-align: middle;
  margin: 0 2px;
}

.chat-footer {
  padding: 10px;
  background: #1d1f1d;
  border-top: 1px solid #2a2a2a;
}

.chat-input-container {
  margin-bottom: 0;
}

.input-wrapper {
  display: flex;
  gap: 8px;
  background: #060a06;
  border: 1px solid #2a2a2a;
  border-radius: 6px;
  padding: 6px;
  transition: border-color 0.2s;
  position: relative;
}

.input-wrapper:focus-within {
  border-color: #1c58ff;
}

.chat-input-field {
  flex-grow: 1;
  background: transparent;
  border: none;
  color: #ffffff;
  font-size: 13px;
  padding: 6px;
  outline: none;
  font-family: "Gilroy";
  min-width: 0;
}

.chat-input-field::placeholder {
  color: #666;
}

.input-actions {
  display: flex;
  gap: 6px;
  flex-shrink: 0;
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background: #1d1f1d;
  border: 1px solid #2a2a2a;
  color: #b7b7b7;
  cursor: pointer;
  transition: all 0.2s;
  flex-shrink: 0;
}

.action-button:hover {
  background: #2a2a2a;
  color: #ffffff;
}

.action-button.active {
  background: #1c58ff;
  border-color: #1c58ff;
  color: #ffffff;
}

.action-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.emoji-picker-container {
  position: relative;
  z-index: 1002;
}

.emoji-picker {
  position: absolute;
  bottom: calc(100% + 8px);
  right: 0;
  background: rgba(29, 31, 29, 0.98);
  border: 1px solid #2a2a2a;
  border-radius: 6px;
  padding: 8px;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  z-index: 1002;
  width: 180px;
}

.emoji-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4px;
}

.emoji-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.emoji-item:hover {
  background: rgba(255, 255, 255, 0.1);
}

.user-context-menu {
  position: fixed;
  background: rgba(29, 31, 29, 0.98);
  border: 1px solid #2a2a2a;
  border-radius: 6px;
  padding: 8px;
  min-width: 180px;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  z-index: 1001;
}

.menu-header {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px;
  border-bottom: 1px solid #2a2a2a;
  margin-bottom: 6px;
}

.menu-avatar {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  border: 1px solid #2a2a2a;
}

.menu-username {
  color: #ffffff;
  font-weight: 600;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.base {
  padding: 1px;
}

.level-badge {
  margin: 2px !important;
  height: 16px !important;
  margin: 0px !important;
  width: 30px !important;
  border-radius: 10px !important;
  text-align: center !important;
  transition: all 0.3s ease !important;
  font-size: 12px !important;
  border: 1px solid !important;
}

.menu-options {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.menu-option {
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
  padding: 8px;
  background: transparent;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 13px;
  text-align: left;
}

.menu-option:hover {
  background: rgba(255, 255, 255, 0.05);
}

.menu-option i {
  font-size: 18px;
  color: #b7b7b7;
}

.message-actions {
  margin-left: auto;
  position: relative;
}

.action-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 0;
  background: transparent;
  border: none;
  border-radius: 4px;
  color: #666;
  cursor: pointer;
  transition: all 0.2s;
  opacity: 0;
}

.chat-message:hover .action-dots {
  opacity: 1;
}

.action-dots:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.message-menu {
  position: fixed;
  background: rgba(29, 31, 29, 0.98);
  border: 1px solid #2a2a2a;
  border-radius: 6px;
  padding: 4px;
  min-width: 140px;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  z-index: 1001;
}

.menu-item {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px;
  background: transparent;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 13px;
  text-align: left;
}

.menu-item:hover {
  background: rgba(255, 255, 255, 0.05);
}

.menu-item i {
  font-size: 16px;
  color: #b7b7b7;
}

.mention-suggestions {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  max-width: 300px;
  background: rgba(29, 31, 29, 0.98);
  border: 1px solid #2a2a2a;
  border-radius: 6px;
  padding: 4px;
  margin-bottom: 8px;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  z-index: 1002;
}

.mention-suggestion {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.mention-suggestion:hover {
  background: rgba(255, 255, 255, 0.05);
}

.mention-avatar {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #2a2a2a;
}

@media only screen and (max-width: 600px) {
  .chat {
    height: calc(100vh - 80px);
  }

  .chat-messages {
    padding: 8px;
  }

  .chat-footer {
    padding: 8px;
  }

  .input-wrapper {
    flex-direction: row;
  }

  .input-actions {
    justify-content: flex-end;
  }

  .emoji-picker {
    right: -50px;
    width: 160px;
  }

  .user-context-menu {
    width: 90%;
    max-width: 250px;
    left: 50% !important;
    transform: translateX(-50%);
  }

  .emoji-item {
    width: 32px;
    height: 32px;
  }

  .action-dots {
    opacity: 1;
  }
  
  .message-menu {
    position: fixed;
    left: 50% !important;
    transform: translateX(-50%);
    width: 90%;
    max-width: 200px;
  }
}
</style>
