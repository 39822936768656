<template>
  <div class="complete-profile">
    <div class="profile-card">
      <h1>Complete Your Profile</h1>
      
      <div v-if="error" class="error-message">
        {{ error }}
      </div>

      <form @submit.prevent="completeProfile">
        <div class="form-group">
          <label>Email (from {{ oauthProvider }})</label>
          <input type="text" :value="email" disabled class="disabled-input" />
        </div>

        <div class="form-group">
          <label>Choose a Username</label>
          <input 
            type="text" 
            v-model="username" 
            :class="{ 'error': usernameError }"
            @input="validateUsername"
            placeholder="Enter username"
            maxlength="20"
          />
          <small v-if="usernameError" class="error-text">{{ usernameError }}</small>
          <small class="help-text">Username must be 4-20 characters, using only letters, numbers, and underscores</small>
        </div>

        <div class="form-group">
          <label>
            <input type="checkbox" v-model="acceptTerms" />
            I accept the <a href="/terms-of-service" target="_blank">Terms of Service</a>
          </label>
        </div>

        <div class="form-group">
          <label>
            <input type="checkbox" v-model="over18" />
            I am over 18 years old and not from USA, UK, Australia or New Zealand
          </label>
        </div>

        <button type="submit" :disabled="!isValid || loading">
          <span v-if="loading">Creating Account...</span>
          <span v-else>Complete Profile</span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Config from '../config';

export default {
  name: 'CompleteProfile',
  data() {
    return {
      username: '',
      usernameError: '',
      acceptTerms: false,
      over18: false,
      loading: false,
      error: '',
      email: this.$cookies.get('oauth_email'),
      oauthProvider: this.$cookies.get('oauth_provider')
    }
  },
  computed: {
    isValid() {
      return this.username.length >= 4 && 
             !this.usernameError && 
             this.acceptTerms && 
             this.over18;
    }
  },
  mounted() {
    // Redirect if no temp token
    if (!this.$cookies.get('temp_token')) {
      this.$router.push('/');
    }
  },
  methods: {
    validateUsername() {
      this.usernameError = '';
      
      if (this.username.length < 4) {
        this.usernameError = 'Username must be at least 4 characters';
        return;
      }
      
      if (!/^[a-zA-Z0-9_]*$/.test(this.username)) {
        this.usernameError = 'Username can only contain letters, numbers, and underscores';
        return;
      }
    },
    async completeProfile() {
      if (!this.isValid) return;
      
      this.loading = true;
      this.error = '';
      
      try {
        const response = await axios.post(`${Config.Api_call}/api/complete-oauth-profile`, {
          tempToken: this.$cookies.get('temp_token'),
          username: this.username,
          acceptTerms: this.acceptTerms,
          over18: this.over18
        });
        
        if (response.data.token) {
          // Clear temporary OAuth data
          this.$cookies.remove('temp_token');
          this.$cookies.remove('oauth_email');
          this.$cookies.remove('oauth_provider');
          
          // Set permanent token
          this.$cookies.set('token', response.data.token, '10y');
          this.$root.logged_in = true;
          
          // Fetch user data
          await this.$root.fetch_user();
          
          // Redirect to home
          this.$router.push('/');
        } else {
          throw new Error('Invalid server response');
        }
      } catch (error) {
        console.error('Profile completion error:', error);
        this.error = error.response?.data?.error || 'Failed to complete profile. Please try again.';
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>
.complete-profile {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: #060a06;
}

.profile-card {
  background: #111b11;
  padding: 30px;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h1 {
  color: #42da3b;
  text-align: center;
  margin-bottom: 30px;
  font-size: 24px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
  color: #fff;
}

input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #2b2b2b;
  border-radius: 4px;
  background: #1d1f1d;
  color: #fff;
}

input[type="text"].error {
  border-color: #ff4444;
}

.disabled-input {
  background: #1d1f1d;
  color: #666;
  cursor: not-allowed;
}

.error-message {
  background: #ff44441a;
  color: #ff4444;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
}

.error-text {
  color: #ff4444;
  font-size: 12px;
  margin-top: 4px;
  display: block;
}

.help-text {
  color: #666;
  font-size: 12px;
  margin-top: 4px;
  display: block;
}

button {
  width: 100%;
  padding: 12px;
  background: #42da3b;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

button:disabled {
  background: #2b2b2b;
  cursor: not-allowed;
}

a {
  color: #42da3b;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style> 