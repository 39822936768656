<template>
  <div v-if="labelNo" :style="getLabelStyle(labelNo)" class="label-component" :class="{'yt-label': labelNo === 1}">
    <template v-if="labelNo === 1">
      <i class="material-icons yt-icon">play_circle_filled</i>
      <span>YT</span>
    </template>
    <template v-else>
      {{ getLabelName(labelNo) }}
    </template>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    labelNo: {
      type: Number,
      required: true
    },
    username: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      level: 0
    }
  },
  mounted() {
    if (this.labelNo === 1) {
      this.fetchLevel()
    }
  },
  methods: {
    getLabelName(labelNo) {
      switch (labelNo) {
        case 1: return "YT";
        case 2: return "Kick Streamer";
        case 3: return "Admin";
        case 4: return "Mod";
        case 5: return "Dev";
        case 6: return "Streamer";
        case 7: return "Moderator";
        case 8: return "Contributor";
        case 9: return "VIP";
        case 10: return "Support";
        case 11: return "Support+";
        default: return "Unknown"; 
      }
    },
    getLabelStyle(labelNo) {
      switch (labelNo) {
        case 1:
        return {
            color: 'white',
            backgroundColor: '#ff0000',
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            padding: '0 10px'
          };
        case 2:
          return {
            color: 'black',
            backgroundColor: '#a7a7a7',
          };
        case 3:
        return {
            color: 'black',
            backgroundColor: '#f4f520',
          };
        case 4:
        return {
            color: 'black',
            backgroundColor: '#6bd11b',
          };
        case 5:
          return {
            color: 'black',
            backgroundColor: '#f59020',
          };
        case 6:
          return {
            color: 'black',
            backgroundColor: '#ffbf00',
          };
        case 7:
          return {
            color: 'white',
            backgroundColor: '#800080',
          };
        case 8:
          return {
            color: 'black',
            backgroundColor: '#00bfff',
          };
        case 9:
          return {
            color: 'black',
            backgroundColor: '#ffc10c',
          };
        case 10:
          return {
            color: 'black',
            backgroundColor: '#1c58ff',
          };
          case 11:
          return {
            color: 'white',
            backgroundColor: '#1c58ff',
          };
        default: return {}; // Default style
      }
    },
    async fetchLevel() {
      try {
        const response = await axios.post(`${this.$root.API_PREFIX}/api/get_total_wager`, {
          username: this.username
        })
        if (response.data) {
          const lifetime = response.data.lifetime_wagered
          const xp = lifetime * 150
          this.level = Math.trunc((0.04 * Math.sqrt(xp * 50)))
          if (this.level === 0) this.level = 1
        }
      } catch (error) {
        console.error('Error fetching level:', error)
      }
    }
  }
};
</script>

<style scoped>
.label-component {
  display: inline-flex;          
  font-Size: 14px;
  border: 1px solid #000000;
  padding: 0px 10px;
  margin-Left: 2px;
  border-Radius: 10px;
  align-items: center;
}

.yt-label {
  border: 1px solid #cc0000;
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.2);
}

.yt-icon {
  font-size: 16px;
}
</style>