<template>
  <div class="level-badge" :class="[class_lvl, `tier-${Math.ceil((level % 50) / 10)}`]" v-tooltip="{
    content: `
      <div class='level-tooltip'>
        <div class='level-tooltip-title'>${getLevelRank(level)} - Level ${level}</div>
      </div>
    `,
    html: true,
    classes: ['level-tooltip-container']
  }">
    {{ level }}
  </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'NewLevelBadgeComponent',
  data() {
    return {
        dollar: 1,
        level: 0,
        class_lvl: 'bronze',
        xp: 0,
        lifetime: ''
    }
  },
    
    props: {
        username: String,
        offset: Number
    },  
    methods: {
        calculateWagerRequired(level) {
            const baseAmount = 10
            if (level <= 100) {
                return Math.floor(baseAmount * Math.pow(1.055, level))
            } else if (level <= 500) {
                const base = this.calculateWagerRequired(100)
                return Math.floor(base * Math.pow(1.009, level - 100))
            } else {
                const base = this.calculateWagerRequired(500)
                const remainingLevels = 999 - 500
                const targetAmount = 100000000
                const multiplier = Math.pow(targetAmount / base, 1 / remainingLevels)
                return Math.floor(base * Math.pow(multiplier, level - 500))
            }
        },
        formatNumber(num) {
            if (num >= 1e9) return (num / 1e9).toFixed(1) + 'B'
            if (num >= 1e6) return (num / 1e6).toFixed(1) + 'M'
            if (num >= 1e3) return (num / 1e3).toFixed(1) + 'K'
            return num.toFixed(0)
        },
        getLevelRank(level) {
            if (level >= 951) return '🏆 Legendary'
            if (level >= 901) return '⚡ Aether'
            if (level >= 851) return '🌑 Eclipse'
            if (level >= 801) return '❄️ Frost'
            if (level >= 751) return '🔥 Inferno'
            if (level >= 701) return '🌟 Celestial'
            if (level >= 651) return '💎 Onyx'
            if (level >= 601) return '🟢 Jade'
            if (level >= 551) return '🔴 Crimson'
            if (level >= 501) return '⚔️ Titanium'
            if (level >= 451) return '✨ Platinum'
            if (level >= 401) return '🌑 Obsidian'
            if (level >= 351) return '💜 Amethyst'
            if (level >= 301) return '💙 Sapphire'
            if (level >= 251) return '❤️ Ruby'
            if (level >= 201) return '💎 Diamond'
            if (level >= 151) return '💚 Emerald'
            if (level >= 101) return '🌟 Gold'
            if (level >= 51) return '⚪ Silver'
            return '🟤 Bronze'
        },
        updateLevel() {
            this.xp = this.lifetime * 150
            this.level = Math.trunc((0.04 * Math.sqrt(this.xp * 50)))

            if (this.offset) {
                this.level += this.offset
            }

            if (this.level <= 0) {
                this.level = 1
            }

            // Set class based on level ranges
            if (this.level >= 951) this.class_lvl = 'legendary'
            else if (this.level >= 901) this.class_lvl = 'aether'
            else if (this.level >= 851) this.class_lvl = 'eclipse'
            else if (this.level >= 801) this.class_lvl = 'frost'
            else if (this.level >= 751) this.class_lvl = 'inferno'
            else if (this.level >= 701) this.class_lvl = 'celestial'
            else if (this.level >= 651) this.class_lvl = 'onyx'
            else if (this.level >= 601) this.class_lvl = 'jade'
            else if (this.level >= 551) this.class_lvl = 'crimson'
            else if (this.level >= 501) this.class_lvl = 'titanium'
            else if (this.level >= 451) this.class_lvl = 'platinum'
            else if (this.level >= 401) this.class_lvl = 'obsidian'
            else if (this.level >= 351) this.class_lvl = 'amethyst'
            else if (this.level >= 301) this.class_lvl = 'sapphire'
            else if (this.level >= 251) this.class_lvl = 'ruby'
            else if (this.level >= 201) this.class_lvl = 'diamond'
            else if (this.level >= 151) this.class_lvl = 'emerald'
            else if (this.level >= 101) this.class_lvl = 'gold'
            else if (this.level >= 51) this.class_lvl = 'silver'
            else this.class_lvl = 'bronze'
        }
    },
    mounted() {
        axios.post(`${this.$root.API_PREFIX}/api/get_total_wager`, {
            username: this.username
        })
        .then(response => {
            if (response.data) {
                this.lifetime = response.data.lifetime_wagered
                this.updateLevel()
            }
        })
    }
}
</script>

<style scoped>
.level-badge {
    display: inline;
    padding: 1px 4px;
    margin-left: 20px;
    font-size: 10px;
    font-weight: 600;

    border-radius: 4px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    border: 2px solid;
    transition: all 0.3s ease;
    /* min-width: 50px; */
    text-align: center;
}

.level-badge:hover {
  transform: translateY(-5px);
}

/* Basic Tiers */
.bronze {
  border-color: #cd7f32;
  background: linear-gradient(145deg, #111b11, #3d2611);
  box-shadow: 0 0 10px rgba(205, 127, 50, 0.2);
  color: #ffffff;
}

.silver {
  border-color: #c0c0c0;
  background: linear-gradient(145deg, #111b11, #2b2b2b);
  box-shadow: 0 0 10px rgba(192, 192, 192, 0.2);
  color: #ffffff;
}

.gold {
  border-color: #ffd700;
  background: linear-gradient(145deg, #111b11, #3d3000);
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.2);
  color: #ffffff;
}

/* Mid Tiers */
.emerald {
  border-color: #50c878;
  background: linear-gradient(145deg, #111b11, #0d3319);
  box-shadow: 0 0 10px rgba(80, 200, 120, 0.2);
  color: #ffffff;
}

.diamond {
  border-color: #b9f2ff;
  background: linear-gradient(145deg, #111b11, #1a2b2e);
  box-shadow: 0 0 15px rgba(185, 242, 255, 0.3);
  color: #ffffff;
}

.ruby {
  border-color: #e0115f;
  background: linear-gradient(145deg, #111b11, #2e1a1a);
  box-shadow: 0 0 10px rgba(224, 17, 95, 0.2);
  color: #ffffff;
}

/* High Tiers */
.sapphire, .amethyst, .obsidian, .platinum {
  background: linear-gradient(145deg, #000000, #434343);
  color: #ffd700;
  border: 2px solid #ffd700;
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.3);
  animation: glow 2s ease-in-out infinite alternate;
}

/* Elite Tiers */
.titanium, .crimson, .jade, .onyx, .celestial {
  background: linear-gradient(145deg, #FF0099, #493240);
  color: #ffffff;
  border: 2px solid var(--border-color, #cc007a);
  box-shadow: 0 0 15px var(--glow-color, rgba(255, 0, 153, 0.5));
}

/* Legendary Tiers */
.inferno, .frost, .eclipse, .aether, .legendary {
  background: linear-gradient(145deg, var(--start-color, #FFD700), var(--end-color, #FF0000));
  color: #ffffff;
  border: 2px solid #ffd700;
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.4);
  animation: multiGlow 3s infinite alternate;
}

/* Tier Opacity */
.tier-1 { opacity: 0.75; }
.tier-2 { opacity: 0.8; }
.tier-3 { opacity: 0.85; }
.tier-4 { opacity: 0.9; }
.tier-5 { opacity: 1; }

@keyframes glow {
  from { box-shadow: 0 0 10px rgba(255, 215, 0, 0.3); }
  to { box-shadow: 0 0 20px rgba(255, 215, 0, 0.6); }
}

@keyframes multiGlow {
  0% {
    box-shadow: 0 0 20px rgba(255, 215, 0, 0.6);
    border-color: #FFD700;
  }
  50% {
    box-shadow: 0 0 30px rgba(255, 0, 0, 0.6);
    border-color: #FF0000;
  }
  100% {
    box-shadow: 0 0 40px rgba(255, 20, 147, 0.6);
    border-color: #FF1493;
  }
}

/* Tooltip Styles */
:deep(.level-tooltip-container) {
  background: rgba(0, 0, 0, 0.95) !important;
  border: 1px solid var(--glow-color, #42da3b) !important;
  border-radius: 8px !important;
  padding: 12px !important;
  min-width: 150px !important;
  box-shadow: 0 0 15px var(--glow-color, rgba(66, 218, 59, 0.3)) !important;
}

.level-tooltip {
  font-family: 'Roboto Mono', monospace;
}

.level-tooltip-title {
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
}
</style>
