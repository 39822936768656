<template>
    <div class="home">    
        <div class="particles-container">
            <div v-for="n in 20" :key="n" class="particle"></div>
        </div>

        <div class="title_name_level">
            <div class="t3" v-if="!user">
                <a class="bio_Desc" style="font-size:38px; color:sliver;">{{username}} doesn't exist</a>  
                <a class="bio_Desc" style="font-size:18px; color:sliver;">They may have deleted there account. If you think something is wrong contact please support.</a>  
            </div>

            <div class="flexy_gholder" v-if="user">
                <div class="profile-header animate-fade-in">
                    <div class="profile-picture-container">
                        <div class="profile-picture-glow"></div>
                        <img class="img_dp_2000" :src="`${this.$root.API_PREFIX}/api/DisplayPicture?name=${userdata.username}`"/>
                    </div>

                    <div class="profile-info">
                        <div class="username-section">
                            <div class="name-and-actions">
                                <h2 class="nickanme">{{username}}</h2>
                                <div class="quick-actions">
                                    <button v-if="username != $root.user.name && $root.user.name" 
                                            class="action-btn tip-btn" 
                                            @click="$root.modal_open = 'tip_box', $root.tipName = userdata.username">
                                        <i class="material-icons">payments</i>
                                    </button>
                                    <button v-if="$root.user.admin == true" 
                                            class="action-btn admin-btn" 
                                            @click="toggleAdminPanel">
                                        <i class="material-icons">admin_panel_settings</i>
                                    </button>
                                </div>
                            </div>
                            <div class="user-badges">
                                <a v-if="userdata.label_no" :style="getLabelStyle(userdata.label_no)" class="user-label">
                                    {{ getLabelName(userdata.label_no) }}
                                </a>
                                <LevelBadgeComponent v-if="userdata.lifetime_wagered != null" :total_wager="userdata.lifetime_wagered" />
                            </div>
                        </div>

                        <!-- Admin Panel -->
                        <transition name="fade">
                            <div v-if="showAdminPanel" class="admin-panel">
                                <div class="admin-header">
                                    <h3>Admin Controls</h3>
                                    <button class="close-btn" @click="showAdminPanel = false">
                                        <i class="material-icons">close</i>
                                    </button>
                                </div>
                                <div class="admin-content">
                                    <div class="admin-actions">
                                        <button class="admin-action-btn" @click="resetProfilePicture">
                                            <i class="material-icons">delete_forever</i>
                                            <span>Reset Picture</span>
                                        </button>
                                        <button class="admin-action-btn" @click="removeBio">
                                            <i class="material-icons">edit_off</i>
                                            <span>Remove Bio</span>
                                        </button>
                                        <button class="admin-action-btn" @click="removeNickname">
                                            <i class="material-icons">person_remove</i>
                                            <span>Remove Nickname</span>
                                        </button>
                                        <button class="admin-action-btn" @click="lockWithdrawal">
                                            <i class="material-icons">lock</i>
                                            <span>Toggle Withdrawal</span>
                                        </button>
                                        <button class="admin-action-btn" @click="ToggleMuted">
                                            <i class="material-icons">volume_off</i>
                                            <span>Toggle Mute</span>
                                        </button>
                                    </div>
                                    
                                    <div class="profile-picture-upload">
                                        <h4>Update Profile Picture</h4>
                                        <div class="upload-container">
                                            <input type="file" name="file" id="file" v-if="!previewImage" ref="fileInput" @input="pickFile"/>
                                            <img v-if="previewImage" :src="previewImage" class="preview-image">
                                            <button v-if="previewImage" class="upload-btn" @click="uploadImgToServer">
                                                <i class="material-icons">cloud_upload</i>
                                                <span>Upload</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>

                        <div class="bio-section">
                            <div class="bio-content" :class="{'no-bio': !userdata.bio}">
                                <i class="material-icons bio-icon">description</i>
                                <div class="bio-text">
                                    <div v-if="userdata.nickname || userdata.handle" class="bio-meta">
                                        <span v-if="userdata.nickname" class="nickname">
                                            <i class="material-icons">person</i>
                                            {{userdata.nickname}}
                                        </span>
                                        <span v-if="userdata.handle" class="handle">
                                            <i class="material-icons">alternate_email</i>
                                            @{{userdata.handle}}
                                        </span>
                                    </div>
                                    <p>{{userdata.bio || 'This user has no bio set'}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="user-stats">
                            <div class="stat-card" v-for="(stat, index) in stats" :key="index" :style="{ animationDelay: index * 0.1 + 's' }">
                                <i class="material-icons">{{stat.icon}}</i>
                                <span>{{stat.label}}</span>
                                <strong>{{stat.value}}</strong>
                            </div>
                        </div>

                        <div class="user-additional-stats">
                            <div class="additional-stat" v-for="(stat, index) in additionalStats" :key="index" :style="{ animationDelay: index * 0.1 + 's' }">
                                <i class="material-icons">{{stat.icon}}</i>
                                <span>{{stat.label}}</span>
                                <strong>{{stat.value}}</strong>
                            </div>
                        </div>
                    </div>
                </div>

      

        

                <!-- Admin Panel Popup -->
                <div v-if="showAdminPanel && $root.user.admin == true" class="admin-popup-overlay" @click.self="showAdminPanel = false">
                    <div class="admin-popup">
                        <div class="admin-header">
                            <h3>Admin Panel - {{username}}</h3>
                            <div class="admin-header-actions">
                                <button class="close-btn" @click="showAdminPanel = false">
                                    <i class="material-icons">close</i>
                                </button>
                            </div>
                        </div>

                        <div class="admin-content">
                            <div class="user-status">
                                <div v-if="admin_data?.isOnline" class="status-orb online"></div>
                                <div v-else class="status-orb offline"></div>
                                <span class="status-text">{{admin_data?.isOnline ? 'Currently Online' : 'Currently Offline'}}</span>
                            </div>

                            <div class="admin-stats">
                                <div class="admin-stat">
                                    <i class="material-icons">account_balance_wallet</i>
                                    <span>Current Balance</span>
                                    <strong>{{admin_data?.bal ? `$${formatNumber(admin_data.bal)}` : '$0.00'}}</strong>
                                </div>
                                <div class="admin-stat">
                                    <i class="material-icons">add_circle</i>
                                    <span>Total Deposits</span>
                                    <strong>{{admin_data?.totalDeposited ? `$${formatNumber(admin_data.totalDeposited)}` : '$0.00'}}</strong>
                                </div>
                                <div class="admin-stat">
                                    <i class="material-icons">remove_circle</i>
                                    <span>Total Withdrawals</span>
                                    <strong>{{admin_data?.totalWithdrawn ? `$${formatNumber(admin_data.totalWithdrawn)}` : '$0.00'}}</strong>
                                </div>
                            </div>

                            <div class="admin-section">
                                <h4 class="section-title">Account Actions</h4>
                                <div class="admin-actions">
                                    <button class="admin-btn" @click="resetProfilePicture">
                                        <i class="material-icons">delete_forever</i>
                                        <span>Reset Avatar</span>
                                    </button>
                                    <button class="admin-btn" @click="removeBio">
                                        <i class="material-icons">edit_off</i>
                                        <span>Clear Bio</span>
                                    </button>
                                    <button class="admin-btn" @click="removeNickname">
                                        <i class="material-icons">person_remove</i>
                                        <span>Reset Nickname</span>
                                    </button>
                                    <button class="admin-btn" @click="lockWithdrawal">
                                        <i class="material-icons">lock</i>
                                        <span>Toggle Withdrawals</span>
                                    </button>
                                    <button class="admin-btn" @click="ToggleMuted">
                                        <i class="material-icons">volume_off</i>
                                        <span>Toggle Chat Mute</span>
                                    </button>
                                </div>
                            </div>

                            <div class="admin-section">
                                <h4 class="section-title">Change Profile photo</h4>
                                <div class="profile-picture-upload">
                                    <div class="upload-container">
                                        <div class="upload-preview">
                                            <input type="file" name="file" id="file" v-if="!previewImage" ref="fileInput" @input="pickFile" class="file-input"/>
                                            <label for="file" v-if="!previewImage" class="file-label">
                                                <i class="material-icons">cloud_upload</i>
                                                <span>Choose Image</span>
                                            </label>
                                            <img v-if="previewImage" :src="previewImage" class="preview-image">
                                        </div>
                                        <button class="upload-btn" v-if="previewImage" @click="uploadImgToServer">
                                            <i class="material-icons">check_circle</i>
                                            <span>Update Avatar</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LevelBadgeComponent from '../components/LevelBadgeComponent.vue'
import axios from 'axios'
export default {
  name: 'UserProfileComponent',
    components: {  LevelBadgeComponent },   
   data() {
        return {
        
            inspected_cf: [],
            userdata: [],
            user: true,
            xp: 0,
            loaded: false,
            admin_data: null,
            blob: [],
            auctalBlob: '',
            image: [],
            previewImage: '',
            showAdminPanel: false
        }
    },  props: {
          username: String,
    
  
      },   
      
      
    
    methods: {
      pickFile () {
        let input = this.$refs.fileInput
        let file = input.files
        if (file && file[0]) {
          let reader = new FileReader
          reader.onload = e => {
            this.previewImage = e.target.result
          }
          reader.readAsDataURL(file[0])
          this.$emit('input', file[0])
        }
      },
     
    calculateWinRate() {
        if (!this.userdata.total_joined) return 0;
        const winRate = (this.userdata.total_won / this.userdata.total_joined) * 100;
        return Math.round(winRate);
    },

    formatNumber(num) {
        if (!num) return '0';
        return num.toLocaleString('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        });
    },

    toggleAdminPanel() {
        this.showAdminPanel = !this.showAdminPanel;
    },

    checkAdminPanelState() {
        const panelState = this.$cookies.get('admin_panel_visible');
        this.showAdminPanel = panelState === 'true';
    },

    // Function to determine the label name based on label number
    getLabelName(labelNo) {
      switch (labelNo) {
        case 1: return "YouTube";
        case 2: return "Kick Streamer";
        case 3: return "Admin";
        case 4: return "Mod";
        case 5: return "Dev";
        case 6: return "Streamer";
        case 7: return "Moderator";
        case 8: return "Contributor";
        case 9: return "VIP";
        case 10: return "Support";
        case 11: return "Support+";
        default: return ""; // Default label
      }
    },
    closeAdminPanel() {
    this.admin_data = null;
  },
  uploadImgToServer() {
        const form_data = new FormData();
   
 this.previewImage

 const base64Image =  this.previewImage.split(';base64,').pop();

// Decode base64 data
const decodedImageData = atob(base64Image);

// Convert decoded data into a blob
const byteArray = new Uint8Array(decodedImageData.length);
for (let i = 0; i < decodedImageData.length; i++) {
    byteArray[i] = decodedImageData.charCodeAt(i);
}

const blob = new Blob([byteArray], { type: 'image/jpeg' });


        // form_data.append('image', blob)
        form_data.append('token', this.$cookies.get('token'))
        form_data.append('username', this.userdata.username)
        form_data.append('image',blob, 'image.png')



                    this.loading = true

                    axios.post(`${this.$root.API_PREFIX}/api/uploadDisplayPicture_admin`, form_data).then(response => {
                if (response.data) {
                    // this.file = ""
                    // this.text = "image has been added to server " + response.data.img
                    // this.$root.user.profile_picture = response.data.img
         console.log(response.data) 
           setTimeout(function () { window.location.reload() }.bind(this), 200)

                 
                   



                }
            })
            },
    // Function to determine the inline style for label based on label number
    getLabelStyle(labelNo) {
      switch (labelNo) {
        case 1:
        return {
            color: 'white',
            backgroundColor: '#f52020',
            fontSize: '14px',
            border: '1px solid #000000',
     
            marginLeft: '10px',
            borderRadius: '10px'
          };
        case 2:
          return {
            color: 'black',
            backgroundColor: '#a7a7a7',
            fontSize: '18px',
            border: '1px solid #000000',
            padding: '0px 20px',
    
            marginLeft: '10px',
            borderRadius: '10px'
          };
        case 3:
        return {
            color: 'black',
            backgroundColor: '#f4f520',
            fontSize: '18px',
            border: '1px solid #000000',
            padding: '0px 20px',

            marginLeft: '10px',
            borderRadius: '10px'
          };
        case 4:
        return {
            color: 'black',
            backgroundColor: '#6bd11b',
            fontSize: '18px',
            border: '1px solid #000000',
            padding: '0px 20px',
       
            marginLeft: '10px',
            borderRadius: '10px'
          };
        case 5:
          return {
            color: 'black',
            backgroundColor: '#f59020',
            fontSize: '18px',
            border: '1px solid #000000',
            padding: '0px 20px',
         
            marginLeft: '10px',
            borderRadius: '10px'
          };
        case 6:
          return {
            color: 'black',
            backgroundColor: '#ffbf00',
            fontSize: '18px',
            border: '1px solid #000000',
            padding: '0px 20px',
   
            marginLeft: '10px',
            borderRadius: '10px'
          };
        case 7:
          return {
            color: 'white',
            backgroundColor: '#800080',
            fontSize: '30px',
            fontVariant: 'all-small-caps'
          };
        case 8:
          return {
            color: 'black',
            backgroundColor: '#00bfff',
            fontSize: '16px',
            border: '1px solid #000000',
            padding: '0px 20px',
  
            marginLeft: '10px',
            borderRadius: '10px'
          };
        case 9:
          return {
            color: 'black',
            backgroundColor: '#ffc10c',
            fontSize: '16px',
            border: '1px solid #000000',
            padding: '0px 20px',
            fontVariant: 'all-small-caps',
            marginLeft: '10px',
            borderRadius: '10px'
          };
        case 10:
          return {
            color: 'black',
            backgroundColor: '#1c58ff',
            fontSize: '16px',
            border: '1px solid #000000',
            padding: '0px 20px',
    
            marginLeft: '10px',
            borderRadius: '10px'
          };
          case 11:
          return {
            color: 'white',
            backgroundColor: '#1c58ff',
            fontSize: '18px',
            border: '1px solid #000000',
            padding: '0px 20px',
         
            marginLeft: '10px',
            borderRadius: '10px'
          };
        default: return {}; // Default style
      }
    },
 


        resetProfilePicture() {
      // Make the API request to reset the profile picture
      axios.post(`${this.$root.API_PREFIX}/api/reset_profile_picture`,  {
        token: this.$cookies.get('token'),
        username: this.username
    })
        .then(response => {
          // Handle the API response
          // Perform any necessary actions after resetting the profile picture

          this.$toast.show(response.data.message, {position: "bottom", duration: 1500});
        })
        .catch(error => {
          // Handle errors that occurred during the API request
          console.error(error);
        });
    },

    removeBio() {
  // Make the API request to remove the bio
  axios.post(`${this.$root.API_PREFIX}/api/remove_bio`, {
    token: this.$cookies.get('token'),
    username: this.username
  })
    .then(response => {
      // Handle the API response

      // Perform any necessary actions after removing the bio

      this.$toast.show(response.data.message, {position: "bottom", duration: 1500});
    })
    .catch(error => {
      // Handle errors that occurred during the API request
      console.error(error);
    });
},

removeNickname() {
  // Make the API request to remove the nickname
  axios.post(`${this.$root.API_PREFIX}/api/remove_nickname`, {
    token: this.$cookies.get('token'),
    username: this.username
  })
    .then(response => {
      // Handle the API response
      console.log(response.data.message);
      // Perform any necessary actions after removing the nickname

      this.$toast.show(response.data.message, {position: "bottom", duration: 1500});
    })
    .catch(error => {
      // Handle errors that occurred during the API request
      console.error(error);
    });
},

lockWithdrawal() {
  // Make the API request to lock withdrawal
  axios.post(`${this.$root.API_PREFIX}/api/lock_withdrawal`, {
    token: this.$cookies.get('token'),
    username: this.username
  })
    .then(response => {
      // Handle the API response
      console.log(response.data.message);
      // Perform any necessary actions after locking withdrawal

      this.$toast.show(response.data.message, {position: "bottom", duration: 1500});
    })
    .catch(error => {
      // Handle errors that occurred during the API request
      console.error(error);
    });
},
ToggleMuted() {
  this.$socket.emit("toggleMute", {
          token: this.$cookies.get("token"),
          username: this.username,
        });
},
reloadUserData() {
      // Fetch admin info if the user is admin
      const fetchAdminInfo = () => {
        axios.post(`${this.$root.API_PREFIX}/api/user_info_admin`, {
          token: this.$cookies.get('token'),
          username: this.username,
        })
        .then(response => {
          const adminInfo = response.data;
    
          this.admin_data = adminInfo;
        })
        .catch(error => {
          console.error(error);
        });
      };

      if (this.$root.user.admin) {
        fetchAdminInfo();
      }

      // Fetch public user data
      axios.post(`${this.$root.API_PREFIX}/api/user_public`,  {
        username: this.username,
        requester_name: this.$root.user.name
      }).then(response => {
        this.loaded = true;
        if (response.data[0] === undefined) {
          this.user = false;
        } else {
          this.userdata = response.data[0];
          this.xp = response.data[0].lifetime_wagered * 150;
          var level_not_round = (0.04 * Math.sqrt(this.xp * 50)).toFixed(2);
          this.level = Math.trunc(level_not_round);
          this.percentage = Math.abs(this.level - level_not_round);
        }

        if (!this.userdata) {
          this.$router.push({ path: '/coinflip' });
        }
      }).catch(error => {
        console.error(error);
      });
    },

    copy_userid() {
            this.$toast.show(`Copied Users Account ID to Clipboard`, {position: "bottom", duration: 1500});
        },
back() {
    this.$root.viewUser = '' 
},watch: {
    // Watch the username prop for changes
    username: {
      immediate: true,  // Call immediately on component load
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.reloadUserData();  // Trigger the data reload only on change
        }
      },
    }
  },
    },

    computed: {
        stats() {
            return [
                { icon: 'casino', label: 'Games Played', value: this.userdata.total_joined + this.userdata.total_created },
                { icon: 'trending_up', label: 'Level', value: this.level },
                { icon: 'stars', label: 'XP', value: this.formatNumber(Math.round(this.xp)) }
            ];
        },
        additionalStats() {
            return [
 

                { icon: 'fingerprint', label: 'User ID', value: this.userdata.uuid || 'N/A' },
                { icon: 'event', label: 'Join Date', value: this.userdata.join_date ? new Date(this.userdata.join_date).toLocaleDateString() : 'N/A' },
                { icon: 'volume_off', label: 'Chat Muted', value: this.userdata.is_muted ? 'Yes' : 'No' }
            ];
        },
        adminStats() {
            return [
                { icon: 'account_balance_wallet', label: 'Balance', value: `$${this.admin_data?.bal}` },
                { icon: 'trending_up', label: 'Deposited', value: `$${this.admin_data?.totalDeposited}` },
                { icon: 'trending_down', label: 'Withdrawn', value: `$${this.admin_data?.totalWithdrawn}` }
            ];
        },
        adminActions() {
            return [
                { icon: 'delete_forever', label: 'Remove Profile Picture', handler: this.resetProfilePicture },
                { icon: 'edit_off', label: 'Remove Bio', handler: this.removeBio },
                { icon: 'person_remove', label: 'Remove Nickname', handler: this.removeNickname },
                { icon: 'lock', label: 'Toggle Withdrawal', handler: this.lockWithdrawal },
                { icon: 'volume_off', label: 'Toggle Mute', handler: this.ToggleMuted }
            ];
        }
    },

     mounted () {
      this.reloadUserData();
      this.checkAdminPanelState();
    
  }
        
    
}
</script>

<style scoped>
.home {
    max-width: 800px;
    margin: auto;
    width: 100%;
    padding: 20px;
    padding-top: 0px;
    margin: 0px;
    position: relative;
    overflow: hidden;
    font-family: 'Gilroy', sans-serif;
}

.particles-container {
    display: none;
}

.profile-header {
    display: flex;
    gap: 25px;
    margin-bottom: 25px;
    padding: 0px;
width: calc(100% - 40px);
    border-radius: 16px;

    position: relative;
    overflow: hidden;
}

.profile-picture-container {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 20px;
    overflow: hidden;
    z-index: 1;
}

.img_dp_2000 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.profile-picture-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.profile-picture-overlay i {
    color: white;
    font-size: 24px;
}

.profile-picture-container:hover .profile-picture-overlay {
    opacity: 1;
}

.profile-info {
    flex: 1;
    z-index: 1;
}

.username-section {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 20px;
}

.nickanme {
    font-size: 32px;
    color: #fff;
    margin: 0;
    font-weight: 600;
    font-family: 'Gilroy', sans-serif;
}

.user-badges {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.user-label {
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-family: 'Gilroy', sans-serif;
}

.user-stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    margin-bottom: 20px;
}

.stat-card {

    padding: 15px;
    border-radius: 12px;
    text-align: center;
    transition: all 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.stat-card:hover {
    transform: translateY(-2px);
    background: rgba(255, 255, 255, 0.08);
    border-color: rgba(37, 193, 0, 0.3);
}

.stat-card i {
    font-size: 24px;
    color: #25c100;
    margin-bottom: 8px;
}

.stat-card span {
    display: block;
    color: #888;
    font-size: 14px;
    margin-bottom: 4px;
    font-family: 'Gilroy', sans-serif;
}

.stat-card strong {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Gilroy', sans-serif;
}

.user-additional-stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.additional-stat {
    background: rgba(255, 255, 255, 0.03);
    padding: 12px;
    border-radius: 8px;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.05);
}

.additional-stat i {
    font-size: 20px;
    color: #25c100;
    margin-bottom: 6px;
}

.additional-stat span {
    display: block;
    color: #888;
    font-size: 12px;
    margin-bottom: 4px;
    font-family: 'Gilroy', sans-serif;
}

.additional-stat strong {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Gilroy', sans-serif;
}

.profile-content {
 
    border-radius: 16px;
    padding: 25px;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
}

.bio-section {
    margin: 20px 0;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    overflow: hidden;
}

.bio-content {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    padding: 20px;
}

.bio-icon {
    color: #25c100;
    font-size: 20px;
    margin-top: 2px;
}

.bio-text {
    flex: 1;
}

.bio-meta {
    display: flex;
    gap: 15px;
    margin-bottom: 10px;
}

.bio-meta span {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #888;
    font-size: 14px;
}

.bio-meta i {
    font-size: 16px;
    color: #25c100;
}

.bio-text p {
    margin: 0;
    color: #fff;
    font-size: 14px;
    line-height: 1.6;
}

.bio-content.no-bio p {
    color: #888;
    font-style: italic;
}

.profile-actions {
    display: flex;
    gap: 12px;
    margin: 20px 0;
}

.tip_btn {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px 24px;
    background: linear-gradient(135deg, #25c100 0%, #1a8a00 100%);
    border: none;
    border-radius: 12px;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.tip-icon-wrapper {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tip_btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(37, 193, 0, 0.3);
}

.admin-toggle {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 24px;
    background: linear-gradient(135deg, #ff6b6b 0%, #ff5252 100%);
    border: none;
    border-radius: 12px;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.admin-toggle:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(255, 107, 107, 0.3);
}

.admin-toggle i {
    font-size: 20px;
}

.admin-panel {
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    width: 300px;
    background: #1a1a1a;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.name-and-actions {
    position: relative;
}

/* Fade transition */
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.2s ease;
}

.fade-enter-from, .fade-leave-to {
    opacity: 0;
}

.admin-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.05);
}

.admin-header h3 {
    margin: 0;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
}

.close-btn {
    background: none;
    border: none;
    color: #888;
    cursor: pointer;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: all 0.2s ease;
}

.close-btn:hover {
    color: #fff;
    background: rgba(255, 255, 255, 0.1);
}

.admin-content {
    padding: 15px;
}

.admin-actions {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    margin-bottom: 15px;
}

.admin-action-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
    width: 100%;
    text-align: left;
}

.admin-action-btn:hover {
    background: rgba(255, 255, 255, 0.1);
    transform: translateX(2px);
}

.admin-action-btn i {
    font-size: 18px;
    color: #888;
}

.profile-picture-upload {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.profile-picture-upload h4 {
    margin: 0 0 10px 0;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
}

.upload-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.preview-image {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    object-fit: cover;
}

.upload-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px;
    background: #25c100;
    border: none;
    border-radius: 6px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.upload-btn:hover {
    background: #1e9600;
}

@media (max-width: 768px) {
    .admin-panel {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        max-width: 300px;
        margin: 0;
    }
}

/* Remove old admin panel styles */
.profile-actions,
.tip_btn,
.tip-icon-wrapper,
.admin-toggle {
    display: none;
}

.user-id-section {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #888;
    font-size: 14px;
    margin-top: 15px;
    font-family: 'Gilroy', sans-serif;
}

.status-orb {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
    animation: pulse 2s infinite;
}

.status-orb.online {
    background: #25c100;
    box-shadow: 0 0 10px rgba(37, 193, 0, 0.5);
}

.status-orb.offline {
    background: #ff6b6b;
    box-shadow: 0 0 10px rgba(255, 107, 107, 0.5);
}

/* Mobile Responsive */
@media only screen and (max-width: 768px) {
    .home {
        padding: 15px;
        padding-top: 70px;
    }
    
    .profile-header {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 20px;
    }
    
    .username-section {
        align-items: center;
    }
    
    .user-badges {
        justify-content: center;
    }
    
    .user-stats, .user-additional-stats {
        grid-template-columns: 1fr;
    }
    
    .profile-content {
        padding: 20px;
    }
    
    .h-bar-0-mobile {
        height: 60px;
        padding: 0 15px;
        background: #1a1a1a;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    }
    
    .new_top_head {
        font-size: 18px;
        margin: 0;
        color: #fff;
        font-family: 'Gilroy', sans-serif;
    }
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideUp {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
}

.animate-fade-in {
    animation: fadeIn 0.5s ease-out;
}

.animate-slide-up {
    animation: slideUp 0.5s ease-out;
}

.profile-header {

    transition: transform 0.3s ease;
}



.stat-card, .additional-stat {
    transition: all 0.3s ease;

    backdrop-filter: blur(10px);
}

.stat-card:hover, .additional-stat:hover {
    transform: translateY(-2px);
    background: rgba(255, 255, 255, 0.08);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.admin-panel {
    background: linear-gradient(135deg, #1a1a1a 0%, #2a2a2a 100%);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}

.admin-header {
    background: rgba(255, 255, 255, 0.05);
    padding: 15px;
    border-radius: 8px 8px 0 0;
}

.admin-content {
    animation: fadeIn 0.3s ease-out;
}

.admin-stat {
    transition: all 0.3s ease;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
}

.admin-stat:hover {
    transform: translateY(-2px);
    background: rgba(255, 255, 255, 0.08);
}

.admin-btn {
    transition: all 0.3s ease;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
}

.admin-btn:hover {
    transform: translateY(-2px);
    background: rgba(255, 255, 255, 0.08);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.name-and-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}

.quick-actions {
    display: flex;
    gap: 8px;
}

.action-btn {
    width: 36px;
    height: 36px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.05);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.action-btn i {
    font-size: 18px;
}

.action-btn:hover {
    background: rgba(255, 255, 255, 0.1);
    transform: translateY(-2px);
}

.tip-btn:hover {
    border-color: rgba(37, 193, 0, 0.3);
}

.admin-btn:hover {
    border-color: rgba(255, 107, 107, 0.3);
}

.bio-section {
    margin: 20px 0;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    overflow: hidden;
}

.bio-content {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    padding: 20px;
}

.bio-icon {
    color: #25c100;
    font-size: 20px;
    margin-top: 2px;
}

.bio-text {
    flex: 1;
}

.bio-meta {
    display: flex;
    gap: 15px;
    margin-bottom: 10px;
}

.bio-meta span {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #888;
    font-size: 14px;
}

.bio-meta i {
    font-size: 16px;
    color: #25c100;
}

.bio-text p {
    margin: 0;
    color: #fff;
    font-size: 14px;
    line-height: 1.6;
}

.bio-content.no-bio p {
    color: #888;
    font-style: italic;
}

/* Remove old styles */
.profile-actions,
.tip_btn,
.tip-icon-wrapper,
.admin-toggle,
.admin-panel {
    display: none;
}

/* Admin Panel Popup Styles */
.admin-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-out;
}

.admin-popup {
    background: linear-gradient(135deg, #1a1a1a 0%, #2a2a2a 100%);
    border-radius: 16px;
    width: 90%;
    max-width: 500px;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.1);
    animation: slideUp 0.3s ease-out;
}

.admin-toggle-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 20px;
    background: linear-gradient(135deg, #ff6b6b 0%, #ff5252 100%);
    border: none;
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 15px;
}

.admin-toggle-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(255, 107, 107, 0.3);
}

.admin-toggle-btn i {
    font-size: 20px;
}

.admin-header {
    padding: 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.05);
}

.admin-header h3 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}

.admin-content {
    padding: 24px;
}

.user-status {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    padding: 12px 16px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.status-text {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
}

.admin-stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    margin-bottom: 24px;
}

.admin-stat {
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 16px;
    text-align: center;
    transition: all 0.3s ease;
}

.admin-stat:hover {
    transform: translateY(-2px);
    background: rgba(255, 255, 255, 0.08);
    border-color: rgba(37, 193, 0, 0.3);
}

.admin-stat i {
    font-size: 24px;
    color: #25c100;
    margin-bottom: 8px;
}

.admin-stat span {
    display: block;
    color: #fff;
    font-size: 12px;
    margin-bottom: 4px;
    opacity: 0.7;
}

.admin-stat strong {
    display: block;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
}

.admin-section {
    margin-bottom: 24px;
}

.section-title {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 16px 0;
    opacity: 0.9;
}

.admin-actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
}

.admin-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
}

.admin-btn:hover {
    background: rgba(255, 255, 255, 0.08);
    border-color: rgba(37, 193, 0, 0.3);
    transform: translateY(-2px);
}

.admin-btn i {
    font-size: 20px;
    color: #25c100;
}

.profile-picture-upload {
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 16px;
}

.upload-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.upload-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 120px;
    position: relative;
}

.file-input {
    display: none;
}

.file-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 24px;
    background: rgba(255, 255, 255, 0.03);
    border: 2px dashed rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
}

.file-label:hover {
    background: rgba(255, 255, 255, 0.05);
    border-color: #25c100;
}

.file-label i {
    font-size: 32px;
    color: #25c100;
}

.file-label span {
    color: #fff;
    font-size: 14px;
    opacity: 0.7;
}

.preview-image {
    width: 120px;
    height: 120px;
    border-radius: 12px;
    object-fit: cover;
    border: 2px solid #25c100;
}

.upload-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 12px;
    background: #25c100;
    border: none;
    border-radius: 12px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
}

.upload-btn:hover {
    background: #1e9600;
    transform: translateY(-2px);
}

.upload-btn i {
    font-size: 20px;
}

@media only screen and (max-width: 768px) {
    .admin-stats {
        grid-template-columns: 1fr;
    }
    
    .admin-actions {
        grid-template-columns: 1fr;
    }
    
    .admin-popup {
        width: 95%;
        margin: 10px;
    }
}
</style>