<template>
  <div class="system"

 
 :style="!chatEnabled ? { width: 'calc(100vw + 220px)' } : { height: '100vh' }"


        >
    <div v-if="dropdownVisible" style="
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: rgb(4 4 4 / 20%);
        z-index: 69;
      "
      
      
      
       @click="dropdownVisible = false"></div>

    <aside style="width: 200px">
      <img class="MAIN_ASIDE pointer" style="height: auto !important; flex-direction: row" src="./assets/mobile_header.png"
        @click="$router.push('/')" />
      <div class="d_w_sec" style="margin-top: 0px" v-if="!logged_in">
        <div class="d_w_padding_0">
          <button class="crea_acc" v-if="windowWidth > 1350" @click="$root.modal_open = 'create_a'">
            Create Account
          </button>
          <button class="crea_acc" v-else @click="$root.modal_open = 'c_acc_ipad'">
            Create Account
          </button>
          <button class="withdrawl_cc pointer" @click="$root.modal_open = 'login'">
            Login
          </button>
        </div>
      </div>
      <div v-else>
        <div class="picture_section" style="padding: 0px 20px">
          <img class="dp pointer" @click="$router.push('/profile')" v-tooltip="'View profile'"
            :src="`${this.API_PREFIX}/api/DisplayPicture?name=${$root.user.name}`" />
        </div>
        <div class="name_section">
          <a class="name pointer" style="display: flex; align-items: center; justify-content: center">
            <span class="Namebsoer"   v-tooltip="`View profile`" 
@click="$router.push('/profile')">{{
              $root.user.name
            }}</span>
            <LevelBadgeComponent v-tooltip="`Total XP : ${$root.user.total_wager * 150}`"
              v-if="$root.user.total_wager != null" :total_wager="$root.user.total_wager" />
            <i @click="$router.push('/profile')" v-tooltip="`Profile`" class="material-icons spcur">person</i>
          </a>
        </div>
        <div class="price_section">
          <div class="price_BOX_new">
            <img class="price_LOGO" src="./assets/gemstone.png" />
            <number ref="number1" :to="user.balance" :format="theFormat" :duration="0.4" easing="Power1.easeOut" />
          </div>
          <div v-if="showAnimation" :class="['balance-animation', animationType]" @animationend="resetAnimation">
            {{ animationValue }}
          </div>
        </div>
        <div class="d_w_sec" style="width: unset">
          <div class="d_w_padding">
            <button   v-tooltip="`Deposit Now`"   @click="$router.push('/deposit_m')" class="d_pose">
              Deposit</button><button @click="$router.push('/withdraw_m')"  v-tooltip="`Withdraw`" class="widthdawlee">
              Withdraw
            </button>
          </div>
        </div>
      </div>
      <!-- <button @click="updateBalance(10)" class="add-btn">Add 10</button>
            <button @click="updateBalance(-10)" class="subtract-btn">Subtract 10</button> -->

      <div  v-tooltip="`Admin panel`"  v-if="windowHeight > 707 && this.user.admin == 1" :class="this.$route.matched.some((route) => route.path.includes('/admin'))
        ? 'aside_selected'
        : ''
        " @click="$router.push('/admin')" class="aside_btn_small pointer mr_left">
        <a style="color: #c95555; font-size: 15px" :class="this.$route.matched.some((route) => route.path.includes('/admin'))
          ? 'Selected_topbar'
          : ''
          " class="pointer" @click="$router.push('/admin')">Admin Panel</a>
      </div>

      <div v-tooltip="`Don't trust, Verify`" v-if="windowHeight > 707" @click="$router.push('/provably-fair')" :class="this.$route.matched.some((route) =>
        route.path.includes('/provably-fair')
      )
        ? 'aside_selected'
        : ''
        " class="aside_btn_small pointer mr_left">
        <a>Provably Fair</a>
      </div>
      <div v-tooltip="`Frequently asked Questions`"  v-if="windowHeight > 707" @click="$router.push('/faq')" :class="this.$route.matched.some((route) => route.path.includes('/faq'))
        ? 'aside_selected'
        : ''
        " class="aside_btn_small pointer mr_left">
        <a>FAQ</a>
      </div>
      <div v-tooltip="'Live Customer Service 24/7'" v-if="windowHeight > 707" @click="$router.push('/support')" :class="this.$route.matched.some((route) => route.path.includes('/support'))
        ? 'aside_selected'
        : ''
        " class="aside_btn_small pointer mr_left">
        <a>Support</a>
      </div>
      <!-- <div v-if="windowHeight > 707" @click="$router.push('/settings')" :class="this.$route.matched.some((route) => route.path.includes('/settings'))
        ? 'aside_selected'
        : ''
        " class="aside_btn_small pointer mr_left">
        <a>Settings</a>
      </div> -->
      <div class="flex_smaerl_holder" v-if="windowHeight < 707">
        <a class="pointer e323" @click="$router.push('/provably-fair')">Provably Fair
        </a>
        <a class="pointer e323" @click="$router.push('/faq')">FAQ </a>
        <a class="pointer e323" @click="$router.push('/support')">Support</a>
        <!-- <a class="pointer e323" @click="$router.push('/settings')">Settings</a> -->
      </div>
      <a v-if="windowHeight > 824" class="legal legalz">Crypto Is Cool N.V. is the proprietor and administrator of
        CryptoFlip.GG, with a registered number of 173193 and registered address
        located at Fransche Bloemweg 4, Willemstad, Curaçao. You may reach us at
        support@cryptoflip.gg. The payment processing is handled by Hunter Is
        Cool N.V., which is based at 7-9 Riga Feraiou, LIZANTIA COURT, Office
        310, Long Distance Links, 1087 Nicosia, Cyprus and is registered under
        HE 450921. CryptoFlip.GG holds a license from the government of Curacao,
        with the license number being 3012/JEZ, and all games and wagering
        activities carried out by CryptoFlip.GG comply with all relevant
        regulations. For legal inquiries, please email legal@cryptoflip.gg, and
        for press-related matters, email press@cryptoflip.gg. For further
        support, you can reach us at support@cryptoflip.gg.
      </a>
      <a v-else class="legal legalz" style="font-size:9px !important;">Crypto Is Cool N.V. is the proprietor and administrator of
        CryptoFlip.GG, with a registered number of 173193 and registered address
        located at Fransche Bloemweg 4, Willemstad, Curaçao.
      </a>
    </aside>

    <!-- <button @click="toggleChatEnabled">{{ chatEnabled ? 'Disable Chat' : 'Enable Chat' }}</button> -->


    <main :style="{ width: chatEnabled ? '100vw' : 'calc(100% - 100px)', height: windowHeight + 'px' }">
    
    <div id="h_DESKTOP">
        <div class="dropdown">
          <button :class="dropdownVisible ? 'Selected_topbar' : ''" @click="toggleDropdown" class="dropbtn">
            Select a game
          </button>
          <div v-if="dropdownVisible" class="dropdown-content" style="z-index: 369">
            <a class="pointer" @click="
              $router.push('/coinflip');
            dropdownVisible = false;
            ">
              <img src="./assets/game_icons/cf.png" style="height: 30px; width: 30px" alt="Coinflip Icon" />
              Crypto Flip
            </a>

            <a class="pointer" @click="
              $router.push('/jackpot');
            dropdownVisible = false;
            ">
              <img src="./assets/game_icons/jp.png" style="height: 30px; width: 30px" alt="Coinflip Icon" />Jackpot
            </a>
            <a class="pointer" @click="
              $router.push('/roulette');
            dropdownVisible = false;
            ">
              <img src="./assets/game_icons/rou_icon.png" style="height: 30px; width: 30px" alt="Coinflip Icon" />
              Roulette
            </a>

            <a class="pointer" @click="
              $router.push('/dice');
            dropdownVisible = false;
            ">
              <img src="./assets/game_icons/di.png" style="height: 30px; width: 30px" alt="Coinflip Icon" />Dice</a>
            <a class="pointer" @click="
              $router.push('/upgrade');
            dropdownVisible = false;
            ">
              <img src="./assets/game_icons/up.png" style="height: 30px; width: 30px" alt="Coinflip Icon" />Upgrade
            </a>

            <a class="pointer" @click="
              $router.push('/fast');
            dropdownVisible = false;
            ">
              <img src="./assets/game_icons/crash.png" style="height: 30px; width: 30px" alt="Coinflip Icon" />Fast
            </a>

            <a class="pointer" @click="
              $router.push('/slot');
            dropdownVisible = false;
            ">
              <img src="./assets/game_icons/sl.png" style="height: 30px; width: 30px" alt="Coinflip Icon" />Slots
            </a>

            <a class="pointer" @click="
              $router.push('/plinko');
            dropdownVisible = false;
            ">
              <img src="./assets/game_icons/pl.png" style="height: 30px; width: 30px" alt="Coinflip Icon" />Plinko
            </a>
            <a class="pointer" @click="
              $router.push('/blackjack');
            dropdownVisible = false;
            ">
              <img src="./assets/game_icons/bj.png" style="height: 30px; width: 30px" alt="Coinflip Icon" />Blackjack
            </a>
            <a class="pointer" @click="
              $router.push('/squares');
            dropdownVisible = false;
            ">
              <img src="./assets/game_icons/ch.png" style="height: 30px; width: 30px" alt="Coinflip Icon" />Squares
            </a>
          </div>
        </div>

        <a v-tooltip="'Referral section'" :class="!dropdownVisible &&
          this.$route.matched.some((route) =>
            route.path.includes('/affiliates')
          )
          ? 'Selected_topbar'
          : ''
          " class="pointer" @click="$router.push('/affiliates')" style="display: flex">Free <img class="price_LOGO"
            src="./assets/gemstone.png" /></a>
        <a v-tooltip="'FREE GEMS'"  :class="!dropdownVisible &&
          this.$route.matched.some((route) => route.path.includes('/rakeback'))
          ? 'Selected_topbar'
          : ''
          " class="pointer" @click="$router.push('/rakeback')">Rakeback</a>

       



        <a   v-tooltip="`ToS`"  :class="!dropdownVisible &&
          this.$route.matched.some((route) =>
            route.path.includes('/terms-of-service')
          )
          ? 'Selected_topbar'
          : ''
          " class="pointer" @click="$router.push('/terms-of-service')">Terms of Service</a>



        <a v-tooltip="`Privacy Policy
`" :class="!dropdownVisible &&
          this.$route.matched.some((route) =>
            route.path.includes('/privacy-policy')
          )
          ? 'Selected_topbar'
          : ''
          " class="pointer"
       
           @click="$router.push('/privacy-policy')">Privacy</a>



        <a    v-tooltip="`CryptoFlip.GG's story`"  :class="!dropdownVisible &&
          this.$route.matched.some((route) =>
            route.path.includes('/our-story')
          )
          ? 'Selected_topbar'
          : ''
          " class="pointer" @click="$router.push('/our-story')">Our Story</a>

<div style="display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;">




        <i v-if="!chatEnabled " v-tooltip="'Vault'"   @click="$root.modal_open = 'vault'" style="margin-left: -5px; cursor: pointer"
          class="material-icons">account_balance_wallet</i>


          <i v-if="!chatEnabled"  v-tooltip="'Open chat'" @click="toggleChatEnabled" 
   style="margin-left: -5px; cursor: pointer" 
   class="material-icons">
   chat
</i>

</div>
      </div>
      <div id="h" class="mobile-header">
        <template v-if="user.self_locked.locked">
          <div class="locked-account">
            <i class="material-icons">lock</i>
            <span>Account Locked</span>
          </div>
        </template>
        <template v-else>
          <div class="mobile-header-content">
            <div class="left-actions">
              <a v-if="this.user.admin == 1" 
                 @click="$router.push('/admin')"
                 class="admin-button">
                <i class="material-icons">admin_panel_settings</i>
              </a>
              
              <button class="action-button" @click="this.$root.modal_open = 'm_chat'">
                <i class="material-icons">chat</i>
              </button>
            </div>

            <div class="center-logo" @click="$router.push('/')">
              <img class="mobile-logo" src="./assets/mobile_header.png" alt="CryptoFlip.GG" />
            </div>

            <div class="right-actions">
              <button class="action-button" @click="$root.modal_open = 'vault'">
                <i class="material-icons">account_balance_wallet</i>
              </button>
            </div>
          </div>
        </template>
      </div>
      <div id="b">
        <template v-if="user.self_locked != undefined && user.self_locked.locked">
          <main class="mainer mobile_locked">
            <img class="MAIN_ASIDE pointer" style="
                height: auto !important;
                flex-direction: row;
                width: 245px;
                margin: 0 auto;
              " src="./assets/logo.png" @click="$router.push('/coinflip')" />
            <a class="name3">Your account has been locked temporarily at your request.</a>
            <br />
            <a class="name" style="
                color: #f0f8ff;
                font-family: monospace;
                text-align: center;
                font-size: 16px;
                background: rgb(255 9 9 / 33%);
                padding: 20px;
              ">Reason: {{ user.self_locked.reason }}</a>
            <br />
            <a class="name" style="font-size: 20px; display: flex; align-items: center">
              <span style="margin-right: 6px">
                Your account will be accessable</span>
              <timeago v-if="user.self_locked.expires" :datetime="user.self_locked.expires" />
              <i class="material-icons">access_alarm</i>
            </a>
            <br />

            <span style="color: rgb(177, 177, 177)" class="name">Still need support? You can still email our support at
              <a style="color: rgb(8 255 0 / 76%)" href="mailto:support@cryptoflip.gg">support@cryptoflip.gg</a></span>
            <br />
            <div style="display: flex; color: white">
              <a @click="reloadPageAndRemoveToken" style="
                  cursor: pointer;
                  color: rgb(255, 255, 255);
                  margin-left: -1px;
                  margin-right: 4px;
                ">Logout </a><i @click="reloadPageAndRemoveToken" class="material-icons pointer">exit_to_app</i>
            </div>
          </main>
        </template>
        <template v-else>
          <!-- https://www.softr.io/tools/svg-shape-generator -->
          <svg v-if="$route.path == '/'" class="svgShape" width="100%" height="100%" viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg">
            <path fill="grey"
              d="M3.6,-9.8C4.9,-5.5,6.4,-5,9.8,-4C13.3,-3,18.6,-1.5,21.7,1.8C24.8,5.1,25.7,10.2,22.1,10.9C18.5,11.6,10.4,7.8,6,5.3C1.6,2.8,0.8,1.6,-1.8,4.7C-4.4,7.8,-8.8,15.3,-9.9,15.9C-11.1,16.5,-9,10.2,-13.3,6.4C-17.6,2.6,-28.3,1.3,-28.9,-0.4C-29.5,-2,-20,-4.1,-15.6,-7.7C-11.2,-11.3,-11.8,-16.4,-10.1,-20.5C-8.3,-24.6,-4.2,-27.7,-1.5,-25.1C1.1,-22.5,2.3,-14.2,3.6,-9.8Z"
              transform="translate(50 50)" stroke-width="0" style="transition: 0.3s"></path>
          </svg>

          <!-- <div style="
              max-width: 1200px;
              margin: 0px auto;
              width: 70%;
              margin-top: 20px;
            " class="alert_msgx" v-if="
              $cookies.isKey(`newVisitFalse`) == false &&
              $root.msg_of_the_day != ''
            ">
            {{ $root.msg_of_the_day }}
            <i @click="
              $cookies.set(`newVisitFalse`, `set`, 60 * 60 * 12 * 20),
              ($root.msg_of_the_day = '')
              " class="material-icons zzzzznz">cancel</i>
          </div> -->

          <div style="
              width: 1080px;
              margin: 0px auto;
              margin-top: 0px;
              margin-top: 20px;
            " class="alert_msgxx" v-if="$root.enable_games == 0">
            <i class="material-icons" style="font-size: 40px">warning</i>MAINTENANCE NOW: Games may not be created,
            joined or cancelled due
            to an impending service restart.<i style="font-size: 40px" class="material-icons">warning</i>
          </div>

          <!-- <div  class="alert_msgx4">Happy Halloween! - CryptoFlip.GG extends to you a spooky and eerie Halloween! 🎃👻🕷️💀</div>  -->
          <div class="alert_msgx3" style="
              width: 1080px;
              margin: 0px auto;
              margin-top: 0px;
              margin-top: 20px;
            " v-if="$root.admin_msg_socket != ''">
            <div class="block_bleow">
              <a class="admin_txt_msg">LIVE - Message from Admin:</a>
              <div class="alert_msgx3_intter">
                {{ $root.admin_msg_socket }}
                <i @click="$root.admin_msg_socket = ''" style="margin-top: -10px"
                  class="material-icons zzzzznz">cancel</i>
              </div>
            </div>
          </div>

          <router-view v-slot="{ Component }">
            <transition name="fade" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
          
          <!-- Loading Overlay -->
          <transition name="fade">
            <div v-if="isRouteLoading" class="route-loading-overlay">
              <i class="material-icons loading-spinner">refresh</i>
            </div>
          </transition>
        </template>
      </div>
      <div id="f">
        <div class="m_footer_wrapper">
          <div class="m_footer" @click="$router.push('/') && (this.$root.modal_open = false)" :class="this.$root.modal_open == false && $route.path == '/'
            ? 'lime_selected'
            : ''
            ">
            <i class="material-icons">home</i>
            <a>Home</a>
          </div>

          <!-- <div
           @click="this.$root.modal_open = 'gamesPick'"
       
            :class="
              this.$root.modal_open == 'gamesPick' ? 'lime_selected' : ''
            "
            class="m_footer"
          >
            <i class="material-icons">queue</i>
            <a>Games</a>















          </div> -->

          <template v-if="!logged_in">
            <div class="m_footer" @click="this.$root.modal_open = 'login'"
              :class="this.$root.modal_open == 'login' ? 'lime_selected' : ''">
              <i class="material-icons">get_app</i>
              <a>Login</a>
            </div>
            <div class="m_footer" @click="
              $router.push('/create-account') &&
              (this.$root.modal_open = false)
              " :class="this.$route.matched.some((route) =>
                route.path.includes('/create-account')
              ) && this.$root.modal_open == false
                ? 'lime_selected'
                : ''
                ">
              <i class="material-icons">account_balance_wallet</i>
              <a>Signup</a>
            </div>
            <div class="m_footer" @click="$router.push('/faq') && (this.$root.modal_open = false)" :class="this.$root.modal_open == false && $route.path == '/history/faq'
              ? 'lime_selected'
              : ''
              ">
              <i class="material-icons">drag_handle</i>
              <a>FaQ</a>
            </div>
            <div class="m_footer" @click="$router.push('/terms-of-service') && (this.$root.modal_open = false)" :class="this.$root.modal_open == false && $route.path == '/terms-of-service'
              ? 'lime_selected'
              : ''
              ">
              <i class="material-icons">all_out</i>
              <a>Terms</a>
            </div>
          </template>
          <template v-if="logged_in">
            <div class="m_footer" @click="
              $router.push('/deposit_m') && (this.$root.modal_open = false)
              " :class="this.$root.modal_open == false &&
                this.$route.matched.some((route) =>
                  route.path.includes('/deposit')
                )
                ? 'lime_selected'
                : ''
                ">
              <i class="material-icons">get_app</i>
              <a>Deposit</a>
            </div>

            <div v-if="logged_in" class="price_section">
              <div class="price_BOX_new" style="width: unset; padding: 2px 5px">
                <img class="price_LOGO" src="./assets/gemstone.png" />
                <number ref="number1" :to="user.balance" :format="theFormat" :duration="0.4" easing="Power1.easeOut" />
              </div>
              <div v-if="showAnimation" :class="['balance-animation', animationType]" @animationend="resetAnimation">
                {{ animationValue }}
              </div>
            </div>

            <div class="m_footer" @click="
              $router.push('/withdraw_m') && (this.$root.modal_open = false)
              " :class="this.$root.modal_open == false && $route.path == '/withdraw_m'
                ? 'lime_selected'
                : ''
                ">
              <i class="material-icons">account_balance_wallet</i>
              <a>Withdrawl</a>
            </div>
            <div class="m_footer" @click="
              $router.push('/profile') && (this.$root.modal_open = false)
              " :class="this.$root.modal_open == false && $route.path == '/profile'
                ? 'lime_selected'
                : ''
                ">
              <i class="material-icons">person</i>
              <a>Profile</a>
            </div>
          </template>
        </div>
      </div>
    </main>

    <aside class="chatBoxMain" :style="chatEnabled   ? { width: '270px' } : { left: '270px' }" v-if="$root.modal_open != 'm_chat' ">

      <div class="chat_wrapper">
        <i v-if="$root.modal_open == 'm_chat'" @click="$root.modal_open = false"
          class="material-icons pointer">arrow_back</i>
        <i style="margin-left: -5px; cursor: pointer"  v-tooltip="'Open Vault'"  @click="$root.modal_open = 'vault'"
          class="material-icons">account_balance_wallet</i>
        Chat <i style="margin-left: 10px;cursor: pointer" v-tooltip="'Close chat'"    @click="toggleChatEnabled" class="material-icons">chat</i>
      </div>
      <ChatComp />
    </aside>
    <Transition data-space-modal="inspect_game" name="fade">
      <div v-if="$root.modal_open == 'inspect_game'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div v-if="$root.modal_open != ''" class="modal-c" @mousedown.stop>
            <div class="h_e">
              <i @click="$root.modal_open = false" class="material-icons pointer">arrow_back</i>
              <div class="holderxz">
                <h1 class="new_iitt">
                  Game #{{
                    $root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                      .game_no
                  }}
                </h1>
                <button @click="cancel_game()" class="btnx" v-if="
                  enable_games &&
                  $root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                    .c_name == $root.user.name &&
                  !$root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                    .j_name &&
                  $root.logged_in
                ">
                  Cancel
                </button>
              </div>
              <i @click="$root.modal_open = false" style="color: #111b11" class="material-icons">arrow_back</i>
            </div>
            <div class="modal-body" style="margin-top: 20px">
              <!-- Mobile 1v1 style header -->
              <div class="name_holder">
                <div class="filler">
                  <div class="con_1" style="margin-right: 5px">
                    <img @click="
                      $root.viewUserProfile(
                        $root.inspected_cf_fetch(
                          $root.cfs,
                          $root.uuid_inspected
                        ).c_name
                      )
                      " :class="[
                        $root.inspected_cf_fetch(
                          $root.cfs,
                          $root.uuid_inspected
                        ).c_side == 0
                          ? 'pic__green'
                          : 'pic__purple',
                      ]" v-if="
                        $root.inspected_cf_fetch(
                          $root.cfs,
                          $root.uuid_inspected
                        ).c_name
                      " :src="`${this.API_PREFIX}/api/DisplayPicture?name=${inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                        .c_name
                        }`" />

                    <div v-if="
                      $root.inspected_cf_fetch(
                        $root.cfs,
                        $root.uuid_inspected
                      ).c_side == 1
                    " class="purp_coin_left">
                      <img class="pic__Coin" src="./assets/purple_coin.png" />
                    </div>
                    <div class="green_coin_left" v-else>
                      <img class="pic__Coin" src="./assets/green_coin.png" />
                    </div>
                  </div>
                  <div class="filler">
                    <div class="eswz_w">
                      <div class="filler maring">
                        <a class="namey__2">
                          <NameComponent :username="$root.inspected_cf_fetch(
                            $root.cfs,
                            $root.uuid_inspected
                          ).c_name
                            " />
                        </a>
                        <NewLevelBadgeComponent :username="$root.inspected_cf_fetch(
                          $root.cfs,
                          $root.uuid_inspected
                        ).c_name
                          " />
                      </div>
                      <div class="price_section4">
                        <div class="prBn">
                          <img class="price_LOGO" src="./assets/gemstone.png" />{{
                            $root.inspected_cf_fetch(
                              $root.cfs,
                              $root.uuid_inspected
                            ).wager
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="filler">
                  <div class="filler">
                    <div class="eswz_w" v-if="
                      $root.inspected_cf_fetch(
                        $root.cfs,
                        $root.uuid_inspected
                      ).j_name
                    ">
                      <div class="filler maring">
                        <a class="namey__2">
                          <NameComponent :username="$root.inspected_cf_fetch(
                            $root.cfs,
                            $root.uuid_inspected
                          ).j_name
                            " />
                        </a>
                        <NewLevelBadgeComponent v-if="
                          $root.inspected_cf_fetch(
                            $root.cfs,
                            $root.uuid_inspected
                          ).j_name
                        " :username="$root.inspected_cf_fetch(
                          $root.cfs,
                          $root.uuid_inspected
                        ).j_name
                          " />
                        <a v-if="
                          !$root.inspected_cf_fetch(
                            $root.cfs,
                            $root.uuid_inspected
                          ).j_name
                        " class="name_c">Nobody</a>
                      </div>
                      <div class="price_section" v-if="
                        $root.inspected_cf_fetch(
                          $root.cfs,
                          $root.uuid_inspected
                        ).j_name
                      ">
                        <div class="prBn">
                          <img class="price_LOGO" src="./assets/gemstone.png" />{{
                            $root.inspected_cf_fetch(
                              $root.cfs,
                              $root.uuid_inspected
                            ).wager
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="con_3" style="margin-left: 5px">
                    <img v-if="
                      !$root.inspected_cf_fetch(
                        $root.cfs,
                        $root.uuid_inspected
                      ).j_name
                    " src="./assets/no_joiner.png" class="no_joinerz" />
                    <a v-else class="name_c">
                      <img class="pic__" @click="
                        $root.viewUserProfile(
                          $root.inspected_cf_fetch(
                            $root.cfs,
                            $root.uuid_inspected
                          ).j_name
                        )
                        " :class="[
                          $root.inspected_cf_fetch(
                            $root.cfs,
                            $root.uuid_inspected
                          ).c_side == 1
                            ? 'pic__green'
                            : 'pic__purple',
                        ]" :src="`${this.API_PREFIX}/api/DisplayPicture?name=${inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                          .j_name
                          }`" />
                    </a>
                    <div v-if="
                      $root.inspected_cf_fetch(
                        $root.cfs,
                        $root.uuid_inspected
                      ).c_side != 1 &&
                      $root.inspected_cf_fetch(
                        $root.cfs,
                        $root.uuid_inspected
                      ).j_name
                    " class="purp_coin_right">
                      <img class="pic__Coin" src="./assets/purple_coin.png" />
                    </div>
                    <div v-if="
                      $root.inspected_cf_fetch(
                        $root.cfs,
                        $root.uuid_inspected
                      ).c_side != 0 &&
                      $root.inspected_cf_fetch(
                        $root.cfs,
                        $root.uuid_inspected
                      ).j_name
                    " class="green_coin_right">
                      <img class="pic__Coin" src="./assets/green_coin.png" />
                    </div>
                  </div>
                </div>
              </div>
              <!-- CONATINER -->
              <div class="coinflip_cointaner">
                <!-- PLAYER 1 -->
                <div class="con_1 non_smaller" style="margin-right: 5px">
                  <img @click="
                    $root.viewUserProfile(
                      $root.inspected_cf_fetch(
                        $root.cfs,
                        $root.uuid_inspected
                      ).c_name
                    )
                    " :class="[
                      $root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                        .c_side == 0
                        ? 'pic__green'
                        : 'pic__purple',
                    ]" v-if="
                      $root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                        .c_name
                    " :src="`${this.API_PREFIX}/api/DisplayPicture?name=${inspected_cf_fetch($root.cfs, $root.uuid_inspected).c_name
                      }`" />

                  <div v-if="
                    $root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                      .c_side == 1
                  " class="purp_coin_left">
                    <img class="pic__Coin" src="./assets/purple_coin.png" />
                  </div>
                  <div class="green_coin_left" v-else>
                    <img class="pic__Coin" src="./assets/green_coin.png" />
                  </div>
                  <div class="ew_NEW">
                    <div class="filler maring">
                      <!-- <a class="namey__2">{{ $root.inspected_cf_fetch($root.cfs, $root.uuid_inspected).c_name }}</a> -->

                      <a class="namey__2">
                        <NameComponent :username="$root.inspected_cf_fetch(
                          $root.cfs,
                          $root.uuid_inspected
                        ).c_name
                          " />
                      </a>

                      <NewLevelBadgeComponent :username="$root.inspected_cf_fetch(
                        $root.cfs,
                        $root.uuid_inspected
                      ).c_name
                        " />
                    </div>
                    <div class="price_section4">
                      <div class="prBn">
                        <img class="price_LOGO" src="./assets/gemstone.png" />{{
                          $root.inspected_cf_fetch(
                            $root.cfs,
                            $root.uuid_inspected
                          ).wager
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- NO JOINER -->
                <div class="con_4" v-if="
                  !$root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                    .j_name
                ">
                  <a class="middle_txt">Waiting</a>
                </div>
                <!-- IS COUNTING WITH CIRCLE-->
                <div class="con_4 font-z" v-else-if="
                  $root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                    .counting
                ">
                  <radial-progress-bar :diameter="100" :animateSpeed="1000" :completed-steps="$root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                    .completedSteps
                    " :total-steps="7" :start-color="'#ffee23'" :stop-color="'#f0ce08'">
                    <p :id="id" style="color: #ffee23">
                      {{
                        $root.inspected_cf_fetch(
                          $root.cfs,
                          $root.uuid_inspected
                        ).completedSteps
                      }}
                    </p>
                  </radial-progress-bar>
                </div>
                <!-- ROLLING -->
                <div class="con_4" :class="[
                  inspected_cf_fetch(cfs, uuid_inspected).winner_side
                    ? `con_4_green`
                    : `con_4_purple`,
                ]" v-else-if="
                  $root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                    .rolling
                ">
                  <div style="display: block">
                    <div class="coin-wrapper fadeIn">
                      <div id="flip" class="coin" :class="[
                        $root.inspected_cf_fetch(
                          $root.cfs,
                          $root.uuid_inspected
                        ).winner_side
                          ? `animate-rr`
                          : `animate-rb`,
                      ]">
                        <div id="headsz"></div>
                        <div id="tailsz"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END SCREEN -->
                <div class="con_4" v-else-if="
                  !$root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                    .rolling
                ">
                  <div v-if="
                    $root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                      .winner_side == 1
                  " class="purp_coin">
                    <img v-tooltip="'Purple'" class="logo" style="width: 100%" src="./assets/purple_coin.png" />
                  </div>
                  <div v-if="
                    $root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                      .winner_side == 0
                  " class="green_coin">
                    <img v-tooltip="'Green'" class="logo" style="width: 100%" src="./assets/green_coin.png" />
                  </div>
                  <div class="roller_sizer" v-if="
                    $root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                      .j_name &&
                    !$root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                      .rolling &&
                    !$root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                      .counting
                  ">
                    {{
                      $root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                        .roll
                    }}%
                  </div>
                </div>
                <!-- PLAYER 2 -->
                <div class="con_3 non_smaller" style="margin-left: 5px">
                  <img v-if="
                    !$root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                      .j_name
                  " src="./assets/no_joiner.png" class="no_joinerz" />
                  <a v-else class="name_c">
                    <img @click="
                      $root.viewUserProfile(
                        $root.inspected_cf_fetch(
                          $root.cfs,
                          $root.uuid_inspected
                        ).j_name
                      )
                      " class="pic__" :class="[
                        $root.inspected_cf_fetch(
                          $root.cfs,
                          $root.uuid_inspected
                        ).c_side == 1
                          ? 'pic__green'
                          : 'pic__purple',
                      ]" v-if="
                        $root.inspected_cf_fetch(
                          $root.cfs,
                          $root.uuid_inspected
                        ).j_name
                      " :src="`${this.API_PREFIX}/api/DisplayPicture?name=${inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                        .j_name
                        }`" />
                  </a>
                  <div v-if="
                    $root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                      .c_side != 1 &&
                    $root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                      .j_name
                  " class="purp_coin_right">
                    <img class="pic__Coin" src="./assets/purple_coin.png" />
                  </div>
                  <div v-if="
                    $root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                      .c_side != 0 &&
                    $root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                      .j_name
                  " class="green_coin_right">
                    <img class="pic__Coin" src="./assets/green_coin.png" />
                  </div>
                  <div class="ew_NEW" v-if="
                    $root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                      .j_name
                  ">
                    <div class="filler maring">
                      <!-- <a  class="namey__2">{{$root.inspected_cf_fetch($root.cfs, $root.uuid_inspected).j_name}}</a> -->
                      <a class="namey__2" v-if="
                        $root.inspected_cf_fetch(
                          $root.cfs,
                          $root.uuid_inspected
                        ).j_name
                      ">
                        <NameComponent :username="$root.inspected_cf_fetch(
                          $root.cfs,
                          $root.uuid_inspected
                        ).j_name
                          " />
                      </a>
                      <NewLevelBadgeComponent v-if="
                        $root.inspected_cf_fetch(
                          $root.cfs,
                          $root.uuid_inspected
                        ).j_name
                      " :username="$root.inspected_cf_fetch(
                        $root.cfs,
                        $root.uuid_inspected
                      ).j_name
                        " />

                      <a v-if="
                        !$root.inspected_cf_fetch(
                          $root.cfs,
                          $root.uuid_inspected
                        ).j_name
                      " class="name_c">Nobody</a>
                    </div>
                    <div class="price_section" v-if="
                      $root.inspected_cf_fetch(
                        $root.cfs,
                        $root.uuid_inspected
                      ).j_name
                    ">
                      <div class="prBn">
                        <img class="price_LOGO" src="./assets/gemstone.png" />{{
                          $root.inspected_cf_fetch(
                            $root.cfs,
                            $root.uuid_inspected
                          ).wager
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button @click="$root.joinGame_socket" class="jing_gameRE" :class="[
                $root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                  .c_side != 0
                  ? `btn_create`
                  : `btn_purple`,
              ]" v-if="
                !$root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                  .j_name &&
                $root.logged_in &&
                $root.user.name !=
                $root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                  .c_name
              ">
                Join Game
              </button>
              <div class="newgameoe" v-if="
                $root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                  .j_name &&
                !$root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                  .rolling &&
                !$root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                  .counting
              ">
                {{
                  $root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                    .winner_name
                }}
                won <img class="price_LOGO" src="./assets/gemstone.png" />
                {{
                  $root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                    .payout
                }}
                with roll
                {{
                  $root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                    .roll
                }}%
              </div>
              <div class="jkd_divc_holder">
                <a class="jkr_bandz">
                  {{
                    $root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                      .uuid
                  }}
                  <br /><i>
                    {{
                      $root.inspected_cf_fetch($root.cfs, $root.uuid_inspected)
                        .hash
                    }}</i></a>
              </div>
            </div>
            <div class="modal-footer hifur_">
              <a class="ewqeqweqw" v-tooltip="'HASH'"><i>{{
                $root.inspected_cf_fetch($root.cfs, $root.uuid_inspected).hash
                  }}</i></a>
              <a class="ewqeqweqw" v-tooltip="'ROLL'">{{
                $root.inspected_cf_fetch($root.cfs, $root.uuid_inspected).uuid
              }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <Transition data-space-modal="view_profile" name="fade">
      <div v-if="$root.viewUser != ''" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.viewUser = false">
          <div v-if="$root.viewUser != ''" class="modal-c" @mousedown.stop>
            <div class="h_e">
              <i @click="$root.viewUser = false" class="material-icons pointer">arrow_back</i>
              <div class="holderxz">
                <h1 class="new_iitt">{{ viewUser }}</h1>
              </div>
              <i @click="$root.viewUser = false" style="color: #111b11" class="material-icons">arrow_back</i>
            </div>
            <UserProfileComponent :username="viewUser" />
          </div>
        </div>
      </div>
    </Transition>
    <ModalsComponent />
    <LoadingScreen v-if="isLoading" />
    <ServerDownComponent v-if="server_down" />
  </div>
</template>
<script>
import axios from "axios";
import LevelBadgeComponent from "./components/LevelBadgeComponent.vue";
import NewLevelBadgeComponent from "./components/NewLevelBadgeComponent.vue";
import RadialProgressBar from "vue-radial-progress";
import VueNumber from "vue-number-animation";
import modalsComponent from "./components/modalsComponent.vue";
import LoadingScreen from "./components/LoadingScreen";
import ServerDownComponent from "./components/ServerDownComponent";
import ModalsComponent from "./components/modalsComponent.vue";
import NameComponent from "./components/NameComponent.vue";
import ChatComp from "./components/ChatComp.vue";
import UserProfileComponent from "./components/UserProfileComponent.vue";

import Config from "./config";

export default {
  data() {
    return {
      chatEnabled: false, // Changed from cookie check to false by default
      server_down: false,
      deposit_lock: false,
      enable_games: true,
      isRouteLoading: false,

      admin_msg_socket: "",
      // Check support nof
      cfs: [],
      user: {
        name: null,
        balance: null,
        usdc_address: null,
        eth_address: null,
        btc_address: null,
        ltc_address: null,
        total_wager: null,
        xrp_alt_address: null,
        support_notification: 2,
        kyc_lvl: null,
        warning_loc_kyc: null,
        info_msg_kyc: null,
        self_locked: { locked: false, reason: "", expires: "" },
      },
      viewUser: "",
      API_PREFIX: Config.Api_call,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      isLoading: true,

      // for tipping
      tipName: null,

      // for createAcompintent
      step: 1,

      colorblind: this.$cookies.get("colorblind"),
      logged_in: this.$cookies.isKey("token"),

      modal_open: false,
      create_game: { side: 0, wager: null },

      messages: [],
      chatMessages: [],

      animationQueue: [], // Queue to handle rapid balance updates
      new_address: { type: "btc", address: "", name: "", color: "Green" },

      saved_addresses: [],

      selectedGame: "",
      dropdownVisible: false,

      credentials: {
        f_name: null,
        l_name: null,
        email: null,
        username: null,
        password: null,
        dob: null,
        accept_terms: false,
        over_18: false,
        month_birth: "",
      },
      password: "",
      password_check: [],
      mvp: { name: "Unknown" },
      msg_of_the_day:
        "Welcome To CryptoFlip.GG: #1 Trusted original CryptoFlip game.",

      withdrawal_transactions: [],

      cf_win_animation: false,



      diceHistory: [],
      fastHistory: [],
      upgraderHistory: [],
      slotHistory: [],
      plinkoHistory: [],
      blackjackHistory: [],
      squaresHistory: [],

      checkedNames: [],

      stats: { total_game: 0, total_wager: 0, your_placed: 0, big: 0 },
      show_password: false,
      index_inspected: "",
      rolling: 0,
      rewatch_data: {
        creation_time: "",
        payout: "",
        winner_side: "",
        winner_username: "",
      },
      inspected_id: "",
      inspected_cf: [],
      completedSteps: 0,
      totalSteps: 7,
      counting: 0,
      show_animation: 0,
      reorder_selected: 0,
      price_before: 0,
      price_after: 0,
      code2fa_modal: "",
      uuid_inspected: "",
      delete_on_exit: "",

      showAnimation: false,
      animationValue: 0,
      animationType: "",

      first_login: false,

      logo: require("./assets/gemstone.png"),

      bio: "",
      userdata: "",
      email: "",
      nickname: "",
      problem_msg: "",

      w_address: "",
      w_selected: "",
      w_typed: "",

      recent_transactions: [],
    };
  },
  sockets: {
    connect: function () {
      //socket connected
      console.log(
        "%c" + "⦿ Connected to server ⦿",
        "color: #42da3b; -webkit-text-stroke: 1px #053b24; font-size: 22px; font-weight: bold;"
      );
      // console.log("%c" + 'SessionID: ' +  this.$socket.id, "font-size: 12px; font-weight: bold;")
      const referringWebsite = document.referrer || "Direct visit";
      // ADD Start time end time
      this.$socket.emit("navigation", referringWebsite);

      setTimeout(() => {
        if (this.user.name) {
          this.$socket.emit("user_connect", {
            token: this.$cookies.get("token"),
          });
        }
      }, 5000);
    },

    add_message: function (msg) {
      // this.$toast.show(msg, { position: "bottom", duration: 1500 });

      this.messages.push(msg);

      if (this.messages.length > 50) {
        // Remove the oldest message (the first one)
        this.messages.shift();
      }
    },
    message_notify: function (msg) {
      this.$toast.show(msg, { position: "bottom", duration: 1500 });
    },
    header_message_notify: function (msg) {
      this.admin_msg_socket = msg;
    },

    disable_games() {
      this.enable_games = 0;
    },
    clear_chats() {
      this.messages = [];
    },
    socket_enable_games() {
      this.enable_games = 1;
    },
    server_down_true() {
      this.server_down = 1;
    },
    server_down_fasle() {
      this.server_down = 0;
    },
    addresses: function (msg) {
      // this.$toast.show(msg, { position: "bottom", duration: 1500 });

      this.user.btc_address = JSON.parse(msg).btc;
      this.user.eth_address = JSON.parse(msg).eth;
      this.user.ltc_address = JSON.parse(msg).ltc;
      this.user.xrp_address = JSON.parse(msg).xrp;
      this.user.xrp_alt = JSON.parse(msg).xrp_alt_address;
      this.user.doge_address = JSON.parse(msg).doge;
      this.user.usdc_address = JSON.parse(msg).usdc;
    },
    remove_coinflip: function (data) {
      if (this.uuid_inspected == data && this.modal_open == "inspect_game") {
        this.delete_on_exit = data;
      } else {
        let i = this.cfs.map((item) => item.uuid).indexOf(data); // find index of your object
        this.cfs.splice(i, 1);
      }
    },
    cancel_coinflip: function (data) {
      if (this.uuid_inspected == data) {
        this.modal_open = false;
        this.uuid_inspected = "";
        let i = this.cfs.map((item) => item.uuid).indexOf(data); // find index of your object
        this.cfs.splice(i, 1);
      } else {
        let i = this.cfs.map((item) => item.uuid).indexOf(data); // find index of your object
        this.cfs.splice(i, 1);
      }
    },
    deposit: function (data) {
      var utcSeconds = data[3];
      var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
      d.setUTCSeconds(utcSeconds);

      const string =
        ` <i  style="font-size: 50px;display: flex;justify-content: center;align-items: center;color: #ff6600;"  class="material-icons">assignment_turned_in</i>
            Hi ` +
        data[0] +
        ` <br><br> We have recived a deposit for your account on ` +
        d +
        `  <br><br>Your account has been credited <div class="gemstone_img_popdeposit">
                <div style="margin-left:20px;">` +
        parseFloat(data[4]).toFixed(2) +
        `</div> </div><br> The payment method was ` +
        data[2] +
        ` ` +
        data[1] +
        ` to address ` +
        data[5] +
        `   <br><br>TXN ID:` +
        data[6] +
        `<br><br>`;

      this.$toast.show(string, { position: "top", duration: 1000 * 60 * 2 });
      this.updateBalance(+data[4]);

    },
    tipReceived: function (data) {
      var name = data[1];
      var amount = data[0];

      const string = ` <i  style="font-size: 50px;display: flex;justify-content: center;align-items: center;color: #ff6600;"  class="material-icons">attach_money</i>
            You received a tip! <br>Congratulations! ${name} sent you ${amount} gems. Thank you for being an awesome member of our community!<div class="gemstone_img_popdeposit">
                <div style="margin-left:20px;"></div> </div>  <br>The gems have been automatically added to your balance.`;

      this.updateBalance(+amount);

      this.$toast.show(string, { position: "top", duration: 41000 });
    },

    reload_games: function () {
      this.cfs.splice(0, this.cfs.length);

      axios.get(`${this.API_PREFIX}/api/fetch_coinflips`).then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          if (!response.data[i].j_name) {
            response.data[i]["display_amount"] =
              response.data[i].wager.toFixed(2);
            response.data[i]["wager"] = response.data[i].wager.toFixed(2);
            this.cfs.push(response.data[i]);
          } else {
            response.data[i]["display_amount"] =
              response.data[i].winner_payout.toFixed(2);
            response.data[i]["wager"] = response.data[i].wager.toFixed(2);
            response.data[i]["payout"] =
              response.data[i].winner_payout.toFixed(2);
            this.cfs.push(response.data[i]);
          }
        }
      });

      if (this.logged_in == true) {
        this.fetch_user();
      }
    },

    status_sent: function (data) {
      this.$toast.show(
        `Your withdrawal with ID:` + data + ` Has now been marked as sent.`,
        { position: "bottom", duration: 10500 }
      );

      for (let i = 0; i < this.withdrawal_transactions.length; i++) {
        if (data == this.withdrawal_transactions[i].cp_id) {
          this.withdrawal_transactions[i].status = "Completed";
        }
      }
    },

    update_coinflip: function (data) {
      const uuid = data[0];

      if (!this.cfs.find((cf) => cf.uuid === uuid)) {
        return;
      }

      //FIRST JOIN STATS
      this.cfs.find((cf) => cf.uuid === uuid).winner_name = data[6];
      this.cfs.find((cf) => cf.uuid === uuid).payout = data[5].toFixed(2);
      this.cfs.find((cf) => cf.uuid === uuid).display_amount =
        data[5].toFixed(2);
      this.cfs.find((cf) => cf.uuid === uuid).rolling = 0;
      this.cfs.find((cf) => cf.uuid === uuid).counting = 1;
      this.cfs.find((cf) => cf.uuid === uuid).completedSteps = 7;
      this.cfs.find((cf) => cf.uuid === uuid).j_name = data[1];
      this.cfs.find((cf) => cf.uuid === uuid).winner_name = data[6];
      this.cfs.find((cf) => cf.uuid === uuid).roll = data[3];
      this.cfs.find((cf) => cf.uuid === uuid).secret = data[4];

      //COUNT DOWN
      setTimeout(
        function () {
          this.cfs.find((cf) => cf.uuid === uuid).completedSteps--;
        }.bind(this),
        1000
      );
      setTimeout(
        function () {
          this.cfs.find((cf) => cf.uuid === uuid).completedSteps--;
        }.bind(this),
        2000
      );
      setTimeout(
        function () {
          this.cfs.find((cf) => cf.uuid === uuid).completedSteps--;
        }.bind(this),
        3000
      );
      setTimeout(
        function () {
          this.cfs.find((cf) => cf.uuid === uuid).completedSteps--;
        }.bind(this),
        4000
      );
      setTimeout(
        function () {
          this.cfs.find((cf) => cf.uuid === uuid).completedSteps--;
        }.bind(this),
        5000
      );
      setTimeout(
        function () {
          this.cfs.find((cf) => cf.uuid === uuid).completedSteps--;
        }.bind(this),
        6000
      );
      setTimeout(
        function () {
          this.cfs.find((cf) => cf.uuid === uuid).completedSteps--;
        }.bind(this),
        7000
      );

      //ROLL
      setTimeout(
        function () {
          this.cfs.find((cf) => cf.uuid === uuid).counting = 0;
          this.cfs.find((cf) => cf.uuid === uuid).rolling = 1;
          this.cfs.find((cf) => cf.uuid === uuid).winner_side = data[2];
          this.cfs.find((cf) => cf.uuid === uuid).j_name = data[1];
          this.cfs.find((cf) => cf.uuid === uuid).roll = data[3];
          this.cfs.find((cf) => cf.uuid === uuid).secret = data[4];
          this.cfs.find((cf) => cf.uuid === uuid).payout = data[5];
        }.bind(this),
        8000
      );

      setTimeout(
        function () {
          this.cfs.find((cf) => cf.uuid === uuid).rolling = 0;

          if (data[6] == this.$root.user.name) {
            this.cf_win_animation = true;

            this.updateBalance(+data[5]);
          }
        }.bind(this),
        13500
      );
    },

    paymentGame: function (data) {


      if (this.$route.path !== '/roulette' && data.game == "roulette") {
    this.updateBalance(+data.value);
  } else if (this.$route.path !== '/jackpot' && data.game == "jackpot") {
    this.updateBalance(+data.value);
  }



},
    add_coinflip: function (data) {
      // Check if the coinflip with the same UUID already exists
      const existingCoinflip = this.cfs.find((cf) => cf.uuid === data[0]);

      // If it exists, do not add it again
      if (existingCoinflip) {
        // Optionally, you can handle this case (e.g., show an error message)
        console.error("Coinflip with the same UUID already exists");
        return;
      }

      // Add the new coinflip to the table
      this.cfs.push({
        uuid: data[0],
        game_no: data[1],
        c_name: data[2],
        wager: data[3],
        display_amount: data[3],
        c_side: data[4],
        hash: data[5],
      });

      // Check conditions related to user's name and auto-watching
      if (data[2] == this.user.name && !this.$cookies.isKey("auto_watch")) {
        this.watch_cf(data[0]);
      } else if (
        data[2] == this.user.name &&
        this.$cookies.get("auto_watch") == "true"
      ) {
        this.watch_cf(data[0]);
      }

      // Refresh the order of coinflips
      this.refresh_cf_order();
    },
  },
  watch: {
    $route() {
      this.modal_open = false;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    modal_open() {
      if (this.user.self_locked.locked) {
        this.modal_open = "";
      }

      if (this.delete_on_exit != "") {
        this.uuid_inspected = "";
        this.modal_open = "";
        let i = this.cfs.map((item) => item.uuid).indexOf(this.delete_on_exit); // find index of your object
        this.cfs.splice(i, 1);
        this.delete_on_exit = "";
      }
    },

    password: function (val) {
      this.password_check = [];
      if (val.length < 8) {
        this.password_check.push(["Password must be 8 or more characters"]);
      }
      if (this.isUpper(val)) {
        this.password_check.push([
          "Password should contain atleast uppercase letter",
        ]);
      }
      if (val.search(/[0-9]/) < 0) {
        this.password_check.push([
          "Password should contain atleast one number",
        ]);
      }
    },
    cancel_coinflip: function (data) {
      if (this.inspected_id == data) {
        this.$toast.show(`This coinflip has been canncelled by the creator`, {
          position: "bottom",
          duration: 1500,
        });
        this.$router.push({ path: "/coinflip" });
      }
    },
    cfs: {
      handler: function () {
        this.stats.total_game = this.cfs.length;
        this.stats.total_wager = 0;
        this.stats.your_placed = 0;
        this.stats.big = 0;

        let biggestGame = null;
        let biggestAmount = 0;

        //         for (let i = 0; i < this.cfs.length; i++) {

        for (let i = 0; i < this.cfs.length; i++) {
          // Check if the current game is bigger than the previous biggest game
          if (parseFloat(this.cfs[i].display_amount) > biggestAmount) {
            biggestAmount = parseFloat(this.cfs[i].display_amount);
            biggestGame = this.cfs[i];
          }
        }
        if (biggestGame !== null) {
          // Access the biggest game details//
          this.stats.big = biggestGame.display_amount;
          // console.log("Biggest Game:", biggestGame.display_amount);
        }

        // // Check if the current game is bigger than the previous biggest game

        //         if (this.cfs[i].display_amount > this.stats.big) {
        //          this.stats.big = this.cfs[i].display_amount;
        //          console.log(Date.now() + " - NEW BIG: " + this.stats.big);

        //         }
        //     }

        this.refresh_cf_order();

        for (let i = 0; i < this.cfs.length; i++) {
          this.stats.total_wager = (
            parseFloat(this.stats.total_wager) +
            parseFloat(this.cfs[i].display_amount)
          ).toFixed(2);

          // Check if the current game is bigger than the previous biggest game
        }

        if (this.logged_in == true) {
          for (let i = 0; i < this.cfs.length; i++) {
            if (this.user.name == this.cfs[i].c_name) {
              this.stats.your_placed++;
            }
          }
        }
      },
      deep: true,
    },
  },
  components: {
    LevelBadgeComponent,
    RadialProgressBar,
    NameComponent,
    UserProfileComponent,
    NewLevelBadgeComponent,
    VueNumber,
    LoadingScreen,
    modalsComponent,
    ModalsComponent,
    ServerDownComponent,
    ChatComp,
  },
  methods: {
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
    toggleChatEnabled() { this.chatEnabled = !this.chatEnabled; this.$cookies.set('ChatEnabled', this.chatEnabled, '10y'); },
    updateBalance(amount) {
      // Add the update to the queue
      this.animationQueue.push(amount);
      if (!this.showAnimation) {
        // Process the queue if no animation is currently showing
        this.processQueue();
      }
    },
    processQueue() {
      if (this.animationQueue.length === 0) {
        this.showAnimation = false;
        return;
      }

      // Get the next amount from the queue
      const amount = this.animationQueue.shift();
      const Decemailamount = parseFloat(amount).toFixed(2);

      // Update the balance
      this.user.balance += amount;

      if (Number(amount) === 0) {
        this.showAnimation = false;
        this.processQueue(); // Process the next item in the queue
        return;
      }

      // Set animation values
      this.animationValue = amount > 0 ? `+${Decemailamount}` : Decemailamount;
      this.animationType = amount > 0 ? "add" : "subtract"; this.showAnimation = true;

      // Wait for the animation to end before processing the next one
      this.$nextTick(() => {
        this.$el
          .querySelector(".balance-animation")
          .addEventListener("animationend", () => this.processQueue(), { once: true });
      });

    },
    resetAnimation() {
      this.showAnimation = false;
    },
    onResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    },
    //Delete withdrawal address
    delete_withdrawal_address: function (address) {
      this.$socket.emit("delete_saved_withdrawal", {
        token: this.$cookies.get("token"),
        address: address,
      });

      for (var i = 0; i < this.saved_addresses.length; i++) {
        if (address == this.saved_addresses[i].address) {
          this.$toast.show(
            `Removing your saved withdrawal address - ${address}`,
            { position: "bottom", duration: 1500 }
          );
          this.$root.saved_addresses.splice(i, 1);
          return;
        }
      }
    },

    add_withdrawal_address() {
      if (!this.new_address.type) {
        this.$toast.show(`You have not selected a cryptocurrency.`, {
          position: "bottom",
          duration: 1500,
        });
      } else if (this.saved_addresses.length >= 6) {
        this.$toast.show(`To much address, you can only have 6 max`, {
          position: "bottom",
          duration: 1500,
        });
      } else if (!this.new_address.address) {
        this.$toast.show(`No address selected`, {
          position: "bottom",
          duration: 1500,
        });
      } else if (!this.new_address.name) {
        this.$toast.show(`No address name`, {
          position: "bottom",
          duration: 1500,
        });
      } else if (this.saved_addresses.length > 9) {
        this.$toast.show(`You may not have more than 10 cards saved`, {
          position: "bottom",
          duration: 1500,
        });
      } else {
        axios
          .post(`${this.API_PREFIX}/api/add_saved_withdrawal`, {
            token: this.$cookies.get("token"),
            type: this.$root.new_address.type,
            address: this.$root.new_address.address,
            name: this.$root.new_address.name,
            color: this.$root.new_address.color,
          })
          .then((response) => {
            if (response.data.error) {
              this.$toast.show(response.data.error, {
                position: "bottom",
                duration: 1500,
              });
            } else {
              this.$root.saved_addresses = this.$root.saved_addresses.concat(
                this.new_address
              );
              this.$toast.show(`Adding address to wallet!`, {
                position: "bottom",
                duration: 1500,
              });

              this.new_address = {
                type: this.new_address.type,
                address: "",
                color: this.$root.new_address.color,
              };

              this.$root.new_address.color = "Green";
            }
          });
      }
    },



    
    delete_address(address) {
      this.saved_addresses.find((x) => x.address === address).pop();
    },

    delete_func() {
      this.selected = "";
      this.address = "";
    },
    deleted_address(address) {
      this.address = "";
      this.address = "";
      console.log(address);
    },
    moveUp() {
      window.scrollTo(0, -1);
      document.getElementById("B-bar").scrollIntoView();
    },
    try_2fa_code() {
      if (this.code2fa_modal == "") {
        this.$toast.show(`Please enter an code`, {
          position: "bottom",
          duration: 1500,
        });
      } else {
        axios
          .post(`${this.API_PREFIX}/api/login_2fa_code`, {
            code2fa: this.code2fa_modal,
          })
          .then((response) => {
            if (response.data.token == "incorrect") {
              this.$toast.show(`Wrong Code`, {
                position: "bottom",
                duration: 1500,
              });
            } else {
              this.modal_open = false;
              console.log(response.data.token);
              this.$cookies.set("token", response.data.token, "10y");
              this.$root.logged_in = true;
              this.$router.push({ path: "/coinflip" });
              this.$root.fetch_user();
            }
          });
      }
    },
    inspected_cf_fetch(arr, value) {
      var result = [];

      arr.forEach(function (o) {
        if (o.uuid == value) result.push(o);
      });

      return result ? result[0] : null; // or undefined
    },
    login_2fa() {
      if (this.credentials.email == null) {
        this.$toast.show(`Please enter an email`, {
          position: "bottom",
          duration: 1500,
        });
      } else if (this.credentials.password == null) {
        this.$toast.show(`Please enter an password`, {
          position: "bottom",
          duration: 1500,
        });
      } else {
        axios
          .post(`${this.API_PREFIX}/api/login_2fa`, {
            email: this.credentials.email,
            password: this.credentials.password,
          })
          .then((response) => {
            if (response.data.token == "incorrect") {
              this.$toast.show(`Wrong Code`, {
                position: "bottom",
                duration: 1500,
              });
            } else {
              this.modal_open = false;
              console.log(response.data.token);
              this.$cookies.set("token", response.data.token, "10y");
              this.$root.logged_in = true;
              this.$router.push({ path: "/coinflip" });
              this.$root.fetch_user();
            }
          });
      }
    },
    isUpper(str) {
      return !/[A-Z]/.test(str);
    },
    refresh_cf_order: function () {
      if (this.reorder_selected == 1) {
        this.reorder_down();
      } else if (this.reorder_selected == 0) {
        this.reorder_up();
      }
    },
    theFormat(number) {
      return number.toFixed(2);
    },
    reorder_down() {
      this.reorder_selected = 1;
      this.$root.cfs.sort(function (a, b) {
        return parseFloat(a.display_amount) - parseFloat(b.display_amount);
      });
    },
    reorder_up() {
      this.reorder_selected = 0;
      this.$root.cfs.sort(function (a, b) {
        return parseFloat(b.display_amount) - parseFloat(a.display_amount);
      });
    },
    watch_cf(id) {
      this.modal_open = "inspect_game";

      for (var i = 0; i < this.cfs.length; i++) {
        if (id == this.cfs[i].uuid) {
          this.index_inspected = i;
          this.uuid_inspected = this.cfs[i].uuid;
          if (this.cfs[i].rolling == 1) {
            this.cfs[i].rolling = 0;
          }
          return;
        }
      }
    },
    login() {
      if (this.credentials.email == null) {
        this.$toast.show(`Please enter an email`, {
          position: "bottom",
          duration: 3500,
        });
      } else if (this.$cookies.get(`wrong_login`) > 17) {
        this.$toast.show(
          `To much recent logins, Try later or reset your password`,
          { position: "bottom", duration: 3500 }
        );
      } else if (this.credentials.password == null) {
        this.$toast.show(`Please enter an password`, {
          position: "bottom",
          duration: 3500,
        });
      } else {
        axios
          .post(`${this.API_PREFIX}/api/login`, {
            email: this.credentials.email,
            password: this.credentials.password,
          })
          .then((response) => {
            if (response.data.token == "incorrect") {
              this.$toast.show(`Incorrect Password or Email`, {
                position: "bottom",
                duration: 3500,
              });

              if (this.$cookies.get("wrong_login") == null) {
                this.$cookies.set("wrong_login", 1, 60);
              } else {
                this.$cookies.set(
                  "wrong_login",
                  parseInt(this.$cookies.get("wrong_login")) + 1
                );
              }
            } else {
              this.modal_open = false;
              this.$cookies.set("token", response.data.token, "10y");
              this.$root.fetch_user();

              setTimeout(() => {
                if (this.user.name) {
                  this.$socket.emit("user_connect", {
                    token: this.$cookies.get("token"),
                  });
                }
              }, 500);

              this.$root.logged_in = true;
              this.$router.push({ path: "/" });
            }
          });
        this.credentials.password = "";
      }
    },

    loginWithGoogle() {
      axios.get(`${this.API_PREFIX}/api/auth/google`)
        .then(response => {
          // Redirect to Google OAuth page
          window.location.href = response.data.authUrl;
        })
        .catch(error => {
          console.error('Error initiating Google login:', error);
          this.$toast.show('Failed to initiate Google login. Please try again.', {
            position: "bottom",
            duration: 3500,
          });
        });
    },

    loginWithSteam() {
      axios.get(`${this.API_PREFIX}/api/auth/steam`)
        .then(response => {
          // Redirect to Steam OAuth page
          window.location.href = response.data.authUrl;
        })
        .catch(error => {
          console.error('Error initiating Steam login:', error);
          this.$toast.show('Failed to initiate Steam login. Please try again.', {
            position: "bottom",
            duration: 3500,
          });
        });
    },

    validateEmail(email) {
      /* eslint-disable */
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return false;
      } else {
        return true;
      }
    },
    fetch_user() {
      axios
        .post(`${this.API_PREFIX}/api/user`, {
          token: this.$cookies.get("token"),
        })
        .then((response) => {
          if (response.data.error == "no_user") {
            this.logout();
          } else if (response.data[0] == undefined) {
            this.logout();
          } else {
            this.user.name = response.data[0].username;
            this.user.balance = response.data[0].bal;
            if (response.data[0].admin == 1) {
              this.user.admin = true;
            }

            if (response.data[0].crypto_addresses) {
              this.user.btc_address = JSON.parse(
                response.data[0].crypto_addresses
              ).btc;
              this.user.eth_address = JSON.parse(
                response.data[0].crypto_addresses
              ).eth;
              this.user.ltc_address = JSON.parse(
                response.data[0].crypto_addresses
              ).ltc;
              this.user.xrp_address = JSON.parse(
                response.data[0].crypto_addresses
              ).xrp;
              this.user.xrp_alt = JSON.parse(
                response.data[0].crypto_addresses
              ).xrp_alt_address;
              this.user.doge_address = JSON.parse(
                response.data[0].crypto_addresses
              ).doge;
              this.user.usdc_address = JSON.parse(
                response.data[0].crypto_addresses
              ).usdc;
            }

            // console.log(response.data[0].self_lock)

            if (response.data[0].self_lock) {
              this.user.self_locked.reason = JSON.parse(
                response.data[0].self_lock
              ).reason;
              this.user.self_locked.locked = true;
              this.user.self_locked.expires = JSON.parse(
                response.data[0].self_lock
              ).expiration;
            }
            const currentTime = new Date();
            const unlockDateTime = new Date(this.user.self_locked.expires);



            if (currentTime > unlockDateTime) {
              this.user.self_locked.locked = false;
            }

            //                     if (response.data[0].self_lock) {
            //                         this.user.self_locked.true = true
            // this.user.self_locked.reason = JSON.parse(response.data[0].self_locked).reason
            // this.user.self_locked.expires = JSON.parse(response.data[0].self_locked).expires

            // }

            // this.user.xrp_alt = JSON.parse(response.data[0].crypto_addresses)[4].xrp_alt_address

            // console.log(response.data[0].crypto_addresses)

            if (this.user.confirmed_email == undefined) {
              this.user.confirmed_email = response.data[0].confirmed_email;
            }

            if (response.data[0].kyc) {
              this.user.kyc_lvl = JSON.parse(response.data[0].kyc).level;

              this.user.warning_loc_kyc = JSON.parse(
                response.data[0].kyc
              ).warning_loc;

              this.user.info_msg_kyc = JSON.parse(
                response.data[0].kyc
              ).info_msg;
            }

            this.nickname = response.data[0].nickname;
            this.bio = response.data[0].bio;
            this.email = response.data[0].email;

            this.user.seed = response.data[0].random_salt_token;
            this.user.salt = response.data[0].randomness_token;

            this.rakeback = response.data[0].rakeback.toFixed(2);
            this.raked = response.data[0].raked.toFixed(2);

            if (response.data[0].lifetime_wagered == 0.0) {
              this.first_login = true;
            } else {
              this.first_login = false;
            }

            //add cards

            if (response.data[0].withdraw_address != null) {
              this.saved_addresses = JSON.parse(
                response.data[0].withdraw_address
              );
            } else {
              this.saved_addresses = [];
            }

            axios
              .post(`${this.API_PREFIX}/api/user_transactions`, {
                token: this.$cookies.get("token"),
              })
              .then((response) => {
                this.$root.recent_transactions = response.data;
              });

            this.user.total_wager = response.data[0].lifetime_wagered;
          }
        });
    },
    viewUserProfile(name) {
      this.viewUser = name;
    },

    fetch_server_stats() {
      axios.get(`${this.API_PREFIX}/api/get_server_status`).then((response) => {
        if (response.data[0] == null) {
          //       console.log("NO MVP SET")
        } else {
          this.server_down = response.data[0].server_down;
          this.deposit_lock = !response.data[0].enable_deposit;
          this.enable_games = response.data[0].enable_games;

          // console.log(response.data)
        }
      });
    },

    fetch_chats() {
      axios.get(`${this.API_PREFIX}/api/get_last_chats`).then((response) => {
        if (response.data[0] == null) {
          //       console.log("NO MVP SET")
        } else {
          this.messages = response.data;

          // console.log(response.data)

          // console.log(response.data)
        }
      });
    },

    fetch_mvp() {
      axios.get(`${this.API_PREFIX}/api/fetch_mvp`).then((response) => {
        if (response.data[0] == null) {
          //       console.log("NO MVP SET")
        } else {
          this.mvp.name = response.data[0].mvp_name;
        }
      });
    },
    rapid_join(uuid) {
      if (this.$root.logged_in == false) {
        this.$toast.show(`Login necessary to perform this action.`, {
          position: "bottom",
          duration: 1500,
        });
      } else if (
        this.user.balance <=
        this.cfs.find((cf) => cf.uuid === uuid).display_amount
      ) {
        this.$toast.show(`Your balance is too low for this game.`, {
          position: "bottom",
          duration: 1500,
        });
      } else {
        this.$root.user.balance =
          this.$root.user.balance -
          this.cfs.find((cf) => cf.uuid === uuid).display_amount;

        // console.log("ran" + uuid)

        this.user.total_wager =
          parseFloat(this.user.total_wager) +
          parseFloat(this.cfs.find((cf) => cf.uuid === uuid).display_amount);

        this.$socket.emit("join_cf", {
          token: this.$cookies.get("token"),
          uuid: uuid,
        });
      }
    },

 


    joinGame_socket() {
      if (this.$root.logged_in == false) {
        this.$toast.show(`Login necessary to perform this action.`, {
          position: "bottom",
          duration: 1500,
        });
      } else if (
        this.user.balance <
        this.cfs.find((cf) => cf.uuid === this.uuid_inspected).display_amount
      ) {
        this.$toast.show(`Your balance is too low for this game.`, {
          position: "bottom",
          duration: 1500,
        });
      } else if (
        this.user.balance <
        this.cfs[this.index_inspected].display_amount + 0.01
      ) {
        this.$toast.show(`You dont have enough money for this`, {
          position: "bottom",
          duration: 1500,
        });
      } else {
        this.$root.user.balance =
          this.$root.user.balance -
          this.cfs.find((cf) => cf.uuid === this.uuid_inspected).display_amount;

        this.user.total_wager =
          parseFloat(this.user.total_wager) +
          parseFloat(
            this.cfs.find((cf) => cf.uuid === this.uuid_inspected)
              .display_amount
          );

        this.$socket.emit("join_cf", {
          token: this.$cookies.get("token"),
          uuid: this.uuid_inspected,
        });
      }
    },

    createGame_socket() {
      if (!this.$cookies.isKey("token")) {
        this.$toast.show(
          `Please sign up or create an account to get started.`,
          { position: "bottom", duration: 2500 }
        );
      } else if (parseFloat(this.create_game.wager) <= 0) {
        this.$toast.show(`Please enter a valid wager.`, {
          position: "bottom",
          duration: 1500,
        });
        
      } else if (!this.create_game.wager) {
        this.$toast.show(`Please enter something.`, {
          position: "bottom",
          duration: 1500,
        });
        
      } else if (parseFloat(this.create_game.wager) < 0.5) {
        this.$toast.show(`Minimum wager must be 0.50 gems.`, {
          position: "bottom",
          duration: 1500,
        });
      } else if (
        this.user.balance <= this.create_game.wager &&
        !(this.user.balance == this.create_game.wager)
      ) {
        this.$toast.show(`Insufficient funds to create this game.`, {
          position: "bottom",
          duration: 1500,
        });
      } else {
        // console.log(this.create_game.wager + " si wager")
        // console.log( this.user.balance + " si wager")

        if (this.create_game.wager == this.user.balance) {
          this.modal_open = "confirm_cf_max";
        } else {
          // Not going all in, proceed directly

          this.processGameCreation();
        }
      }
    },

    processGameCreation() {
      this.price_before = this.$root.user.balance;
      this.price_after = this.$root.user.balance - this.create_game.wager;

      this.updateBalance(-this.create_game.wager);

      this.user.total_wager =
        parseFloat(this.user.total_wager) + parseFloat(this.create_game.wager);

      this.$socket.emit("create_cf", {
        side: this.create_game.side,
        wager: this.create_game.wager,
        token: this.$cookies.get("token"),
      });
      if (!this.$cookies.isKey("auto_watch")) {
        this.modal_open = false;
      } else if (this.$cookies.get("auto_watch") === "false") {
        this.modal_open = false;
      }
    },

    cancel_game() {
      this.updateBalance(
        parseFloat(this.cfs[this.index_inspected].display_amount)
      );
      this.user.total_wager -= parseFloat(
        this.cfs[this.index_inspected].display_amount
      );
      axios
        .post(`${this.API_PREFIX}/api/cancel_coinflip`, {
          token: this.$cookies.get("token"),
          uuid: this.cfs[this.index_inspected].uuid,
        })
        .then((response) => {
          if (response.data == "delete") {
            console.log("this path");
          }
        });
    },
    toggle_password_show() {
      this.show_password = !this.show_password;
    },
    reloadPageAndRemoveToken() {
      window.location.reload(); // Reload the page
      this.$cookies.remove("token"); // Remove the token
    },
    logout() {
      this.$router.push({ path: "/" });
      this.$socket.emit("logout", {
        token: this.$cookies.get("token"),
      });

      this.$cookies.remove("token");




      this.logged_in = false;
      this.user = {
        name: null,
        balance: null,
        usdc_address: null,
        eth_address: null,
        btc_address: null,
        ltc_address: null,
        total_wager: null,
        xrp_alt_address: null,
        support_notification: 2,
        kyc_lvl: null,
        warning_loc_kyc: null,
        info_msg_kyc: null,
        self_locked: { locked: false, reason: "", expires: "" },
      };
    },
    handleSocialLogin(provider) {
      // Only handle Google for now
 

      // Generate a random state parameter to prevent CSRF attacks
      const state = Math.random().toString(36).substring(7);
      this.$cookies.set('oauth_state', state, '10m');

      // Get the auth URL from the server and redirect
      axios.get(`${this.API_PREFIX}/api/auth/${provider}`, {
        params: { state }
      })
        .then(response => {
          if (!response.data.authUrl) {
            throw new Error('No auth URL received');
          }
          // Store current path to redirect back after login
          this.$cookies.set('login_redirect', this.$route.path, '10m');
          
          // Redirect to the auth URL directly
          window.location.href = response.data.authUrl;
        })
        .catch(error => {
          console.error('Error initiating social login:', error);
          this.$toast.show(`Failed to initiate ${provider} login. Please try again.`, {
            position: "bottom",
            duration: 3500,
          });
        });
    },
  },



  
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    if (this.logged_in) {
      this.fetch_user();
    }

    if (this.windowWidth < 1080) {
      this.chatEnabled = false;
    }

    // Remove the old chat cookie check and set it to false by default
    if(!this.$cookies.get("ChatEnabled")) {
      this.$cookies.set("ChatEnabled", false, '7y');
    }
    this.chatEnabled = this.$cookies.get("ChatEnabled") === 'true';

    this.light_theme = !this.$cookies.get("dark_mode");
    this.fetch_mvp();

    this.fetch_server_stats();
    this.fetch_chats();

    console.log("%c" + "⚠️Stop!⚠️", "color: red; font-size: 42px;");
    console.log(
      "%c" +
      `This is a browser feature intended for developers. If someone told you to copy and paste something here to enable a CryptoFlip.GG feature or 'hack' someone's account, it is a scam and will give them access to your CryptoFlip.GG account.` +
      "%c\n\nLegal Disclaimer: Any attempt to tamper with the website's code is prohibited and may result in account suspension.",
      "color: black; font-size: 26px; background-color: white;",
      "color: black; font-size: 14px; background-color: white;"
    );


    setTimeout(
      function () {
        this.isLoading = false;
      }.bind(this),
      3000
    );
  },
  created() {
    // Add navigation guards for loading state
    this.$router.beforeEach((to, from, next) => {
      this.isRouteLoading = true;
      next();
    });

    this.$router.afterEach(() => {
      setTimeout(() => {
        this.isRouteLoading = false;
      }, 100); // Small delay to ensure smooth transition
    });
  },
};
</script>

<style scoped>
.flex_smaerl_holder {
  width: calc(100% - 60px);
  color: #a3a3a3;
  display: flex;
  padding: 1px;
  margin-top: 10px;
  margin-left: 30px;
  justify-content: space-around;
  flex-direction: column;
}

.shift {
  filter: saturate(2) hue-rotate(34deg);
}

.absolute {
  position: absolute;
  bottom: 50px;
  width: 100%;
  height: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.coin {
  position: relative;
  height: 80px;
  margin: 2rem 0rem;
  opacity: 1;
  width: 80px;
  transform-style: preserve-3d;
}

.MAIN_LOGOx {
  height: 25px;
}

#headsz {
  width: 100%;
  height: 100%;
  border-radius: 80px;
  backface-visibility: hidden;
  background-size: contain;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  filter: saturate(1.3) brightness(1);
}

#tailsz {
  width: 100%;
  height: 100%;
  border-radius: 80px;
  backface-visibility: hidden;
  background-size: contain;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  filter: saturate(1.3) brightness(1);
}

#headsz {
  background-image: url("../src/assets/green_coin.png");
}

#tailsz {
  background-image: url("../src/assets/purple_coin.png");
  transform: rotateY(-180deg);
}

.alert_msgxx {
  background: #990e0e;
  color: white;
  border: 1px solid;
  padding: 20px;
  margin-top: 20px;
  font-family: "Gilroy", cursive;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert_msgx3 {
  font-size: 20px;
  background: #00d0ffc2;
  color: white;
  border: 1px #000000 dashed;
  padding: 20px;
  margin-top: 20px;
  font-family: "Gilroy", cursive;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.balance-buttons {
  margin-top: 10px;
}

.balance-buttons button {
  margin-right: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.balance-animation {
  border-radius: 5px;
  height: 30px;
  background: #060a068e;

  display: flex;
  align-items: center;
  color: white;
  font-size: 28px;
  font-family: monospace;
  width: 75px;
  animation: popdown 0.2s ease-in-out;
  position: absolute;
  margin-top: 60px;
  justify-content: center;
}

.add {
  color: green;
}

.subtract {
  color: red;
}

@keyframes popdown {
  0% {
    opacity: 1;
    transform: translateY(-20px);
  }

  100% {
    opacity: 0;
    transform: translateY(0);
  }
}

.animate-br {
  animation: animateBR 4.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

.block_bleow {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}

.animate-rr {
  animation: animateRR 4.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

.animate-br {
  animation: animateBR 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

.animate-rb {
  animation: animateRB 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

.animate-bb {
  animation: animateBB 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

.btndddr {
  display: block;
  color: #ffffffd6;
  background: #55555569;
  border: 1px dashed #000000;
  cursor: pointer;
  border-radius: 10px;
  padding: 3px 7px;
}

.mainer {
  background: none !important;
  min-width: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  max-width: 1280px;
  margin: auto;
  margin-top: 20px;
}

.name3 {
  color: white;
  font-size: 26px;
  text-align: center;
}

.middle_txt {
  text-shadow: 0px 0px 10px black;
}

.fadeIn {
  animation: fadedIn 333ms;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

.Namebsoer {
  font-family: "Gilroy";
  color: #ffffff;
  text-shadow: 0px 0px 10px black;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  inline-size: 90px;
  overflow-wrap: break-word;
  display: inline-flex;
}

@keyframes fadedIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animateRB {
  0% {
    transform: rotateY(0deg) scale(1.2);
  }

  5% {
    transform: rotateY(0deg) scale(1.2);
  }

  60% {
    transform: rotateY(940deg) scale(1.4);
  }

  90% {
    transform: rotateY(1440deg) scale(1.25);
  }

  100% {
    transform: rotateY(1440deg) scale(1.2);
  }
}

@keyframes animateRR {
  0% {
    transform: rotateY(0deg) scale(1.2);
  }

  5% {
    transform: rotateY(0deg) scale(1.2);
  }

  60% {
    transform: rotateY(760deg) scale(1.4);
  }

  90% {
    transform: rotateY(1260deg) scale(1.25);
  }

  100% {
    transform: rotateY(1260deg) scale(1.2);
  }
}

.svgShape {
  position: fixed;
  z-index: -99;
  scale: 5;
  opacity: 0.4;
  top: -50%;
}

.glowz {
  color: #ff0000;
  text-align: center;
  -webkit-animation: glowz 1s ease-in-out infinite alternate;
  -moz-animation: glowz 1s ease-in-out infinite alternate;
  animation: glowz 1s ease-in-out infinite alternate;
}

@keyframes glowz {
  to {
    text-shadow: 0 0 5px #fff, 0 0 20px #fff, 0 0 10px #e60073,
      0 0 5px #e6007323, 0 0 20px #e60073, 0 0 60px #e60073, 0 0 30px #e6007334;
  }

  from {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da61a, 0 0 10px #ff4da6,
      0 0 5px #ff4da6, 0 0 30px #ff4da634, 0 0 5px #ff4da6, 0 0 10px #ff4da6;
  }
}

.dropdown {
  margin: 20px;
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  background: #1d1f1d;
  border-bottom: 2px solid #575757;
  display: flex;
  align-items: center;gap: 5px;
  justify-content: flex-start;
}

.dropdown-content a:hover {
  background-color: #2b2b2b;
}

.dropdown.show .dropdown-content {
  display: block;
}

.game-button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.selected {
  background-color: #ddd;
}

.dropbtn {
  background: none;
  color: white;

  cursor: pointer;
}

.filler {
  display: flex;
}

.roller_sizer {
  color: #f8f8f8;
  font-weight: initial;
  font-size: 15px;
  font-family: "Gilroy";
}

.zzzzznz {
  cursor: pointer;
}

.prBn {
  border-radius: 5px;
  background: #060a06;
  align-items: center;
  color: white;
  font-size: 24px;
  font-family: monospace;
  border: 1px solid #212121;
  padding: 1px 16px;
  display: flex;
}

.chatBoxMain {
  transition: 0.15s;
}

.price_section4 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.backgorund_NONE_ {
  background: none !important;
}

.jkd_divc_holder {
  padding: 0px 20px;
  margin-top: 5px;
}

.c-toast--top {
  align-self: center;
  max-width: 880px;
  width: 90%;
  border: 2px dashed !important;
}

.jkr_bandz {
  font-family: "Gilroy", cursive;
  font-size: 8px;
  color: #dedede;
  width: 90%;
  margin: 20px auto;
}

.widthdawlee {
  width: 50%;
  color: #a0a0a0;
  font-size: 14px;
  background: #1a1a1a;
  border: 1px dashed #444444;
  cursor: pointer;
  border-radius: 10px;
  height: 32px;
  margin: 0;
  padding: 0;
  margin-left: 5px;
  transition: all 0.3s ease;
}

.widthdawlee:hover {
  background: #252525;
  color: #b5b5b5;
}

.d_pose {
  width: 50%;
  color: #42da3b;
  font-size: 16px;
  background: linear-gradient(to bottom, #064c2f, #053b24);
  border: none;
  border-bottom: 2px dashed #42da3b;
  cursor: pointer;
  border-radius: 10px 10px 0 0;
  height: 36px;
  margin: 0;
  padding: 0;
  transition: all 0.3s ease;
  animation: wiggle 0.5s ease-in-out;
  animation-iteration-count: 1;
  animation-delay: 120s;
  animation-direction: normal;
  animation-fill-mode: both;
  animation-play-state: running;
  animation-timeline: auto;
  animation-range-start: normal;
  animation-range-end: normal;
  font-weight: bold;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 8px rgba(66, 218, 59, 0.1);
}

.d_pose:hover {
  transform: translateY(-2px);
  background: linear-gradient(to bottom, #075a38, #064c2f);
  animation: none;
  border-bottom-color: #53eb4c;
  letter-spacing: 1px;
}

@keyframes wiggle {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(-5deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(5deg); }
  100% { transform: rotate(0deg); }
}

.d_pose:hover::after {
  animation: none;
  transform: scaleX(1);
}

.d_pose:hover::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 100%;
  height: 2px;
  background: #42da3b;
  transform: scaleX(0);
  transform-origin: right;
  animation: slideDash 120s infinite;
}

@keyframes slideDash {
  0%, 98% {
    transform: scaleX(0);
    transform-origin: right;
  }
  98.5% {
    transform: scaleX(1);
    transform-origin: right;
  }
  99%, 99.5% {
    transform: scaleX(1);
    transform-origin: left;
  }
  100% {
    transform: scaleX(0);
    transform-origin: left;
  }
}

.d_pose:hover::after {
  animation: none;
  transform: scaleX(1);
}

.d_pose:hover {
  transform: translateY(-2px);
  background: #064c2f;
  animation: none;
}

.withdrawl_cc {
  width: 100%;
  margin: 0;
  background: #828282;
}

.crea_acc {
  color: #42da3b;
  font-size: 16px;
  background: #053b24;
  border: 1px dashed #000000;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  margin: 0;
  margin-bottom: 20px;
}
.chat_wrapper {
  background-color: rgb(29 31 29);
          height: 37px;
          padding: 12px 19px;
          font-size: 20px;
          color: white;
          display: flex;
          align-items: center;
          justify-content: space-between;
}

.d_w_padding_0 {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.name_holder {
  display: flex;
  margin: 20px;
  justify-content: space-between;
  margin-bottom: 10px;
}

.colr_whtie {
  color: white;
}

.opac-dark49 {
  opacity: 0.4;
  background: #ff0e0e38;
}

.rewrewrw {
  opacity: 0.9;
  background: #31ff0e38;
}

.d_pose {
  width: 50%;
  color: #42da3d;
  font-size: 11px;
  background: #053b24;
  border: 1px dashed #000000;
  cursor: pointer;
  border-radius: 10px;
  height: 32px;
  margin: 0px;
}

.admin_txt_msg {
  text-align: start;
  color: #ffffffc5;
  font-size: 15px;
}

.base {
  padding: 1px !important;
}

.new_iitt {
  font-family: "Gilroy", cursive;
  font-size: 24px;
  color: #ffffff;
  font-weight: 100;
  margin: 0px;
  display: flex;
}

.eswz_w {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #2e2e2ef2;
  border: 1px #3f3f3f solid;
  border-radius: 6px;
  padding: 0px 8px;
  height: 50px;
}

.ew_NEW {
  display: flex;
  align-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.h_e {
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  background-color: #111b11;
  flex-shrink: 0;
  box-sizing: border-box;
  touch-action: none;
  padding: 0px 20px;
  color: white !important;
  box-shadow: unset;
  display: flex;
}

.Selected_topbar {
  color: #66d345;
}

.white {
  filter: invert(1);
}

.jing_gameRE {
  font-size: 16px;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
}

.modal-c {
  background: #060a06;
  width: auto;
  margin: 0px auto;
  border-radius: 2px;
  box-shadow: 0 0px 48px rgb(0 0 0 / 90%);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  max-width: 800px;
}

.pointer {
  cursor: pointer;
}

.maring {
  margin-bottom: 4px;
}

.h-bar-0-mobile {
  display: none;
}

.base {
  margin: 0px;
  padding: 0px 5px;
  border: 1px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  font-size: 15px;
}

/* 
@media only screen and (max-height: 780px) {
    .legalz {
 
    font-size: 7px  !important;

}

} */
.adbtn {
  border: 1px solid #030e11;
  border-radius: 5px;
  height: 30px;
  background: #db5252e3;
  display: flex;
  align-items: center;
  color: white;
  font-size: 18px;

  width: 125px;
  padding: 0px;
  justify-content: center;
  margin-right: 10px;
}

.aside_selected {
  background: #2b2b2b;
  border-radius: 10px;
  margin: 8px 20px;
  transition: 0.1s;
}

.mr_left {
  margin-right: 20px;
}

@media only screen and (max-height: 720px) {
  .aside_btn {
    padding: 5px 20px;
  }

  .aside_btn_small {
    padding: 5px 20px;
  }
}

@media only screen and (max-height: 960px) {
  .legalz {
    font-size: 6px !important;
  }
}

@media only screen and (max-width: 600px) {
  .mobile_locked {
    padding: 20px !important;
    width: calc(100% - 40px) !important;
  }

  .prBn {
    font-size: 14px;
    padding: 0px 4px;
  }

  .h-bar-0-mobile {
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    background-color: #111b11;
    flex-shrink: 0;
    box-sizing: border-box;
    touch-action: none;
    padding: 0px 20px;
    color: white !important;
    box-shadow: unset;
    margin-top: 20px;
  }

  .modal-c {
    height: 100%;
  }
}

@media only screen and (min-width: 600px) {
  .name_holder {
    display: none;
  }

  .jkd_divc_holder {
    display: none;
  }

  .con_3 {
    justify-content: center;
  }

  .con_1 {
    justify-content: center;
  }
}

.namey__2 {
  font-size: 20px;
  font-family: "Gilroy";
  color: #ffffff;
  text-shadow: 0px 0px 10px black;
}

.e323 {
  text-decoration: underline;
}

.nocontrast {
  filter: grayscale(1);
}

.normal_light_theme {
  filter: initial;
}

.apexcharts-tooltip {
  background: #000000 !important;
}

.apexcharts-tooltip {
  background: lightblue;
  color: darkblue;
}

@import "../src/assets/css/old_styles.css";
@import "../src/assets/css/new_theme.css";

/* Loading Overlay Styles */
.route-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: all 0.3s ease;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(37, 193, 0, 0.1);
  border-top: 3px solid #25c100;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.mobile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background-color: #111b11;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 100;
}

.locked-account {
  display: flex;
  align-items: center;
  background-color: #ff4444;
  padding: 8px 16px;
  border-radius: 8px;
  font-weight: 500;
}

.locked-account i {
  margin-right: 8px;
}

.mobile-header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.left-actions, .right-actions {
  display: flex;
  align-items: center;
  gap: 12px;
}

.admin-button {
  background-color: #25c100;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.admin-button:hover {
  background-color: #1e9600;
}

.action-button {
  background-color: #1a231a;
  border: 1px solid #25c100;
  color: #25c100;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-button:hover {
  background-color: #25c100;
  color: white;
}

.action-button i {
  font-size: 20px;
}

.center-logo {
  flex: 1;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.mobile-logo {
  height: 32px;
  transition: transform 0.2s ease;
}

.mobile-logo:hover {
  transform: scale(1.05);
}

.mobile-header .material-icons {
  font-size: 20px;
}

.price_BOX_new {
  border: 1px solid #25c100;
  border-radius: 5px;
  height: 40px;
  background: #060a06;
  display: flex;
  align-items: center;
  color: white;
  font-size: 24px;
  font-family: monospace;
  width: 175px;
  padding: 0px;
  justify-content: center;
  box-shadow: 0 0 15px rgba(37, 193, 0, 0.2);
}

.price_LOGO {
  height: 22px;
  margin-right: 8px;
}
</style>

