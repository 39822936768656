<template>
  <div class="daily-spin-container">
    <div class="roulette-container">
      <div class="roulette-wheel" :style="wheelStyle">
        <div class="roulette-items" ref="rouletteItems">
          <div
            v-for="(value, index) in squareValues"
            :key="index"
            class="roulette-item"
            :class="{ 'winning-item': index === winningIndex }"
          >
            <div class="item-content">
              <img src="@/assets/gemstone.png" alt="Gemstone" class="gem-icon">
              <span class="value">{{ value.toFixed(2) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="selector"></div>
    </div>

    <div class="controls">
      <template v-if="!$root.user.name">
        <p class="login-message">Please login to use daily spin</p>
        <button class="spin-button" disabled>Spin the Wheel</button>
      </template>

      <template v-else>
        <button 
          class="spin-button" 
          :disabled="hasSpun || !spinReady"
          @click="spinRoulette"
          v-tooltip="hasSpun ? 'Already done' : ''"
        >
          Spin the Wheel
        </button>

        <div v-if="!spinReady" class="cooldown-message">
          <p>You have already done your daily spin</p>
          <p class="time-remaining">Next spin in: {{ timeLeft }}</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'DailySpin',
  
  data() {
    return {
      totalSquares: 40,
      squareValues: [],
      winningIndex: 34,
      winningValue: null,
      wheelStyle: { transform: 'translateX(0px)' },
      hasSpun: false,
      spinReady: false,
      timeLeft: '',
      itemWidth: 100,
      spinDuration: 8,
    };
  },

  mounted() {
    this.initializeRoulette();
    this.checkDailySpinStatus();
  },

  methods: {
    initializeRoulette() {
      this.generateRandomValues();
      this.wheelStyle = { transform: `translateX(-${this.itemWidth * 18}px)` };
    },

    generateRandomValues() {
      for (let i = 0; i < this.totalSquares; i++) {
        if (i === this.winningIndex) {
          this.squareValues.push(this.winningValue || 0);
        } else {
          const randomValue = this.generateRandomValue();
          this.squareValues.push(randomValue);
        }
      }
    },

    generateRandomValue() {
      const random = Math.random();
      if (random < 0.8) return Math.random() * (0.1 - 0.01) + 0.01;
      if (random < 0.95) return Math.random() * (20 - 0.1) + 0.1;
      if (random < 0.995) return Math.random() * (50 - 20) + 20;
      return Math.random() * (200 - 50) + 50;
    },

    async fetchWinningValue() {
      try {
        const response = await axios.post(`${this.$root.API_PREFIX}/api/daily_spin`, {
          token: this.$cookies.get("token"),
        });

        if (response.data.payout) {
          this.winningValue = parseFloat(response.data.payout);
          this.squareValues[this.winningIndex] = this.winningValue;
        } else {
          this.$toast.show(response.data.message, { position: "bottom", duration: 1500 });
        }
      } catch (error) {
        console.error("Error fetching winning value:", error);
        throw error;
      }
    },

    async checkDailySpinStatus() {
      try {
        const response = await axios.post(`${this.$root.API_PREFIX}/api/daily_spin_status`, {
          token: this.$cookies.get("token"),
        });

        this.spinReady = response.data.ready;
        this.timeLeft = response.data.ready ? '0h 0m' : response.data.timeLeft;
      } catch (error) {
        console.error("Error checking daily spin status:", error);
      }
    },

    async spinRoulette() {
      try {
        await this.fetchWinningValue();
        
        if (this.winningValue === null) {
          throw new Error('Winning value was not set');
        }

        const rouletteItems = this.$refs.rouletteItems;
        this.hasSpun = true;

        // Reset position
        rouletteItems.style.transition = 'none';
        this.wheelStyle = { transform: 'translateX(0px)' };
        void rouletteItems.offsetWidth; // Force reflow

        // Calculate final position
        const randomOffset = Math.floor(Math.random() * (80 - 20 + 1)) + 20;
        const winnerPosition = this.winningIndex * this.itemWidth + randomOffset;

        // Animate to winning position
        rouletteItems.style.transition = `transform ${this.spinDuration}s cubic-bezier(0.10, 1.0, 0.6, 1)`;
        this.wheelStyle = { transform: `translateX(-${winnerPosition}px)` };

        // Handle completion
        setTimeout(() => {
          this.$root.updateBalance(this.winningValue);
          this.spinReady = false;
          this.timeLeft = '23h 59m';
        }, this.spinDuration * 1000);

      } catch (error) {
        console.error('Failed to spin the wheel:', error);
      }
    },
  },
};
</script>

<style scoped>
.daily-spin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.roulette-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  background: #1a1a1a;
  border-radius: 12px;
  padding: 20px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.roulette-wheel {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.roulette-items {
  display: flex;
  gap: 2px;
  padding: 0 10px;
}

.roulette-item {
  flex: 0 0 100px;
  height: 100px;
  background: #2a2a2a;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.winning-item {
  background: #053b24;
  border: 2px solid #42da3d;
}

.item-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.gem-icon {
  width: 24px;
  height: 24px;
}

.value {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}

.selector {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4px;
  height: 100px;
  background: #42da3d;
  z-index: 2;
}

.controls {
  margin-top: 20px;
  text-align: center;
}

.spin-button {
  background: #053b24;
  color: #42da3d;
  border: 2px solid #42da3d;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 200px;
}

.spin-button:hover:not(:disabled) {
  background: #42da3d;
  color: #053b24;
}

.spin-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.login-message {
  color: #fff;
  font-size: 18px;
  margin-bottom: 15px;
}

.cooldown-message {
  margin-top: 15px;
  color: #F5F5DC;
}

.time-remaining {
  font-size: 16px;
  margin-top: 5px;
  color: #42da3d;
}
</style>
