<template>
    <div :class="{ loader: true, fadeout: !isLoading}">
      <img
        class="ss"
        :class="{  flyaway: flyAway }"
        src="../assets/m_logo.png"
      />
    </div>
  </template>
  
  <script>
  export default {
    name: "LoadingScreen",
    props: ["isLoading"],
    data() {
      return {
        flyAway: false,
        flyAwayValues: {
          x: 0,
          y: 0,
        },
      };
    },
    mounted() {
      // Use a timeout to trigger the "flyAway" animation after 2 seconds (adjust the timing as needed)
      setTimeout(() => {
        this.setRandomFlyAwayValues();
        this.flyAway = true;
      }, 2000);
    },
    methods: {
      setRandomFlyAwayValues() {
        // Get random value for x coordinate
        const maxX = window.innerWidth - 70; // Assuming the image width is 70px
        const minX = 0;
  
        const randomX = Math.floor(Math.random() * (maxX - minX + 1)) + minX;
  
        // Make the image fly upwards by setting y to a negative value
        this.flyAwayValues.x = randomX;
        this.flyAwayValues.y = -Math.random() * 200 - 200; // Set y to a negative random value between -200 and -400
      },
    },
  };
  </script>
  
  <style>
  .ss {
    width: 70px;
  }
  
   .white {
    filter: invert(1);
  }
   
  .loader {
    background-color: #000000;
    bottom: 0;
    color: white;
    font-size: 32px;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    text-align: center;
    top: 0;
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
  }
  
  .fadeout {
    animation: fadeout 3s forwards;
  }
  
  @keyframes fadeout {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      visibility: hidden;
      background-color: #000000;
    }
  }
  
  .flyaway {
    /* Transition position (translate) and opacity */
    animation: flyaway 1s forwards;
  }
  
  @keyframes flyaway {
    0% {
      opacity: 1;
      transform: translate(0, 0);
    }
    100% {
      opacity: 0;
      transform: translate(-200px, -200px); /* Adjust the values to control the flying direction */
      visibility: hidden;
    }
  }
  </style>
  