<template>
  <div class="login-container">
    <div class="h-bar-0-mobile" style="justify-content: center">
      <h1 class="new_iitt">Login</h1>
    </div>
    <div class="modal-body">
      <div class="e35_con">
        <img style="width: 180px; margin-bottom: 20px" src="../assets/logo.png" />
        <div v-if="$cookies.get(`wrong_login`) > 17" style="margin-bottom: 20px">
          <a style="color: #ff5353">ERROR: Too many login attempts have been made. Please try again later.</a>
        </div>
        <input 
          class="con_box5" 
          v-model="credentials.email" 
          v-on:keyup.enter="checkFields" 
          type="text"
          placeholder="Email" 
          name="psw" 
          required 
        />
        <div class="dlex_dipslau">
          <input 
            class="con_box5" 
            v-model="credentials.password"
            v-on:keyup.enter="checkFields"
            :type="show_password ? 'text' : 'password'"
            placeholder="Password" 
            name="psw" 
            required 
          />
          <button class="view_boxy" style="cursor: pointer" @click="toggle_password_show">
            <i v-if="!show_password" class="material-icons">remove_red_eye</i>
            <i v-else class="material-icons">visibility_off</i>
          </button>
        </div>
        
        <!-- Always show reCAPTCHA -->
        <div id="login-recaptcha" class="recaptcha-container"></div>
        
        <button class="login_btn" @click="checkFields" :disabled="isLoading">
          <span>Login</span>
          <span v-if="isLoading" class="spinner"></span>
        </button>

        <div class="hrhr">
          <a style="z-index: 2000; background: #060a06; padding: 0px 20px">OR</a>
        </div>
        <div class="social-login-buttons">
          <div class="social-buttons-row">
            <button class="social-btn google-btn" @click="$root.handleSocialLogin('google')">
              <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=" alt="Google" class="social-icon"/>
              Google
            </button>
            <button class="social-btn steam-btn" @click="$root.handleSocialLogin('steam')">
              <img src="https://upload.wikimedia.org/wikipedia/commons/8/83/Steam_icon_logo.svg" alt="Steam" class="social-icon"/>
              Steam
            </button>
          </div>
        </div>
        <div class="or_box">
          <router-link style="margin-bottom: 20px; color: #bfbfbf" class="h_link" to="/forgot-password">
            Forgot Password
          </router-link>
          <div class="signupbox_ dk_m">
            Don't have an account?
            <a 
              style="margin-right: 10px; margin-left: 5px; color: rgb(52 173 38); cursor: pointer;" 
              @click="$emit('switch-modal', 'create_a')"
            >
              Sign up
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'LoginComponent',
  data() {
    return {
      credentials: {
        email: null,
        password: null
      },
      show_password: false,
      recaptchaWidget: null,
      recaptchaResponse: null,
      isLoading: false
    }
  },
  watch: {
    'credentials.password': {
      handler(newVal) {
        if (newVal && newVal.length > 0 && !this.showRecaptcha) {
          this.showRecaptcha = true;
          this.$nextTick(() => {
            const recaptchaContainer = document.querySelector('.recaptcha-container');
            if (recaptchaContainer) {
              recaptchaContainer.classList.add('visible');
              this.initRecaptcha();
            }
          });
        }
      },
      immediate: true
    }
  },
  mounted() {
    // Load reCAPTCHA script if not already loaded
    if (!window.grecaptcha) {
      const recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=explicit')
      recaptchaScript.async = true
      recaptchaScript.defer = true
      recaptchaScript.onload = () => {
        // Small delay to ensure DOM is ready
        setTimeout(() => this.initRecaptcha(), 1000)
      }
      document.head.appendChild(recaptchaScript)
    } else {
      setTimeout(() => this.initRecaptcha(), 1000)
    }
  },
  methods: {
    initRecaptcha() {
      if (window.grecaptcha && document.getElementById('login-recaptcha')) {
        try {
          console.log('Initializing reCAPTCHA...');
          // Reset any existing widget
          if (this.recaptchaWidget !== null) {
            window.grecaptcha.reset(this.recaptchaWidget);
          }
          
          this.recaptchaWidget = window.grecaptcha.render('login-recaptcha', {
            sitekey: '6Lc9YvwqAAAAABvB_6dk7BH6qXc5gZXVcUW1MY5a',
            theme: 'dark',
            size: 'normal',
            callback: (token) => {
              console.log('reCAPTCHA callback received');
              if (token && typeof token === 'string') {
                this.recaptchaResponse = token;
                console.log('Valid token received, length:', token.length);
              } else {
                console.error('Invalid token received');
                this.recaptchaResponse = null;
              }
            },
            'expired-callback': () => {
              console.log('reCAPTCHA token expired');
              this.recaptchaResponse = null;
              window.grecaptcha.reset(this.recaptchaWidget);
              this.$toast.show('reCAPTCHA expired, please verify again', {
                position: "bottom",
                duration: 3500,
              });
            },
            'error-callback': () => {
              console.log('reCAPTCHA error occurred');
              this.recaptchaResponse = null;
              window.grecaptcha.reset(this.recaptchaWidget);
              this.$toast.show('reCAPTCHA error occurred, please try again', {
                position: "bottom",
                duration: 3500,
              });
            }
          });
          console.log('reCAPTCHA initialized successfully');
        } catch (e) {
          console.error('reCAPTCHA initialization error:', e);
         
        }
      } else {
        console.error('reCAPTCHA or container not found');
      }
    },
    toggle_password_show() {
      this.show_password = !this.show_password;
    },
    checkFields() {
      if (!this.credentials.email || !this.credentials.password) {
        this.$toast.show('Please enter both email and password', {
          position: "bottom",
          duration: 3500,
        });
        return;
      }
      
      if (!this.recaptchaResponse) {
        this.$toast.show('Please complete the reCAPTCHA verification', {
          position: "bottom",
          duration: 3500,
        });
        return;
      }
      
      this.login();
    },
    async login() {
      try {
        this.isLoading = true;
        
        if (!this.recaptchaResponse || typeof this.recaptchaResponse !== 'string') {
          console.error('Invalid or missing reCAPTCHA response');
          this.$toast.show('Please complete the reCAPTCHA verification', {
            position: "bottom",
            duration: 3500,
          });
          this.isLoading = false;
          return;
        }

        console.log('Preparing login request...');
        const requestData = {
          email: this.credentials.email.trim().toLowerCase(),
          password: this.credentials.password,
          recaptchaResponse: this.recaptchaResponse
        };

        console.log('Sending login request...');
        const response = await axios.post(`${this.$root.API_PREFIX}/api/login`, requestData);

        // Reset reCAPTCHA after sending request
        if (this.recaptchaWidget !== null) {
          window.grecaptcha.reset(this.recaptchaWidget);
          this.recaptchaResponse = null;
        }

        if (response.data.token === "incorrect") {
          const message = response.data.message || 'Incorrect Password or Email';
          console.error('Login failed:', message);
          this.$toast.show(message, {
            position: "bottom",
            duration: 3500,
          });
          this.isLoading = false;
          return;
        }

        // Login successful
        this.$emit('close-modal');
        this.$cookies.set("token", response.data.token, "10y");
        await this.$root.fetch_user();

        setTimeout(() => {
          if (this.$root.user.name) {
            this.$socket.emit("user_connect", {
              token: this.$cookies.get("token"),
            });
          }
        }, 500);

        this.$root.logged_in = true;
        this.$router.push({ path: "/" });

      } catch (error) {
        console.error('Login error:', error);
        
        // Reset reCAPTCHA on error
        if (this.recaptchaWidget !== null) {
          window.grecaptcha.reset(this.recaptchaWidget);
          this.recaptchaResponse = null;
        }

        const errorMessage = error.response?.data?.message || 'An error occurred during login. Please try again.';
        this.$toast.show(errorMessage, {
          position: "bottom",
          duration: 3500,
        });
      } finally {
        this.isLoading = false;
      }
    },
  }
}
</script>

<style scoped>
.login-container {
  width: 100%;
}

.modal-body {
  padding: 20px;
  background: #060a06;
  border-radius: 0 0 8px 8px;
}

.h-bar-0-mobile {
  background: #111b11;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px 8px 0 0;
}

.new_iitt {
  color: white;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.e35_con {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  background: #060a06;
  padding: 25px;
  border-radius: 0 0 8px 8px;
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
}

.con_box5 {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #42da3d;
  border-radius: 4px;
  background: #1a1e1a;
  color: white;
  font-size: 16px;
  font-family: "Gilroy", sans-serif;
  transition: all 0.2s ease;
}

.con_box5:focus {
  outline: none;
  border-color: #5aff54;
  box-shadow: 0 0 0 2px rgba(90, 255, 84, 0.1);
  background: #1f231f;
}

.dlex_dipslau {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.dlex_dipslau .con_box5 {
  padding-right: 40px;
}

.view_boxy {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  color: #42da3d;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.login_btn {
  position: relative;
  width: 100%;
  padding: 14px;
  background: linear-gradient(135deg, #42da3d 0%, #2b8f28 100%);
  border: none;
  border-radius: 4px;
  color: white;
  font-family: "Gilroy", sans-serif;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: 20px 0;
}

.login_btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background: linear-gradient(135deg, #3ab936 0%, #246f22 100%);
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 8px;
  vertical-align: middle;
  border: 3px solid #ffffff;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.hrhr {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #42da3d;
  line-height: 0.1em;
  margin: 25px 0;
  opacity: 0.5;
}

.hrhr a {
  background: #060a06;
  padding: 0 20px;
  color: #8d8d8d;
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
}

.social-login-buttons {
  width: 100%;
  margin: 15px 0;
}

.social-buttons-row {
  display: flex;
  gap: 12px;
  width: 100%;
}

.social-btn {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 16px;
  border-radius: 4px;
  background: #111b11;
  border: 1px solid #262626;
  color: #ffffff;
  font-family: "Gilroy", sans-serif;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 140px;
}

.social-btn:hover {
  background: #1a271a;
  border-color: #42da3d;
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(66, 218, 61, 0.1);
}

.social-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.google-btn {
  background: #111b11;
  border-color: #262626;
}

.steam-btn {
  background: #111b11;
  border-color: #262626;
}

.or_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-top: 20px;
}

.h_link {
  text-decoration: none;
  color: #bfbfbf;
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  transition: color 0.2s ease;
}

.h_link:hover {
  color: #42da3d;
}

.signupbox_ {
  color: white;
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
}

@media only screen and (max-width: 600px) {
  .e35_con {
    padding: 20px;
  }
  
  .social-buttons-row {
    flex-direction: column;
  }
  
  .social-btn {
    width: 100%;
  }
}

.recaptcha-container {
  margin: 15px 0;
  display: none;
  justify-content: center;
  width: 100%;
  background: rgba(17, 27, 17, 0.8);
  padding: 12px;
  border-radius: 4px;
  border: 1px solid rgba(66, 218, 61, 0.15);
  transition: opacity 0.3s ease;
  opacity: 0;
}

.recaptcha-container.visible {
  display: flex;
  opacity: 1;
}

.g-recaptcha {
  display: inline-block;
}
</style> 