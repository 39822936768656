<template>
  <div class="content">
    <Transition data-space-modal="choose_card" name="fade">
      <div v-if="$root.modal_open == 'choose_card'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" class="material-icons pointer">arrow_back</i>
              <h1 class="new_iitt">Select Card</h1>
              <i @click="$root.modal_open = 'wallet_config'" style="cursor: pointer" class="material-icons">settings</i>
            </div>
            <div class="modal-body" style="border-bottom: 20px solid #060a06">
              <div class="box_2fa4vvvv">
                <a v-if="$root.saved_addresses.length == 0">NO WITHDRAWAL CARDS</a>
                <div style="margin: 20px" :class="option.color + 'color'" class="smal_card" @click="
                  (this.$root.w_selected = option) && (this.$root.modal_open = false)
                  " v-for="option in $root.saved_addresses" :key="option">
                  <div class="carder_tpper" :class="option.color + 'colortop'">
                    <img class="smol-icon3e" style="height: 15px; width: 10px" src="../assets/m_logo.png" />
                    <a>Address Card</a>
                    <img class="smol-icone" style="height: 15px; width: 15px; padding: 2px"
                      :src="require(`@/assets/cryptos-icons/${option.type}.png`)" />
                  </div>
                  <div class="no_scroler">
                    <a class="bold_txt" :class="option.color + 'txtColor'">{{
                      option.name
                    }}</a>
                  </div>
                  <div class="card-bottm" :class="option.color + 'colortop'">
                    <a class="card_name">{{ option.address }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <!-- Coinflip IconFast -->
    <Transition name="fade">
      <div v-if="$root.modal_open == 'provably_fairFast'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" style="cursor: pointer" class="material-icons">arrow_back</i>
              <h1 class="new_iitt">Fast Fairness</h1>
              <i style="color: #111b11" class="material-icons">arrow_back</i>
            </div>
            <div class="modal-body" style="border-bottom: 20px solid #060a06">
            <h1 style="text-align: center; background-color: black; color: limegreen; padding: 20px; margin: 0;">
    Stay Tuned <br>Coming soon!
</h1>
             
            </div>
          </div>
        </div>
      </div>
    </Transition>

        <!-- Coinflip IconFast -->
        <Transition name="fade">
      <div v-if="$root.modal_open == 'provably_fairDailySpin'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" style="cursor: pointer" class="material-icons">arrow_back</i>
              <h1 class="new_iitt">Daily spin Fairness</h1>
              <i style="color: #111b11" class="material-icons">arrow_back</i>
            </div>
            <div class="modal-body" style="border-bottom: 20px solid #060a06">
              <h1 style="text-align: center; background-color: black; color: limegreen; padding: 20px; margin: 0;">
    Stay Tuned <br>Coming soon!
</h1>
              <!-- <div class="e3container">
                        <a>
                            <b style="font-weight: 900; font-style: oblique; color: rgb(197, 199, 86);">1. Server Seed:</b>
                            The server generates a secret seed before the game begins. This seed is hashed and the hash is shown to players before any bets are placed.
                        </a>
                        <br>
                        <a>
                            <b style="font-weight: 900; font-style: oblique; color: rgb(114, 199, 86);">2. Client Seed:</b>
                            Players can provide their own seed to influence the outcome. If no seed is provided, a random seed is used.
                        </a>
                        <br>
                        <a>
                            <b style="font-weight: 900; font-style: oblique; color: rgb(199, 86, 189);">3. Result Calculation:</b>
                            Once all players have placed their bets, the server combines the server seed and the client seed using a cryptographic algorithm to determine the winner.
                        </a>
                        <br>
                        <a>
                            <b style="font-weight: 900; font-style: oblique; color: rgb(86, 144, 199);">4. Verification:</b>
                            After the game, players can verify the fairness by comparing the original server seed hash with the revealed server seed and the client seed used.
                        </a>
                        <br>
                    </div> -->
            </div>
          </div>
        </div>
      </div>
    </Transition>


    <!-- Coinflip IconSlots -->
    <Transition name="fade">
      <div v-if="$root.modal_open == 'provably_fairSlots'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" style="cursor: pointer" class="material-icons">arrow_back</i>
              <h1 class="new_iitt">Slots Fairness</h1>
              <i style="color: #111b11" class="material-icons">arrow_back</i>
            </div>
            <div class="modal-body" style="border-bottom: 20px solid #060a06">
              <h1 style="text-align: center; background-color: black; color: limegreen; padding: 20px; margin: 0;">
    Stay Tuned <br>Coming soon!
</h1>
              <!-- <div class="e3container">
                        <a>
                            <b style="font-weight: 900; font-style: oblique; color: rgb(197, 199, 86);">1. Server Seed:</b>
                            The server generates a secret seed before the game begins. This seed is hashed and the hash is shown to players before any bets are placed.
                        </a>
                        <br>
                        <a>
                            <b style="font-weight: 900; font-style: oblique; color: rgb(114, 199, 86);">2. Client Seed:</b>
                            Players can provide their own seed to influence the outcome. If no seed is provided, a random seed is used.
                        </a>
                        <br>
                        <a>
                            <b style="font-weight: 900; font-style: oblique; color: rgb(199, 86, 189);">3. Result Calculation:</b>
                            Once all players have placed their bets, the server combines the server seed and the client seed using a cryptographic algorithm to determine the outcome.
                        </a>
                        <br>
                        <a>
                            <b style="font-weight: 900; font-style: oblique; color: rgb(86, 144, 199);">4. Verification:</b>
                            After the game, players can verify the fairness by comparing the original server seed hash with the revealed server seed and the client seed used.
                        </a>
                        <br>
                    </div> -->
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <!-- Coinflip IconPlinko -->
    <Transition name="fade">
      <div v-if="$root.modal_open == 'provably_fairPlinko'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" style="cursor: pointer" class="material-icons">arrow_back</i>
              <h1 class="new_iitt">Plinko Fairness</h1>
              <i style="color: #111b11" class="material-icons">arrow_back</i>
            </div>
            <div class="modal-body" style="border-bottom: 20px solid #060a06">
              <h1 style="text-align: center; background-color: black; color: limegreen; padding: 20px; margin: 0;">
    Stay Tuned <br>Coming soon!
</h1>
              <!-- <div class="e3container">
                        <a>
                            <b style="font-weight: 900; font-style: oblique; color: rgb(197, 199, 86);">1. Server Seed:</b>
                            The server generates a secret seed before the game begins. This seed is hashed and the hash is shown to players before any bets are placed.
                        </a>
                        <br>
                        <a>
                            <b style="font-weight: 900; font-style: oblique; color: rgb(114, 199, 86);">2. Client Seed:</b>
                            Players can provide their own seed to influence the outcome. If no seed is provided, a random seed is used.
                        </a>
                        <br>
                        <a>
                            <b style="font-weight: 900; font-style: oblique; color: rgb(199, 86, 189);">3. Result Calculation:</b>
                            Once all players have placed their bets, the server combines the server seed and the client seed using a cryptographic algorithm to determine the outcome.
                        </a>
                        <br>
                        <a>
                            <b style="font-weight: 900; font-style: oblique; color: rgb(86, 144, 199);">4. Verification:</b>
                            After the game, players can verify the fairness by comparing the original server seed hash with the revealed server seed and the client seed used.
                        </a>
                        <br>
                    </div> -->
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <!-- Coinflip IconBlackjack -->
    <Transition name="fade">
      <div v-if="$root.modal_open == 'provably_fairBlackjack'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" style="cursor: pointer" class="material-icons">arrow_back</i>
              <h1 class="new_iitt">Blackjack Fairness</h1>
              <i style="color: #111b11" class="material-icons">arrow_back</i>
            </div>
            <div class="modal-body" style="border-bottom: 20px solid #060a06">
              <h1 style="text-align: center; background-color: black; color: limegreen; padding: 20px; margin: 0;">
    Stay Tuned <br>Coming soon!
</h1>

              <!-- <div class="e3container">
                        <a>
                            <b style="font-weight: 900; font-style: oblique; color: rgb(197, 199, 86);">1. Server Seed:</b>
                            The server generates a secret seed before the game begins. This seed is hashed and the hash is shown to players before any bets are placed.
                        </a>
                        <br>
                        <a>
                            <b style="font-weight: 900; font-style: oblique; color: rgb(114, 199, 86);">2. Client Seed:</b>
                            Players can provide their own seed to influence the outcome. If no seed is provided, a random seed is used.
                        </a>
                        <br>
                        <a>
                            <b style="font-weight: 900; font-style: oblique; color: rgb(199, 86, 189);">3. Result Calculation:</b>
                            Once all players have placed their bets, the server combines the server seed and the client seed using a cryptographic algorithm to determine the outcome.
                        </a>
                        <br>
                        <a>
                            <b style="font-weight: 900; font-style: oblique; color: rgb(86, 144, 199);">4. Verification:</b>
                            After the game, players can verify the fairness by comparing the original server seed hash with the revealed server seed and the client seed used.
                        </a>
                        <br>
                    </div> -->
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <!-- Coinflip IconSquares -->
    <Transition name="fade">
      <div v-if="$root.modal_open == 'provably_fairSquares'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" style="cursor: pointer" class="material-icons">arrow_back</i>
              <h1 class="new_iitt">Squares Fairness</h1>
              <i style="color: #111b11" class="material-icons">arrow_back</i>
            </div>

            <div class="modal-body" style="border-bottom: 20px solid #060a06;">

              <h1 style="text-align: center; background-color: black; color: limegreen; padding: 20px; margin: 0;">
    Stay Tuned <br>Coming soon!
</h1> <!-- 

                    <div class="e3container">
                        <a>
                            <b style="font-weight: 900; font-style: oblique; color: rgb(197, 199, 86);">1. Server Seed:</b>
                            The server generates a secret seed before the game begins. This seed is hashed and the hash is shown to players before any bets are placed.
                        </a>
                        <br>
                        <a>
                            <b style="font-weight: 900; font-style: oblique; color: rgb(114, 199, 86);">2. Client Seed:</b>
                            Players can provide their own seed to influence the outcome. If no seed is provided, a random seed is used.
                        </a>
                        <br>
                        <a>
                            <b style="font-weight: 900; font-style: oblique; color: rgb(199, 86, 189);">3. Result Calculation:</b>
                            Once all players have placed their bets, the server combines the server seed and the client seed using a cryptographic algorithm to determine the outcome.
                        </a>
                        <br>
                        <a>
                            <b style="font-weight: 900; font-style: oblique; color: rgb(86, 144, 199);">4. Verification:</b>
                            After the game, players can verify the fairness by comparing the original server seed hash with the revealed server seed and the client seed used.
                        </a>
                        <br>
                    </div>-->
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <Transition data-space-modal="choose_card" name="fade">
      <div v-if="$root.modal_open == 'gamesPick'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" class="material-icons pointer">arrow_back</i>
              <h1 class="new_iitt">Choose Game</h1>
              <i style="color: #111b11" class="material-icons">done_all</i>
            </div>
            <div class="modal-body" style="border-bottom: 20px solid #060a06">
              <a class="pointer" @click="
                $router.push('/coinflip');
              dropdownVisible = false;
              ">
                <img src="./../assets/game_icons/cf.png" style="height: 30px; width: 30px" alt="Coinflip Icon" />
                Coinflip </a><br />

              <a class="pointer" @click="
                $router.push('/Jackpot');
              dropdownVisible = false;
              ">
                <img src="./../assets/game_icons/jp.png" style="height: 30px; width: 30px" alt="Coinflip Icon" />Jackpot
              </a><br /><a class="pointer" @click="
                $router.push('/Dice');
              dropdownVisible = false;
              ">
                <img src="./../assets/game_icons/di.png" style="height: 30px; width: 30px" alt="Coinflip Icon" />Dice
              </a><br />
              <a class="pointer" @click="
                $router.push('/Upgrader');
              dropdownVisible = false;
              ">
                <img src="./../assets/game_icons/up.png" style="height: 30px; width: 30px"
                  alt="Coinflip Icon" />Upgrader </a><br />

              <a class="pointer" @click="
                $router.push('/roulette');
              dropdownVisible = false;
              ">
                <img src="./../assets/game_icons/rou_icon.png" style="height: 30px; width: 30px" alt="Coinflip Icon" />
                Roulette </a><br />
              <a class="pointer" @click="
                $router.push('/fast');
              dropdownVisible = false;
              ">
                <img src="./../assets/game_icons/crash.png" style="height: 30px; width: 30px" alt="Coinflip Icon" />Fast
              </a><br />
              <a class="pointer" @click="
                $router.push('/blackjack');
              dropdownVisible = false;
              ">
                <img src="./../assets/game_icons/bj.png" style="height: 30px; width: 30px"
                  alt="Coinflip Icon" />Blackjack </a><br />
              <a class="pointer" @click="
                $router.push('/slot');
              dropdownVisible = false;
              ">
                <img src="./../assets/game_icons/sl.png" style="height: 30px; width: 30px" alt="Coinflip Icon" />Slots
              </a><br />

              <a class="pointer" @click="
                $router.push('/Chicken');
              dropdownVisible = false;
              ">
                <img src="./../assets/game_icons/ch.png" style="height: 30px; width: 30px" alt="Coinflip Icon" />Chicken
              </a><br />
              <a class="pointer" @click="
                $router.push('/Plinko');
              dropdownVisible = false;
              ">
                <img src="./../assets/game_icons/pl.png" style="height: 30px; width: 30px" alt="Coinflip Icon" />Plinko
              </a><br />
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <Transition data-space-modal="m_chat" name="fade">
      <div v-if="$root.modal_open == 'm_chat'" style="z-index: 1000" class="modal-mask">
        <div class="modal-wrapper" style="z-index: 1000" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" class="material-icons pointer">arrow_back</i>
              <h1 class="new_iitt">Chat</h1>
              <i style="color: #111b11" class="material-icons">done_all</i>
            </div>

            <ChatComp />
          </div>
        </div>
      </div>
    </Transition>
    <Transition data-space-modal="why_partner" name="fade">
      <div v-if="$root.modal_open == 'why_partner'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" class="material-icons pointer">arrow_back</i>
              <h1 class="new_iitt">Why partner with us?</h1>
              <i style="color: #111b11" class="material-icons">done_all</i>
            </div>
            <div class="modal-body" style="border-bottom: 20px solid transparent">
              <div class="box_2fa4">
                <a>In our system at CryptoFlip.GG, making money is quick, easy and simple.
                  We make it extremely simple for you all the time.</a><br />
                <div class="icon_box">
                  <div class="idcon_field2">
                    <div class="box_">
                      <img style="width: inherit" src="./../assets/affilites/invite.png" />
                      <a>INVITE OTHERS</a>
                    </div>
                  </div>
                  <div class="idcon_field2">
                    <div class="box_">
                      <img style="width: inherit" src="./../assets/affilites/profit.png" />
                      <a>MAKE PROFIT</a>
                    </div>
                  </div>
                  <div class="idcon_field2">
                    <div class="box_">
                      <img style="width: inherit" src="./../assets/affilites/withdrawal.png" />
                      <a>CASH OUT</a>
                    </div>
                  </div>
                  <div class="idcon_field2">
                    <div class="box_">
                      <img style="width: inherit" src="./../assets/affilites/repeat.png" />
                      <a>REPEAT</a>
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
              <br />
              <a>1 Referal =
                <img class="price_LOGO" src="../assets/gemstone.png" />0.50</a><br /><br />
              <a style="font-size: 13px; color: #787878; font-style: italic">Consider becoming an affilite today!</a>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <transition name="fade">
      <div v-if="$root.modal_open == 'lock24'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" class="material-icons pointer">arrow_back</i>
              <h1 class="new_iitt">Are you sure ?</h1>
              <i style="color: #111b11" class="material-icons">done_all</i>
            </div>
            <div class="modal-body" style="border-bottom: 20px solid transparent">
              <div class="box_2fa4">
                <h1 class="new_iitt" style="color: #f2ff00">Lock account for 24 hours</h1>
                <p>
                  If you lock your account for 24 hours,
                  <b style="font-family: monospace; color: #d8b352">under no circumstances will support lift this
                    ban.</b>
                </p>

                <a style="font-size: 13px; color: #787878; font-style: italic">We don't want you losing more than you
                  can afford!</a>
              </div>
              <br />
              <a>Please seek help if you think gambling is a problem for you.</a><br /><br />
              <div v-if="countdown == 11">
                <button :disabled="countdownRunning" @click="startCountdown" class="sumbit_btn2" style="width: 100px">
                  Proceed
                </button>
              </div>

              <div v-else-if="countdown > 0">
                <button :disabled="countdownRunning" @click="startCountdown" class="countingdonw_btn">
                  WAIT, {{ countdown }}
                </button>
              </div>
              <div v-else>
                <button @click="confirmAction24" class="sbt">LOCK NOW</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="$root.modal_open == 'lock7'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" class="material-icons pointer">arrow_back</i>
              <h1 class="new_iitt">Are you sure ?</h1>
              <i style="color: #111b11" class="material-icons">done_all</i>
            </div>
            <div class="modal-body" style="border-bottom: 20px solid transparent">
              <div class="box_2fa4">
                <h1 class="new_iitt" style="color: #ff8800">Lock account for 7 days</h1>
                <p>
                  If you lock your account for 24 hours,
                  <b style="font-family: monospace; color: #d8b352">under no circumstances will support lift this
                    ban.</b>
                </p>

                <a style="font-size: 13px; color: #787878; font-style: italic">We don't want you losing more than you
                  can afford!</a>
              </div>
              <br />
              <a>Please seek help if you think gambling is a problem for you.</a><br /><br />
              <div v-if="countdown == 11">
                <button :disabled="countdownRunning" @click="startCountdown" class="sumbit_btn2" style="width: 100px">
                  Proceed
                </button>
              </div>

              <div v-else-if="countdown > 0">
                <button :disabled="countdownRunning" @click="startCountdown" class="countingdonw_btn">
                  WAIT, {{ countdown }}
                </button>
              </div>
              <div v-else>
                <button @click="confirmAction7" class="sbt">LOCK NOW</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="$root.modal_open == 'lock31'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" class="material-icons pointer">arrow_back</i>
              <h1 class="new_iitt">Are you sure ?</h1>
              <i style="color: #111b11" class="material-icons">done_all</i>
            </div>
            <div class="modal-body" style="border-bottom: 20px solid transparent">
              <div class="box_2fa4">
                <h1 class="new_iitt" style="color: #e43212">Lock account for 31 days</h1>
                <p>
                  If you lock your account for 31 days,
                  <b style="font-family: monospace; color: #d8b352">under no circumstances will support lift this
                    ban.</b>
                </p>

                <a style="font-size: 13px; color: #787878; font-style: italic">We don't want you losing more than you
                  can afford!</a>
                <div></div>
              </div>
              <br />
              <a>Please seek help if you think gambling is a problem for you.</a><br /><br />
              <div v-if="countdown == 11">
                <button :disabled="countdownRunning" @click="startCountdown" class="sumbit_btn2" style="width: 100px">
                  Proceed
                </button>
              </div>

              <div v-else-if="countdown > 0">
                <button :disabled="countdownRunning" @click="startCountdown" class="countingdonw_btn">
                  WAIT, {{ countdown }}
                </button>
              </div>
              <div v-else>
                <button @click="confirmAction31" class="sbt">LOCK NOW</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <Transition data-space-modal="edit_profile" name="fade">
      <div v-if="$root.modal_open == 'edit_profile'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" style="cursor: pointer" class="material-icons">arrow_back</i>
              <h1 class="new_iitt">Edit your profile</h1>
              <i style="color: #111b11" class="material-icons">done_all</i>
            </div>
            <div class="modal-body">
              <div class="flexr_goldere">
                <img class="img_dp_2000x"
                  :src="`${this.$root.API_PREFIX}/api/DisplayPicture?name=${$root.user.name}`" />
                <button class="nekweBtn widebodyBtn" @click="$router.push('/profile/set_image')">
                  Change Picture
                </button>
              </div>
              <a style="font-size: 17px">Email : {{ $root.email }}</a>
              <!-- <div class="flex_bozerrr">
                                <input class="eeq_wq" type="text" placeholder="Email" name="psw" v-model="$root.email" required/>
                                <button class="nekweBtn" @click="set_email()">Change Email</button> 
                            </div> --><br /><br />
              <!-- <a>Nickname :</a>
              <div class="flex_bozerrr">
                <input class="eeq_wq" v-if="$root.nickname != ''" v-model="temp_nickname" type="text"
                  :placeholder="$root.nickname" name="psw" required />
                <input class="eeq_wq" v-else v-model="temp_nickname" type="text" placeholder="No nickname set"
                  name="psw" required />
              </div> -->
              <!-- <a>Twitter / Instagram :</a>
                            <div class="flex_bozerrr">
                                <input class="eeq_wq" v-model="temp_handle" type="text"  name="psw" required/>
                                <button @click="update_handle()" class="nekweBtn">Change Handle</button>
                            </div> -->
              <a>Bio :</a>
              <div class="flex_bozerrr">
                <textarea class="eeq_wq" v-model="temp_bio" style="resize: both"></textarea>
              </div>
              <template v-if="
                this.$root.bio != this.temp_bio ||
                this.$root.nickname != this.temp_nickname
              ">
                <br />
                <button @click="update_bio(), update_nickname()" class="nekweBtn">
                  SAVE CHANGES
                </button>
                <br /><br />
              </template>

              <a style="color: rgb(124, 132, 124); font-size: 12px">To change your profile picture please close this box
                and click
                your
                profile image.</a>
              <br />
              <br />
              <div class="e3container"></div>
            </div>
            <!-- <button @click="this.modal_open = '2fa_code'">2FA Code</button> -->
          </div>
        </div>
      </div>
    </Transition>

    <Transition name="fade">
      <div v-if="$root.modal_open == 'tip_box'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" style="cursor: pointer" class="material-icons">arrow_back</i>
              <h1 class="new_iitt">Send tip to {{ $root.tipName }}</h1>
              <i style="color: #111b11" class="material-icons">done_all</i>
            </div>
            <div class="modal-body">
              <img class="img_dp_2000x" :src="`${this.$root.API_PREFIX}/api/DisplayPicture?name=${$root.tipName}`" />

              <h2>{{ $root.tipName }}</h2>

              <!-- Enter amount -->
              <a>Tip Amount:</a>
              <div class="flex_bozerrr">
                <input class="eeq_wq" v-model="tipAmount" type="number" placeholder="Enter amount" required />
              </div>

              <!-- Confirm button to open confirmation popup -->
              <button class="nekweBtn" style="margin-bottom: 20px" @click="confirmTip()">
                Send Tip
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <!-- Confirmation Modal -->
    <Transition name="fade">
      <div v-if="$root.modal_open == 'confirm_tip'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" style="cursor: pointer" class="material-icons">arrow_back</i>
              <h1 class="new_iitt">Confirm Tip</h1>
              <i style="color: #111b11" class="material-icons">done_all</i>
            </div>
            <div class="modal-body">
              <p style="font-size: 20px">
                Confirm tip of
                <a style="font-family: monospace; color: #c7c7c7">
                  <img class="price_LOGO" src="../assets/gemstone.png" />{{
                    parseFloat(tipAmount).toFixed(2)
                  }}</a>
                to
                <a style="text-decoration: underline; color: #a1ff69">{{
                  $root.tipName
                }}</a>?
              </p>

              <!-- Buttons to confirm or cancel -->

              <button class="btn_create_newz" @click="finalizeTip()">Confirm</button>
              <button class="cancel_game_idontwant" style="margin-bottom: 30px" @click="$root.modal_open = 'tip_box'">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <Transition data-space-modal="wallet" name="fade">
      <div v-if="$root.modal_open == 'wallet_config'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" v-if="
                this.$route.matched.some((route) => route.path.includes('/profile'))
              " class="material-icons pointer">arrow_back</i>
              <i v-else @click="$root.modal_open = 'choose_card'" class="material-icons pointer">arrow_back</i>
              <h1 class="new_iitt">Edit Withdrawal(s)</h1>
              <i style="color: #111b11" class="material-icons">arrow_back</i>
            </div>
            <div class="modal-body" style="border-bottom: 20px solid #060a06; margin-bottom: 0px">
              <h1 class="new_iittx2">Add New</h1>

              <input type="text" v-model="$root.new_address.address" class="tilte" style="
                  color: white;
                  border: 1px solid #525252;
                  margin-bottom: -30px;
                  background: #080a08;
                " placeholder="Address" />
              <input type="text" v-model="$root.new_address.name" class="tilte" style="
                  color: white;
                  border: 1px solid #525252;
                  margin-top: 40px;
                  margin-bottom: -30px;
                  background: #080a08;
                  border: 1px solid grey;
                " placeholder="Name" />
            </div>
            <div class="modal-body" style="border-bottom: 20px solid #060a06">
              <div class="flzexr">
                <select name="cars" class="new321231213" style="
                    margin-right: 10px;
                    outline: none !important;
                    border: 1px solid grey;
                  " v-model="$root.new_address.type">
                  <option selected>btc</option>
                  <option>eth</option>
                  <option>ltc</option>
                  <option>doge</option>

                  <option>xrp</option>
                </select>
                <img class="iconz" :src="require(`../assets/cryptos-icons/${$root.new_address.type}.png`)" />
                <select name="cars" class="tilte" :id="$root.new_address.color"
                  style="border: 1px solid grey; outline: none !important" v-model="$root.new_address.color">
                  <option id="Green" selected>Green</option>
                  <option id="Purple">Purple</option>
                  <option id="Orange">Orange</option>
                  <option id="Blue">Blue</option>
                  <option id="Black">Black</option>
                  <option id="White">White</option>
                </select>
                <button class="add_new_addy" style="margin-left: 10px" @click="$root.add_withdrawal_address()">
                  Add
                </button>
              </div>
              <div class="box_2fa4">
                <div>
                  <h1 class="new_iittx2">My Cards</h1>
                  <div class="card_box">
                    <div v-for="option in $root.saved_addresses" :key="option">
                      <div :class="option.color + `color`" class="carddoe">
                        <a :class="option.color + `txtColor`" class="card_name">{{
                          option.address
                        }}</a>
                        <a :class="option.color + `txtColor`" class="card_name">{{
                          option.name
                        }}</a>
                        <button class="x_btn" @click="$root.delete_withdrawal_address(option.address)">
                          <i class="material-icons">cancel</i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <a v-if="$root.saved_addresses == ''" style="
                    border-radius: 10px;
                    padding: 12px;
                    background: rgb(0 0 0);
                    margin-top: 10px;
                    border: 1px solid white;
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                  ">Create a saved withdrawal card and set up a new address for future
                  transactions. <i class="large material-icons">arrow_upward</i></a><a v-else>You are currently using
                  one {{
                    $root.saved_addresses.length }} of 6
                  available withdrawal cards.</a>
                <br />
                Learn more about
                <a style="
                    color: rgb(102, 211, 69);
                    text-decoration: underline;
                    cursor: pointer;
                  " @click="$router.push('/faq?withdrawal=cards')">Withdrawal Cards</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <Transition data-space-modal="c_ticket" name="fade">
      <div v-if="$root.modal_open == 'c_ticket'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" class="material-icons" style="cursor: pointer">arrow_back</i>
              <h1 class="new_iitt">Create Ticket</h1>
              <button class="btn_create_newz" style="font-size: 15px; width: 150px" @click="sumbit">
                Sumbit Ticket
              </button>
            </div>
            <div class="modal-body" style="border-bottom: 20px solid #060a06">
              <div class="boady">
                <a>Please fill out the form with as much detail as possible. This includes
                  providing transaction IDs, timestamps, dates, and any other relevant
                  information that can help us identify and troubleshoot the issue.</a><br />
                <input type="text" v-model="title" class="tilte" placeholder="Please enter a title..." />
                <textarea v-model="problem_msg" class="ioxrnje22" placeholder="What is your problem?"></textarea><a
                  class="grey_xt">Minimum characters: {{ problem_msg.length }} / 100</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <Transition data-space-modal="login" name="fade">
      <div v-if="$root.modal_open == 'login'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <LoginComponent 
              @close-modal="$root.modal_open = false"
              @switch-modal="(modal) => $root.modal_open = modal"
            />
          </div>
        </div>
      </div>
    </Transition>
    <transition name="fade">
      <div v-if="$root.modal_open == 'confirm_cf_max'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="closeModal">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <h1 class="new_iitt">Confirm Game</h1>
            </div>
            <div class="modal-body" style="border-bottom: 20px solid #060a06">
              <p class="lhm">
                Are you sure you would you like to confirm this game with wager
                <a class="gunembrak"><img class="price_LOGO" src="../assets/gemstone.png" />{{
                  $root.user.balance
                }}</a>?
              </p>
              <button class="btn_create_newz" @click="$root.processGameCreation()">
                Confirm
              </button>
              <button class="cancel_game_idontwant"
                @click="($root.modal_open = null), (this.$root.create_game.wager = 1)">
                Cancel</button><br />
            </div>
          </div>
        </div>
      </div>
    </transition>

    <Transition name="fade">
      <div v-if="$root.modal_open == 'provably_fairJackpot'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" style="cursor: pointer" class="material-icons">arrow_back</i>
              <h1 class="new_iitt">Jackpot Fairness</h1>
              <i style="color: #111b11" class="material-icons">arrow_back</i>
            </div>
            <div class="modal-body" style="border-bottom: 20px solid #060a06">

              <pre style="padding: 5px; border-radius: 5px; overflow-x: auto; line-height: 1.5; font-size: 14px;">
<span style="color: rgb(255, 0, 154);">// Generate a random result using server seed, client seed, and nonce</span>
<span style="color: #9f9fff;">function</span> <span style="color: rgb(0, 82, 255);">getJackpotWinner</span>(<span style="color: rgb(210, 226, 63);">serverSeed</span>, <span style="color: rgb(210, 226, 63);">clientSeed</span>, <span style="color: rgb(210, 226, 63);">nonce</span>, <span style="color: rgb(210, 226, 63);">totalTickets</span>) {
  <span style="color: rgb(255, 0, 154);">// Create a combined seed string</span>
  <span style="color: #9f9fff;">const</span> <span style="color: rgb(23, 199, 102);">combinedSeed</span> = serverSeed + clientSeed + nonce;
  
  <span style="color: rgb(255, 0, 154);">// Generate a hash using the combined seed</span>
  <span style="color: #9f9fff;">const</span> <span style="color: rgb(23, 199, 102);">hash</span> = CryptoJS.SHA256(combinedSeed).toString();
  
  <span style="color: rgb(255, 0, 154);">// Take the first 8 characters of the hash and convert to integer</span>
  <span style="color: #9f9fff;">const</span> <span style="color: rgb(23, 199, 102);">hexValue</span> = hash.substr(0, 8);
  <span style="color: #9f9fff;">const</span> <span style="color: rgb(23, 199, 102);">decimalValue</span> = parseInt(hexValue, 16);
  
  <span style="color: rgb(255, 0, 154);">// Calculate the winning ticket number (0 to totalTickets-1)</span>
  <span style="color: #9f9fff;">const</span> <span style="color: rgb(23, 199, 102);">winningTicket</span> = decimalValue % totalTickets;
  
  <span style="color: #9f9fff;">return</span> {
    winningTicket,
    hash,
    verificationData: {
      serverSeed,
      clientSeed,
      nonce,
      totalTickets
    }
  };
}
</pre>





            </div>
          </div>
        </div>
      </div>
    </Transition>

    <Transition name="fade">
      <div v-if="$root.modal_open == 'provably_fairDice'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" style="cursor: pointer" class="material-icons">arrow_back</i>
              <h1 class="new_iitt">Dice Fairness</h1>
              <i style="color: #111b11" class="material-icons">arrow_back</i>
            </div>
            <div class="modal-body" style="border-bottom: 20px solid #060a06">
             




              <pre style="padding: 5px; border-radius: 5px; overflow-x: auto; line-height: 1.5; font-size: 14px;">
<span style="color: rgb(255, 0, 154);">// Function to simulate a provably fair dice roll</span>
<span style="color: #9f9fff;">function</span> <span style="color: rgb(0, 82, 255);">getDiceResult</span>(<span style="color: rgb(210, 226, 63);">serverSeed</span>, <span style="color: rgb(210, 226, 63);">clientSeed</span>, <span style="color: rgb(210, 226, 63);">nonce</span>, <span style="color: rgb(210, 226, 63);">rangeMin</span>, <span style="color: rgb(210, 226, 63);">rangeMax</span>) {
  <span style="color: rgb(255, 0, 154);">// Step 1: Calculate the range size and validate the input range</span>
  <span style="color: #9f9fff;">const</span> <span style="color: rgb(23, 199, 102);">rangeSize</span> = rangeMax - rangeMin;
  <span style="color: #9f9fff;">if</span> (<span style="color: rgb(23, 199, 102);">rangeSize</span> &gt;= 0) {
    <span style="color: rgb(255, 0, 154);">// Invalid range, return an error</span>
    <span style="color: #9f9fff;">return</span> { <span style="color: rgb(210, 226, 63);">error</span>: <span style="color: rgb(23, 199, 102);">'Invalid range'</span> };
  }

  <span style="color: rgb(255, 0, 154);">// Step 2: Generate a combined seed string for fairness</span>
  <span style="color: #9f9fff;">const</span> <span style="color: rgb(23, 199, 102);">combinedSeed</span> = serverSeed + clientSeed + nonce;

  <span style="color: rgb(255, 0, 154);">// Step 3: Create a hash from the combined seed</span>
  <span style="color: #9f9fff;">const</span> <span style="color: rgb(23, 199, 102);">hash</span> = CryptoJS.SHA256(combinedSeed).toString();

  <span style="color: rgb(255, 0, 154);">// Step 4: Convert the first 8 characters of the hash to a decimal number</span>
  <span style="color: #9f9fff;">const</span> <span style="color: rgb(23, 199, 102);">hexValue</span> = hash.substr(0, 8);
  <span style="color: #9f9fff;">const</span> <span style="color: rgb(23, 199, 102);">decimalValue</span> = parseInt(hexValue, 16);

  <span style="color: rgb(255, 0, 154);">// Step 5: Calculate the random roll value based on the seed and range</span>
  <span style="color: #9f9fff;">const</span> <span style="color: rgb(23, 199, 102);">roll</span> = (decimalValue % 100) / 100;  <span style="color: rgb(255, 0, 154);">// Generate a number between 0 and 1</span>

  <span style="color: rgb(255, 0, 154);">// Step 6: Calculate the multiplier</span>
  <span style="color: #9f9fff;">const</span> <span style="color: rgb(23, 199, 102);">multiplier</span> = (100 / rangeSize).toFixed(2);

  <span style="color: rgb(255, 0, 154);">// Step 7: Determine if the roll is a win or loss</span>
  <span style="color: rgb(255, 0, 154);">// Commenting out comparison operators to avoid parsing error</span>
  <span style="color: rgb(23, 199, 102);">const win</span> = roll &gt;= rangeMin / 100 && roll &lt;= rangeMax / 100;

  <span style="color: rgb(255, 0, 154);">// Step 8: Return the result</span>
  <span style="color: #9f9fff;">return</span> {
    win,
    roll,
    multiplier,
    verificationData: {
      serverSeed,
      clientSeed,
      nonce,
      rangeMin,
      rangeMax
    }
  };
}
</pre>






            </div>
          </div>
        </div>
      </div>
    </Transition>

    <Transition name="fade">
      <div v-if="$root.modal_open == 'provably_fairUpgrader'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" style="cursor: pointer" class="material-icons">arrow_back</i>
              <h1 class="new_iitt">Upgrader Fairness</h1>
              <i style="color: #111b11" class="material-icons">arrow_back</i>
            </div>
            <div class="modal-body" style="border-bottom: 20px solid #060a06">
              <h1 style="text-align: center; background-color: black; color: limegreen; padding: 20px; margin: 0;">
    Stay Tuned <br>Coming soon!
</h1>
              <!-- <div class="e3container">
                <br />
                <a>
                  The outcome of the Upgrader system is determined by a random number
                  between 0.00 and 99.99. This number is generated through a **provably
                  fair system** that guarantees the outcome is not predetermined or
                  manipulated in any way. The fairness system relies on two key
                  components: a **server seed** (generated by the platform) and a **client
                  seed** (inputted or randomly generated for the user). These seeds are
                  combined and hashed to create a random result. To ensure the fairness of
                  the outcome, the server provides a hash of the server seed before the
                  game begins. Once the game is complete, users can verify the result by
                  comparing the hashed server seed with the pre-provided hash. If the
                  hashes match, it proves that the outcome was not altered. The process
                  works as follows: 1. **Server Seed Hashing**: Before the user plays the
                  upgrader, the server generates a random seed (server seed). The server
                  hash, which is a hashed version of this seed, is sent to the client
                  before any game outcomes are decided. This ensures that the server
                  cannot manipulate the seed after the result is generated. 2. **Client
                  Seed**: The user or platform generates a client seed. This seed is
                  either inputted by the user or automatically generated for the player at
                  the start of the game. The client seed is then combined with the server
                  seed to create the final result. 3. **Combining Seeds**: Once the user
                  plays, the system combines both the **server seed** and the **client
                  seed**. These seeds are concatenated (`serverSeed:clientSeed`) and
                  passed through a random number generator algorithm (e.g., `seedrandom`)
                  to generate a result between 0.00 and 99.99. 4. **Result Verification**:
                  After the game, the user can verify the fairness by comparing the hashed
                  server seed (sent before the game) with the server seed used to generate
                  the result. If the hash matches, it guarantees the result was generated
                  fairly and not manipulated by the platform. Example of verification
                  steps:
                </a>
                <br />
                <a>
                  import seedrandom from 'https://cdn.skypack.dev/seedrandom@3.0.5' import
                  CryptoJS from 'https://cdn.skypack.dev/crypto-js'
                  document.getElementById('verify').onclick = () => { const serverSeed =
                  document.getElementById('serverSeed').value const serverHash =
                  document.getElementById('serverHash').value const clientSeed =
                  document.getElementById('clientSeed').value if (!serverSeed) return
                  alert('Server seed is required') if (!serverHash) return alert('Server
                  hash is required') if (!clientSeed) return alert('Client seed is
                  required') // Verify server seed integrity const isHashValid =
                  CryptoJS.SHA256(serverSeed.trim()).toString() === serverHash.trim() if
                  (!isHashValid) return alert('Server hash does not match the provided
                  server seed') // Generate outcome based on seeds const seed =
                  `${serverSeed}:${clientSeed}` const rng = seedrandom(seed) const outcome
                  = Math.floor(rng() * 10000) / 100 // Result between 0.00 and 99.99 //
                  Display outcome document.getElementById('result').textContent =
                  `Upgrader outcome: ${outcome}` // Log the result for further
                  transparency console.log('Upgrader Result:', outcome) }
                </a>

                <br />
                <a>
                  ### How it ensures fairness: - **Server Hash Commitment**: By providing
                  the server hash before the game starts, the server locks in its seed and
                  prevents itself from changing it to alter the outcome. - **Client
                  Involvement**: The user contributes to the result by generating or using
                  a client seed, ensuring that the final outcome is not controlled solely
                  by the platform. - **Verification**: The ability for users to verify
                  that the pre-game server hash matches the server seed ensures that no
                  foul play occurred. This system allows users to feel confident that
                  their outcomes are truly random and not manipulated. It creates
                  transparency and trust in the fairness of the game.
                </a>
                <br />
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <Transition name="fade">
      <div v-if="$root.modal_open == 'provably_fairRoulette'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" style="cursor: pointer" class="material-icons">arrow_back</i>
              <h1 class="new_iitt">Roulette Fairness</h1>
              <i style="color: #111b11" class="material-icons">arrow_back</i>
            </div>
            <div class="modal-body" style="border-bottom: 20px solid #060a06">
              <h1 style="text-align: center; background-color: black; color: limegreen; padding: 20px; margin: 0;">
    Stay Tuned <br>Coming soon!
</h1>
              <!-- <div class="e3container">
                <br />
                <a>
                  Place your bets and watch the wheel spin in this classic game. The
                  fairness of the spin is guaranteed by our provably fair system: 1.
                  **Server Seed Hash**: Before spinning, the server generates a server
                  seed and provides a hash of it to you. This ensures that the seed cannot
                  be altered after the game starts. 2. **Client Seed**: You can either
                  input your own client seed or use a randomly generated one. This seed is
                  combined with the server seed. 3. **Spin Outcome**: The combination of
                  server and client seeds determines where the wheel stops. 4.
                  **Verification**: After the spin, compare the server seed with the
                  pre-shared hash to verify that the outcome was not manipulated. This
                  ensures that every spin is fair and transparent.
                </a>
                <br />
              Add any additional verification logic or example code here if needed
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <Transition data-space-modal="rules" name="fade">
      <div v-if="$root.modal_open == 'rules'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" style="cursor: pointer" class="material-icons">arrow_back</i>
              <h1 class="new_iitt">Chat rules</h1>
              <i style="color: #111b11" class="material-icons">arrow_back</i>
            </div>
            <div class="modal-body" style="border-bottom: 20px solid #060a06">
              <div class="e3container">
                <a>
                  <b style="
                      font-weight: 900;
                      font-style: oblique;
                      color: rgb(197, 199, 86);
                    ">1. Don't ask for money</b>
                  anywhere including in private messages and the spam channel. Asking for
                  loans or investments and implying that other players should give you
                  money is equivalent.
                </a>
                <br />
                <a>
                  <b style="
                      font-weight: 900;
                      font-style: oblique;
                      color: rgb(114, 199, 86);
                    ">2. Don't advertise.</b>
                  This includes trade and job offers as well as referral and affiliate
                  links. Advertising in the spam channel is tolerated as long as you
                  <b>don't automate posting your ads</b>.
                </a>
                <br />
                <a>
                  <b style="
                      font-weight: 900;
                      font-style: oblique;
                      color: rgb(199, 86, 189);
                    ">3. Don't use multiple accounts to chat at the same time or to evade
                    mutes.</b>
                  Using an account to chat while muted on another account may lead to it
                  being muted as well.
                </a>
                <br />
                <a>
                  <b style="
                      font-weight: 900;
                      font-style: oblique;
                      color: rgb(86, 144, 199);
                    ">4. Don't share accounts with other players</b>, even temporarily. If they become muted or banned
                  for breaking the
                  chat rules, your accounts might end up being muted or banned as well.
                </a>
                <br />
                <a>
                  <b style="
                      font-weight: 900;
                      font-style: oblique;
                      color: rgb(197, 199, 86);
                    ">5. Beware of scammers!</b>
                  bustabit is not a trading site and won't get involved in disputes
                  between players under any circumstances. We highly recommend not trading
                  here.
                </a>
                <br />
                <a>
                  <b style="
                      font-weight: 900;
                      font-style: oblique;
                      color: rgb(197, 199, 86);
                    ">Zero Tolerance for Racism:</b>
                  We have no place for racism here. Any racist comments or behavior will
                  result in immediate muting.
                </a>
                <br />
                <a>
                  <b style="
                      font-weight: 900;
                      font-style: oblique;
                      color: rgb(114, 199, 86);
                    ">Respect Each Other:</b>
                  Treat everyone with respect and kindness. We're all part of the
                  CryptoFlip family. 😊
                </a>
                <br />
                <a>
                  <b style="
                      font-weight: 900;
                      font-style: oblique;
                      color: rgb(199, 86, 189);
                    ">No Personal Attacks:</b>
                  Disagreements happen, but personal attacks won't be tolerated. Keep it
                  civil.
                </a>
                <br />
                <a>
                  <b style="
                      font-weight: 900;
                      font-style: oblique;
                      color: rgb(86, 144, 199);
                    ">Be Mindful of Language:</b>
                  Keep your language clean.
                </a>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <Transition data-space-modal="vault" name="fade">
      <div v-if="$root.modal_open == 'vault'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" class="material-icons">arrow_back</i>
              <h1 class="new_iitt">Vault</h1>
              <i style="color: #111b11" class="material-icons">arrow_back</i>
            </div>
            <div class="modal-body" style="border-bottom: 20px solid #060a06">
              <div style="text-align: center">
                <div class="vault-icon-wrap">
                  <img v-if="inVault == 0" class="vault-icon" src="../assets/vaultIcons/empty.png" />
                  <img v-else class="vault-icon" src="../assets/vaultIcons/full.png" />
                </div>

                <div class="vault-balance">
                  <img class="price_LOGO" src="../assets/gemstone.png" />
                  <number ref="number1" :to="inVault" :format="$root.theFormat" :duration=".4" 
                    easing="Power1.easeOut" />
                </div>
              </div>

              <div class="vault-actions">
                <button @click="vaultdepositmode = 0" 
                  class="vualtBtn" 
                  :class="!vaultdepositmode ? '' : 'secltor'"
                  style="margin-right: 20px">
                  Deposit
                </button>
                <button @click="vaultdepositmode = 1" 
                  class="vualtBtn" 
                  :class="vaultdepositmode ? '' : 'secltor'">
                  Withdraw
                </button>
              </div>

              <div class="transaction-form">
                <template v-if="vaultdepositmode == 0">
                  <div class="input-section">
                    <span class="input-label">Enter vault deposit</span>
                    <FancyInputComponent v-model="vaultdeposit" placeholder="Enter Amount" />
                    <div class="action-buttons">
                      <button type="button" class="vault_actioon" @click="vaultDepoist()">
                        Deposit to Vault
                      </button>
                      <button @click="vaultdeposit = $root.user.balance.toFixed(2)" class="maxBTUN" v-tooltip="'Max'">
                        MAX
                      </button>
                      <button @click="vaultdeposit = (vaultdeposit / 2).toFixed(2)" v-tooltip="'Half'" class="lesserBTN">
                        1/2
                      </button>
                      <button @click="vaultdeposit = (vaultdeposit * 2).toFixed(2)" v-tooltip="'Double'" class="lesserBTN">
                        2x
                      </button>
                    </div>
                  </div>
                </template>

                <template v-if="vaultdepositmode == 1">
                  <div class="input-section">
                    <span class="input-label">Enter vault withdrawal</span>
                    <FancyInputComponent v-model="vaultWithdrawl" placeholder="Enter Amount" />
                    <div class="action-buttons">
                      <button type="button" class="vault_actioon withdraw" @click="vaultwithdraw()">
                        Withdraw from Vault
                      </button>
                      <button @click="vaultWithdrawl = inVault.toFixed(2)" class="maxBTUN" v-tooltip="'Max'">
                        MAX
                      </button>
                      <button @click="vaultWithdrawl = (vaultWithdrawl / 2).toFixed(2)" v-tooltip="'Half'" class="lesserBTN">
                        1/2
                      </button>
                      <button @click="vaultWithdrawl = (vaultWithdrawl * 2).toFixed(2)" v-tooltip="'Double'" class="lesserBTN">
                        2x
                      </button>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <Transition data-space-modal="dspin" name="fade">
      <div v-if="$root.modal_open == 'dspin'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" class="material-icons">arrow_back</i>
              <h1 class="new_iitt">Daily Spin</h1>
              <i style="color: #111b11" class="material-icons">arrow_back</i>
            </div>
            <div class="modal-body" style="
                border-bottom: 20px solid #060a06;
                justify-content: center;
                align-items: center;
              ">
              <daily-spin-component @spun="handleSpin"></daily-spin-component>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <Transition data-space-modal="kyc1" name="fade">
      <div v-if="$root.modal_open == 'kyc1'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" class="material-icons pointer">arrow_back</i>
              <h1 class="new_iitt">
                <i class="material-icons"
                  style="display: flex; margin-right: 5px; align-items: center">verified_user</i>
                Verify 1
              </h1>
              <button @click="sumbit_kyc1()" class="login_btndew">Sumbit</button>
            </div>
            <div class="modal-body" style="border-bottom: 20px solid #060a06">
              <div class="e3container">
                <a>Your Legal Names :</a>
                <br />
                <div class="flzexr">
                  <input type="text" class="ioxrnje" style="height: unset; margin-right: 20px" v-model="firstname"
                    placeholder="First Name" />
                  <input type="text" class="ioxrnje" style="height: unset" v-model="lastname" placeholder="Last Name" />
                </div>
                <a>Your Address :</a>
                <br />
                <!-- <a style="color:grey;">Birthday: <br><br></a>
<div class="con_box">
    
    
    
    
    
    
  
    
    
    
    
    <input class="email_innner_box2" style="padding-right: 0px; width: 60px;" type="number" placeholder="Date" name="dob" required>

  <input class="email_innner_box" style="padding-right: 0px;" type="string" placeholder="Choose Date" name="dob" required> 

    <select  class="email_innner_box2">
    <option value=''>--Select Month--</option>
    <option selected value='1'>Janaury</option>
    <option value='2'>February</option>
    <option value='3'>March</option>
    <option value='4'>April</option>
    <option value='5'>May</option>
    <option value='6'>June</option>
    <option value='7'>July</option>
    <option value='8'>August</option>
    <option value='9'>September</option>
    <option value='10'>October</option>
    <option value='11'>November</option>
    <option value='12'>December</option>
    </select> 
    
    <input class="email_innner_box2" style="margin-left:10px;padding-right: 0px; width: 70px;" type="number"  v-model="dob" placeholder="Year" name="dob" required>


</div> -->

                <div class="flzexr"></div>
                <input type="text" class="tilte" style="border: 1px solid #505050; background: black; color: white"
                  v-model="address.line1" placeholder="Address Line 1" />
                <input type="text" class="tilte" style="border: 1px solid #505050; background: black; color: white"
                  v-model="address.line2" placeholder="Address Line 2" />
                <div class="flzexr">
                  <input type="text" class="tilte" style="
                      border: 1px solid #040404;
                      background: #242424;
                      margin-right: 20px;
                      font-size: 13px;
                    " v-model="address.cityTown" placeholder="Town/City" />
                  <input type="text" class="tilte" style="
                      border: 1px solid #040404;
                      background: #242424;
                      font-size: 13px;
                    " v-model="address.postCode" placeholder="Postcode" />
                </div>
                <select class="tilte" style="border: 1px solid #053b24; width: 100%" placeholder="Country"
                  v-model="address.country">
                  <option value="" disabled selected>Select Country</option>
                  <option value="Afghanistan">Afghanistan</option>
                  <option value="Albania">Albania</option>
                  <option value="Algeria">Algeria</option>
                  <option value="American Samoa">American Samoa</option>
                  <option value="Andorra">Andorra</option>
                  <option value="Angola">Angola</option>
                  <option value="Anguilla">Anguilla</option>
                  <option value="Antartica">Antarctica</option>
                  <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                  <option value="Argentina">Argentina</option>
                  <option value="Armenia">Armenia</option>
                  <option value="Aruba">Aruba</option>
                  <option value="Australia">Australia</option>
                  <option value="Austria">Austria</option>
                  <option value="Azerbaijan">Azerbaijan</option>
                  <option value="Bahamas">Bahamas</option>
                  <option value="Bahrain">Bahrain</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Barbados">Barbados</option>
                  <option value="Belarus">Belarus</option>
                  <option value="Belgium">Belgium</option>
                  <option value="Belize">Belize</option>
                  <option value="Benin">Benin</option>
                  <option value="Bermuda">Bermuda</option>
                  <option value="Bhutan">Bhutan</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
                  <option value="Botswana">Botswana</option>
                  <option value="Bouvet Island">Bouvet Island</option>
                  <option value="Brazil">Brazil</option>
                  <option value="British Indian Ocean Territory">
                    British Indian Ocean Territory
                  </option>
                  <option value="Brunei Darussalam">Brunei Darussalam</option>
                  <option value="Bulgaria">Bulgaria</option>
                  <option value="Burkina Faso">Burkina Faso</option>
                  <option value="Burundi">Burundi</option>
                  <option value="Cambodia">Cambodia</option>
                  <option value="Cameroon">Cameroon</option>
                  <option value="Canada">Canada</option>
                  <option value="Cape Verde">Cape Verde</option>
                  <option value="Cayman Islands">Cayman Islands</option>
                  <option value="Central African Republic">
                    Central African Republic
                  </option>
                  <option value="Chad">Chad</option>
                  <option value="Chile">Chile</option>
                  <option value="China">China</option>
                  <option value="Christmas Island">Christmas Island</option>
                  <option value="Cocos Islands">Cocos (Keeling) Islands</option>
                  <option value="Colombia">Colombia</option>
                  <option value="Comoros">Comoros</option>
                  <option value="Congo">Congo</option>
                  <option value="Congo">Congo, the Democratic Republic of the</option>
                  <option value="Cook Islands">Cook Islands</option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Cota D'Ivoire">Cote d'Ivoire</option>
                  <option value="Croatia">Croatia (Hrvatska)</option>
                  <option value="Cuba">Cuba</option>
                  <option value="Cyprus">Cyprus</option>
                  <option value="Czech Republic">Czech Republic</option>
                  <option value="Denmark">Denmark</option>
                  <option value="Djibouti">Djibouti</option>
                  <option value="Dominica">Dominica</option>
                  <option value="Dominican Republic">Dominican Republic</option>
                  <option value="East Timor">East Timor</option>
                  <option value="Ecuador">Ecuador</option>
                  <option value="Egypt">Egypt</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                  <option value="Eritrea">Eritrea</option>
                  <option value="Estonia">Estonia</option>
                  <option value="Ethiopia">Ethiopia</option>
                  <option value="Falkland Islands">Falkland Islands (Malvinas)</option>
                  <option value="Faroe Islands">Faroe Islands</option>
                  <option value="Fiji">Fiji</option>
                  <option value="Finland">Finland</option>
                  <option value="France">France</option>
                  <option value="France Metropolitan">France, Metropolitan</option>
                  <option value="French Guiana">French Guiana</option>
                  <option value="French Polynesia">French Polynesia</option>
                  <option value="French Southern Territories">
                    French Southern Territories
                  </option>
                  <option value="Gabon">Gabon</option>
                  <option value="Gambia">Gambia</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Germany">Germany</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Gibraltar">Gibraltar</option>
                  <option value="Greece">Greece</option>
                  <option value="Greenland">Greenland</option>
                  <option value="Grenada">Grenada</option>
                  <option value="Guadeloupe">Guadeloupe</option>
                  <option value="Guam">Guam</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Guinea">Guinea</option>
                  <option value="Guinea-Bissau">Guinea-Bissau</option>
                  <option value="Guyana">Guyana</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Heard and McDonald Islands">
                    Heard and Mc Donald Islands
                  </option>
                  <option value="Holy See">Holy See (Vatican City State)</option>
                  <option value="Honduras">Honduras</option>
                  <option value="Hong Kong">Hong Kong</option>
                  <option value="Hungary">Hungary</option>
                  <option value="Iceland">Iceland</option>
                  <option value="India">India</option>
                  <option value="Indonesia">Indonesia</option>
                  <option value="Iran">Iran (Islamic Republic of)</option>
                  <option value="Iraq">Iraq</option>
                  <option value="Ireland">Ireland</option>
                  <option value="Israel">Israel</option>
                  <option value="Italy">Italy</option>
                  <option value="Jamaica">Jamaica</option>
                  <option value="Japan">Japan</option>
                  <option value="Jordan">Jordan</option>
                  <option value="Kazakhstan">Kazakhstan</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Kiribati">Kiribati</option>
                  <option value="Democratic People's Republic of Korea">
                    Korea, Democratic People's Republic of
                  </option>
                  <option value="Korea">Korea, Republic of</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                  <option value="Lao">Lao People's Democratic Republic</option>
                  <option value="Latvia">Latvia</option>
                  <option value="Lebanon" selected>Lebanon</option>
                  <option value="Lesotho">Lesotho</option>
                  <option value="Liberia">Liberia</option>
                  <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                  <option value="Liechtenstein">Liechtenstein</option>
                  <option value="Lithuania">Lithuania</option>
                  <option value="Luxembourg">Luxembourg</option>
                  <option value="Macau">Macau</option>
                  <option value="Macedonia">
                    Macedonia, The Former Yugoslav Republic of
                  </option>
                  <option value="Madagascar">Madagascar</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Maldives">Maldives</option>
                  <option value="Mali">Mali</option>
                  <option value="Malta">Malta</option>
                  <option value="Marshall Islands">Marshall Islands</option>
                  <option value="Martinique">Martinique</option>
                  <option value="Mauritania">Mauritania</option>
                  <option value="Mauritius">Mauritius</option>
                  <option value="Mayotte">Mayotte</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Micronesia">Micronesia, Federated States of</option>
                  <option value="Moldova">Moldova, Republic of</option>
                  <option value="Monaco">Monaco</option>
                  <option value="Mongolia">Mongolia</option>
                  <option value="Montserrat">Montserrat</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Mozambique">Mozambique</option>
                  <option value="Myanmar">Myanmar</option>
                  <option value="Namibia">Namibia</option>
                  <option value="Nauru">Nauru</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Netherlands">Netherlands</option>
                  <option value="Netherlands Antilles">Netherlands Antilles</option>
                  <option value="New Caledonia">New Caledonia</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Nicaragua">Nicaragua</option>
                  <option value="Niger">Niger</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Niue">Niue</option>
                  <option value="Norfolk Island">Norfolk Island</option>
                  <option value="Northern Mariana Islands">
                    Northern Mariana Islands
                  </option>
                  <option value="Norway">Norway</option>
                  <option value="Oman">Oman</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="Palau">Palau</option>
                  <option value="Panama">Panama</option>
                  <option value="Papua New Guinea">Papua New Guinea</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Peru">Peru</option>
                  <option value="Philippines">Philippines</option>
                  <option value="Pitcairn">Pitcairn</option>
                  <option value="Poland">Poland</option>
                  <option value="Portugal">Portugal</option>
                  <option value="Puerto Rico">Puerto Rico</option>
                  <option value="Qatar">Qatar</option>
                  <option value="Reunion">Reunion</option>
                  <option value="Romania">Romania</option>
                  <option value="Russia">Russian Federation</option>
                  <option value="Rwanda">Rwanda</option>
                  <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                  <option value="Saint LUCIA">Saint LUCIA</option>
                  <option value="Saint Vincent">Saint Vincent and the Grenadines</option>
                  <option value="Samoa">Samoa</option>
                  <option value="San Marino">San Marino</option>
                  <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                  <option value="Saudi Arabia">Saudi Arabia</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Sierra">Sierra Leone</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Slovakia">Slovakia (Slovak Republic)</option>
                  <option value="Slovenia">Slovenia</option>
                  <option value="Solomon Islands">Solomon Islands</option>
                  <option value="Somalia">Somalia</option>
                  <option value="South Africa">South Africa</option>
                  <option value="South Georgia">
                    South Georgia and the South Sandwich Islands
                  </option>
                  <option value="Span">Spain</option>
                  <option value="SriLanka">Sri Lanka</option>
                  <option value="St. Helena">St. Helena</option>
                  <option value="St. Pierre and Miguelon">St. Pierre and Miquelon</option>
                  <option value="Sudan">Sudan</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Svalbard">Svalbard and Jan Mayen Islands</option>
                  <option value="Swaziland">Swaziland</option>
                  <option value="Sweden">Sweden</option>
                  <option value="Switzerland">Switzerland</option>
                  <option value="Syria">Syrian Arab Republic</option>
                  <option value="Taiwan">Taiwan, Province of China</option>
                  <option value="Tajikistan">Tajikistan</option>
                  <option value="Tanzania">Tanzania, United Republic of</option>
                  <option value="Thailand">Thailand</option>
                  <option value="Togo">Togo</option>
                  <option value="Tokelau">Tokelau</option>
                  <option value="Tonga">Tonga</option>
                  <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Turkey">Turkey</option>
                  <option value="Turkmenistan">Turkmenistan</option>
                  <option value="Turks and Caicos">Turks and Caicos Islands</option>
                  <option value="Tuvalu">Tuvalu</option>
                  <option value="Uganda">Uganda</option>
                  <option value="Ukraine">Ukraine</option>
                  <option value="United Arab Emirates">United Arab Emirates</option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="United States">United States</option>
                  <option value="United States Minor Outlying Islands">
                    United States Minor Outlying Islands
                  </option>
                  <option value="Uruguay">Uruguay</option>
                  <option value="Uzbekistan">Uzbekistan</option>
                  <option value="Vanuatu">Vanuatu</option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Vietnam">Viet Nam</option>
                  <option value="Virgin Islands (British)">
                    Virgin Islands (British)
                  </option>
                  <option value="Virgin Islands (U.S)">Virgin Islands (U.S.)</option>
                  <option value="Wallis and Futana Islands">
                    Wallis and Futuna Islands
                  </option>
                  <option value="Western Sahara">Western Sahara</option>
                  <option value="Yemen">Yemen</option>
                  <option value="Serbia">Serbia</option>
                  <option value="Zambia">Zambia</option>
                  <option value="Zimbabwe">Zimbabwe</option>
                </select>
                <a>It is our policy to keep your names and information as private as
                  possible. See why it is necessary
                  <span style="color: #66d345; cursor: pointer" @click="$router.push('/faq')">here</span>.</a><br />
                <a style="color: grey; font-style: italic; font-size: 12px">You will need to complete this inorder to
                  with
                  withdraw more than 25
                  total gems.</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <Transition data-space-modal="c_acc_ipad" name="fade">
      <div v-if="$root.modal_open == 'c_acc_ipad'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" class="material-icons hifer pointer">close</i>
              <h1 class="new_iitt">Create Account</h1>
              <i style="color: #111b11" class="material-icons hifer">arrow_back</i>
            </div>
            <div class="modal-body" style="border-bottom: 20px solid #060a06">
              <CreateAComponent />
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <Transition data-space-modal="create_game" name="fade">
      <div v-if="$root.modal_open == 'create_game'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" class="material-icons pointer">arrow_back</i>
              <h1 class="new_iitt mobile_display">Create Game</h1>
              <button class="btn_create_newz" @click="$root.createGame_socket()">
                Create
              </button>
            </div>
            <div class="modal-body" style="border-bottom: 20px solid transparent">
              <h1 class="new_iitt other_text" style="margin-bottom: 10px">Create Game</h1>
              Here you can create a game, start by choosing the bet amount and select the
              side, then click "Create".<br /><br />Enter amount :
              <div class="pc_view_io">
                <div class="wdnhwdafis">
                  <img class="price_LOGO" src="../assets/gemstone.png" /><input class="none" type="number"
                    v-on:keyup.enter="$root.createGame_socket()" v-model="$root.create_game.wager"
                    placeholder="Enter amount" />
                </div>
                <div class="c_gamebtn_price_holder">
                  <button v-tooltip="'Add +1.00 gems'" class="lesserBTN" @click="increaseByOne">
                    +1
                  </button>
                  <button v-tooltip="'Add +10.00 gems'" class="lesserBTN" @click="increaseByTen">
                    +10
                  </button>
                  <button v-tooltip="'Add +100.00 gems'" class="lesserBTN" @click="increaseByHundred">
                    +100
                  </button>

                  <button v-tooltip="'Half wager'" @click="half()" class="lesserBTN">
                    1/2
                  </button>
                  <button @click="double()" v-tooltip="'Double wager'" class="lesserBTN">
                    2x
                  </button>

                  <button class="lesserBTN" v-tooltip="'Max bet'" @click="setMaxBet">
                    MAX
                  </button>
                </div>
              </div>
              Choose side :
              <form class="dwqdwq">
                <!-- <div class="pc_view_cf">
                                    <a class="c_g1">$</a>  <input class="c_g" type="number"  v-model="$root.create_game.wager" placeholder="0.00" id="fname" name="fname">
                                    </div> -->
                <input v-model="$root.create_game.side" type="radio" class="non_show" checked="checked" id="Green"
                  name="age" value="0" />
                <label for="Green">
                  <div v-bind:class="{ coin_selected: $root.create_game.side == 0 }"
                    class="green_circle b32eb_gen3 pointer">
                    <img v-tooltip="'Green'" class="logo" style="width: 50px" src="../assets/green_coin.png" />
                  </div>
                </label>
                <input v-model="$root.create_game.side" type="radio" class="non_show" id="Purple" name="age"
                  value="1" />
                <label for="Purple">
                  <div v-bind:class="{ coin_selected: $root.create_game.side == 1 }"
                    class="purple_circle b32eb3 pointer">
                    <img v-tooltip="'Purple'" class="logo" style="width: 50px" src="../assets/purple_coin.png" />
                  </div>
                </label>
              </form>
              <br />
              <i>You can cancel your game at any time.</i>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <Transition data-space-modal="create_a" name="fade">
      <div v-if="$root.modal_open == 'create_a'" class="modal-mask" @mousedown="$root.modal_open = false">
        <div class="contents_CENYER">
          <div @mousedown.stop>
            <div class="grem_photyo">
              <div class="modal-wrapper erwq">
                <div style="width: 540px">
                  <i @click="$root.modal_open = false" style="
                      cursor: pointer;
                      color: white;
                      margin-bottom: 20px;
                      color: white;
                    " class="material-icons">close
                  </i>

                  <img src="./../assets/real_gem.png" />
                </div>
                <div class="new_mopdal_c">
                  <div class="cc_header2">
                    <h1 class="new_iitt" style="margin-left: 30px">Create Account</h1>
                  </div>
                  <div class="modal-body">
                    <CreateAComponent />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <Transition data-space-modal="2fa_code" name="fade">
      <div v-if="$root.modal_open == '2fa_code'" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-c">
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" class="material-icons hifer">arrow_back</i>
              <h1 class="new_iitt mobile_display">Enter Code</h1>
              <button class="btn_create_newz" @click="$root.try_2fa_code()">Go</button>
            </div>
            <div class="modal-body" style="border-bottom: 20px solid transparent">
              <h1 class="new_iitt other_text">Two Factor Authenatcion</h1>
              Check your emails. looks like to finish your login enter the code you got
              emailed<br /><br />6 digit code :
              <div class="pc_view_io">
                <div class="wdnhwdafis">
                  <input id="text" class="none" type="text" v-model="$root.code2fa_modal" />
                </div>
              </div>
              <br />
              <i>Didn't get an email?, resend email.</i>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <Transition data-space-modal="kyc3" name="fade">
      <div v-if="$root.modal_open == 'kyc3'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" class="material-icons pointer">arrow_back</i>
              <h1 class="new_iitt">
                <i class="material-icons" data-v-67fbe2d7=""
                  style="display: flex; margin-right: 5px; align-items: center">verified_user</i>
                Verify 3
              </h1>
              <i style="color: #111b11" class="material-icons">arrow_back</i>
            </div>
            <div class="modal-body" style="border-bottom: 20px solid #060a06">
              <ResizeKYC3Component></ResizeKYC3Component>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <Transition data-space-modal="kyc2" name="fade">
      <div v-if="$root.modal_open == 'kyc2'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" class="material-icons pointer">arrow_back</i>
              <h1 class="new_iitt">
                <i class="material-icons" data-v-67fbe2d7=""
                  style="display: flex; margin-right: 5px; align-items: center">verified_user</i>
                Verify 2
              </h1>
              <i style="color: #111b11" class="material-icons">arrow_back</i>
            </div>
            <div class="modal-body" style="margin-bottom: 0px">
              <ResizeKYC2Component></ResizeKYC2Component>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <Transition data-space-modal="kyc4" name="fade">
      <div v-if="$root.modal_open == 'kyc4'" class="modal-mask">
        <div class="modal-wrapper" @mousedown="$root.modal_open = false">
          <div class="modal-c" @mousedown.stop>
            <div class="h-bar-0-mobile">
              <i @click="$root.modal_open = false" class="material-icons pointer">arrow_back</i>
              <h1 class="new_iitt">
                <i class="material-icons" data-v-67fbe2d7=""
                  style="display: flex; margin-right: 5px; align-items: center">verified_user</i>
                Verify 4
              </h1>
              <i class="material-icons" style="color: #111b11">verified_user</i>
            </div>
            <div class="modal-body" style="border-bottom: 20px solid #060a06">
              <ResizeKYC4Component></ResizeKYC4Component>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import CreateAComponent from "../components/CreateAComponent.vue";
import ResizeKYC2Component from "../components/ResizeKYC2Component.vue";
import ResizeKYC3Component from "../components/ResizeKYC3Component.vue";
import ResizeKYC4Component from "../components/ResizeKYC4Component.vue";
import ChatComp from "../components/ChatComp.vue";
import DailySpinComponent from "../components/dailySpinComponent.vue";
import FancyInputComponent from "@/components/FancyInputComponent.vue";
import LoginComponent from "@/components/LoginComponent.vue";

import axios from "axios";
export default {
  name: "modalsComponent",
  data() {
    return {
      tipName: "", // Holds the name input
      tipAmount: null, // Holds the tip amount

      kyc2: { completed: false, image: "", imageUrl: "", agree: 0 },

      inVault: 0.0,
      vaultWithdrawl: 0,
      vaultdeposit: 0,

      vaultdepositmode: 0,
      previewImage_kyc3: null,

      countdown: 11,
      countdownRunning: false,

      biow: "",
      problem_msg: "",
      title: "",
      dob: "",

      counter: 0,
      image: "",
      imageUrl: "",

      intervalId: null,

      firstname: "",
      lastname: "",
      fileKYC2: null,
      fileKYC3: null,
      fileKYC4: null,

      file: null,

      address: { line1: null, line2: null, cityTown: null, postCode: null, country: "" },

      temp_nickname: "",
      temp_bio: "",
    };
  },
  sockets: {
    vault_deposit_response(data) {
      if (data.success == true) {
        this.$root.updateBalance(parseFloat(-data.depositAmount));
        this.vaultdeposit = 0;
        this.inVault += parseFloat(data.depositAmount);
        
        // Add fade out animation and close modal
        const modalEl = document.querySelector('.modal-c');
        if (modalEl) {
          modalEl.classList.add('fade-out');
          setTimeout(() => {
            this.$root.modal_open = false;
          }, 300);
        }
      } else {
        this.$toast.show(data.message, { position: "bottom", duration: 3500 });
      }
    },

    vault_withdraw_response(data) {
      if (data.success == true) {
        this.$root.updateBalance(parseFloat(data.withdrawAmount));
        this.vaultdeposit = 0;
        this.inVault -= parseFloat(data.withdrawAmount);
        
        // Add fade out animation and close modal
        const modalEl = document.querySelector('.modal-c');
        if (modalEl) {
          modalEl.classList.add('fade-out');
          setTimeout(() => {
            this.$root.modal_open = false;
          }, 300);
        }
      } else {
        this.$toast.show(data.message, { position: "bottom", duration: 3500 });
      }
    },

    add_message: function () {
  
      const element = document.getElementById("scrollerrr_chats");
  

      if (element) {
        setTimeout(
          function () {
            element.scrollBy(0, element.scrollHeight);
          }.bind(this),
          10
        );
      }
    },
  },
  components: {
    CreateAComponent,
    ResizeKYC2Component,
    ResizeKYC3Component,
    ResizeKYC4Component,
    DailySpinComponent,
    ChatComp,
    FancyInputComponent,
    LoginComponent
  },
  methods: {
    previewImage: function (event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.kyc2.imageUrl = e.target.result;
          this.kyc2.name = input.files[0].name;
          this.kyc2.size = input.files[0].size;
          console.log(input.files[0]);
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },

    pickFile_Kyc3() {
      let input = this.$refs.fileInputzz;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage_kyc3 = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
      }
    },

    changeblob({ coordinates, canvas }) {
      console.log(coordinates, canvas);
      // this.image = this.$refs.cropper.getResult()

      canvas.toBlob((blob) => {
        var blober = blob;
        var imgb4 = URL.createObjectURL(blober);
        this.previewImage_kyc3 = imgb4;
      });
    },

    kyc2Change() {
      this.$toast.show("uploaded", { position: "bottom", duration: 3500 });
    },

    sumbit_kyc1() {
      if (this.firstname == "") {
        this.$toast.show("Kindly provide your first name", {
          position: "bottom",
          duration: 3500,
        });
      } else if (this.lastname == "") {
        this.$toast.show("Kindly provide your last name", {
          position: "bottom",
          duration: 3500,
        });
      } else if (!this.address.line1) {
        this.$toast.show("Please enter the first line of your address correctly", {
          position: "bottom",
          duration: 3500,
        });
      } else if (!this.address.line2) {
        this.$toast.show(
          "Please enter the second line of your address (if none please type N/A)",
          { position: "bottom", duration: 3500 }
        );
      } else if (!this.address.cityTown) {
        this.$toast.show("Please provide the name of your town or city", {
          position: "bottom",
          duration: 3500,
        });
      } else if (!this.address.postCode) {
        this.$toast.show("Please enter your postal code or zip code", {
          position: "bottom",
          duration: 3500,
        });
      } else {
        axios
          .post(`${this.$parent.API_PREFIX}/api/update_kyc1`, {
            token: this.$cookies.get("token"),
            f_name: this.firstname,
            l_name: this.lastname,
            address: this.address,
            dob: this.dob,
          })
          .then((response) => {
            this.$toast.show(response.data.message, {
              position: "bottom",
              duration: 3500,
            });

            this.$root.modal_open = false;
            this.$root.user.kyc_lvl = 1;
          });
      }
    },

    set_email() {
      let isExecuted = confirm("Are you sure you want to change your email?");

      if (isExecuted) {
        this.$toast.show(
          "Currently, changing your email is not possible. However, in exceptional circumstances, it can be done through support.",
          { position: "bottom", duration: 3500 }
        );
      }
    },
    // Function to increase the wager by 1
    increaseByOne() {
      this.$root.create_game.wager = (
        parseFloat(this.$root.create_game.wager) + 1
      ).toFixed(2);
    },

    // Function to increase the wager by 10
    increaseByTen() {
      this.$root.create_game.wager = (
        parseFloat(this.$root.create_game.wager) + 10
      ).toFixed(2);
    },

    // Function to increase the wager by 100
    increaseByHundred() {
      this.$root.create_game.wager = (
        parseFloat(this.$root.create_game.wager) + 100
      ).toFixed(2);
    },

    // Function to set the wager to the maximum bet
    setMaxBet() {
      // Assuming this.$root.user.balance holds the user's balance
      this.$root.create_game.wager = parseFloat(this.$root.user.balance).toFixed(2);

      if (isNaN(this.$root.create_game.wager)) {
        this.$root.create_game.wager = "1.00"; // Default to 1.00 if NaN
      }
    },
    startCountdown() {
      if (!this.countdownRunning) {
        this.countdownRunning = true;
        this.intervalId = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
          } else {
            this.stopCountdown(); // Call stopCountdown when countdown reaches 0
          }
        }, 1000);
      }
    },
    stopCountdown() {
      if (this.intervalId !== null) {
        clearInterval(this.intervalId);
        this.intervalId = null;
        this.countdownRunning = false;
      }
    },

    // Function to double the wager
    double() {
      this.$root.create_game.wager = (
        parseFloat(this.$root.create_game.wager) * 2
      ).toFixed(2);
    },

    // Function to halve the wager
    half() {
      this.$root.create_game.wager = (
        parseFloat(this.$root.create_game.wager) / 2
      ).toFixed(2);
    },

    update_bio() {
      if (this.$root.bio == this.temp_bio) {
        // this.$toast.show("No modifications made for bio.", { position: "bottom", duration: 3500 });
      } else {
        this.$root.bio = this.temp_bio;

        axios
          .post(`${this.$parent.API_PREFIX}/api/update_bio`, {
            token: this.$cookies.get("token"),
            new_bio: this.$root.bio,
          })
          .then((response) => {
            this.$toast.show(response.data.message, {
              position: "bottom",
              duration: 3500,
            });
          });
      }
    },

    // THIS IS LOCK ACCOUNT RENAME TO LOCK ACCOUNT
    confirmAction24() {
      const expiration = new Date();
      expiration.setDate(expiration.getDate() + 1);

      const requestData = {
        token: this.$cookies.get("token"),
        expiration: expiration.toISOString(),
        reason: "Self Lock - 24 Hours [ self selected ]",
      };

      axios
        .post(`${this.$parent.API_PREFIX}/api/lock_account_self_req`, requestData)
        .then((response) => {
          console.log(response);

          this.modal_open = false;
          this.$router.push({ path: "/coinflip" });
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    confirmAction31() {
      const expiration = new Date();
      expiration.setDate(expiration.getDate() + 31);

      const requestData = {
        token: this.$cookies.get("token"),
        expiration: expiration.toISOString(),
        reason: "Self Lock - 31 Days [ self selected ]",
      };

      axios
        .post(`${this.$parent.API_PREFIX}/api/lock_account_self_req`, requestData)
        .then((response) => {
          console.log(response);

          this.modal_open = false;
          this.$router.push({ path: "/coinflip" });
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    confirmAction7() {
      const expiration = new Date();
      expiration.setDate(expiration.getDate() + 7);

      const requestData = {
        token: this.$cookies.get("token"),
        expiration: expiration.toISOString(),
        reason: "Self Lock - 7 Days  [ self selected ]",
      };

      axios
        .post(`${this.$parent.API_PREFIX}/api/lock_account_self_req`, requestData)
        .then((response) => {
          console.log(response);

          this.modal_open = false;
          this.$router.push({ path: "/coinflip" });
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    vaultDepoist() {
      this.$socket.emit("vault_deposit", {
        token: this.$cookies.get("token"),
        amount: this.vaultdeposit,
      });
    },
    confirmTip() {
      // Ensure name and amount are entered
      if (this.$root.tipName && this.tipAmount > 0) {
        // Open the confirmation modal
        this.$root.modal_open = "confirm_tip";
      } else {
        this.$toast.show("Please enter a valid name and amount.", {
          position: "bottom",
          duration: 1500,
        });
      }
    },
    finalizeTip() {
      // Check if user has enough balance
      if (this.tipAmount > this.$root.user.balance) {
        this.$toast.show("Sorry, you don't have enough money to send this tip :(", {
          position: "bottom",
          duration: 3500,
        });
        return;
      }

      // Prepare the payload
      const tipData = {
        token: this.$cookies.get("token"),
        name: this.$root.tipName,
        amount: this.tipAmount,
      };

      // Send the API request
      axios
        .post(`${this.$parent.API_PREFIX}/api/sendTip`, tipData)
        .then((response) => {
          // Handle success response
          console.log(response);
          this.$toast.show(`Your tip has been successfully sent!`, {
            position: "bottom",
            duration: 3500,
          });
        })
        .catch((error) => {
          // Handle error response
          this.$toast.show(`Failed to send tip: ${error.message}`, {
            position: "bottom",
            duration: 3500,
          });
        })
        .finally(() => {
          // Close all modals and reset inputs
          this.$root.modal_open = false;
          this.tipName = "";

          this.$root.updateBalance(-this.tipAmount);
          this.tipAmount = null;
        });
    },

    vaultwithdraw() {
      this.$socket.emit("vault_withdraw", {
        token: this.$cookies.get("token"),
        amount: this.vaultWithdrawl,
      });
    },

    update_handle() {
      this.$toast.show(
        "This function requires direct contact with support for verification.",
        { position: "bottom", duration: 3500 }
      );

      // axios.post(`${this.$parent.API_PREFIX}/api/update_handle`,  {
      //     token: this.$cookies.get('token'),
      //     handle: this.temp_handle,
      // }).then(response => {
      //     this.$toast.show(response.data.message, { position: "bottom", duration: 3500 });

      // })
    },

    sumbit() {
      if (this.problem_msg == "") {
        this.$toast.show(`No description provided for support ticket.`, {
          position: "bottom",
          duration: 1500,
        });
      } else if (this.title == "") {
        this.$toast.show(`No title provided for support ticket.`, {
          position: "bottom",
          duration: 1500,
        });
      } else if (this.title.length < 3) {
        this.$toast.show(`Support ticket title not long enough.`, {
          position: "bottom",
          duration: 1500,
        });
      } else if (this.problem_msg.length < 100) {
        this.$toast.show(`Support ticket description not long enough.`, {
          position: "bottom",
          duration: 1500,
        });
      } else {
        axios
          .post(`${this.$parent.API_PREFIX}/api/create_support_ticket`, {
            token: this.$cookies.get("token"),
            title: this.title,
            msg: this.problem_msg,
          })
          .then((response) => {
            this.problem_msg = "";
            this.title = "";

            if (response.data.message == "Bad token: Please Login") {
              this.$toast.show(`Please log in or create an account to submit a ticket.`, {
                position: "bottom",
                duration: 1500,
              });
            } else {
              this.$router.push({ path: "/ticket/" + response.data.message });
            }
          });
      }
    },
    onPickFile() {
      this.$refs.fileInput.click();
    },
    onFilePicked(event) {
      const files = event.target.files;
      let filename = files[0].name;
      console.log(filename);
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.imageUrl = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
      this.image = files[0];
    },

    kYC2file(event) {
      const files = event.target.files;
      if (files) {
        let filename = files[0].name;
        console.log(filename);
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
          this.KYC2file_imageUrl = fileReader.result;
        });
        fileReader.readAsDataURL(files[0]);
        this.KYC2file_image = files[0];
      }
    },

    update_nickname() {
      if (this.$root.nickname == this.temp_nickname) {
        // this.$toast.show("No modifications made for nickname.", { position: "bottom", duration: 3500 });
      } else {
        this.$root.nickname = this.temp_nickname;

        axios
          .post(`${this.$parent.API_PREFIX}/api/update_nickname`, {
            token: this.$cookies.get("token"),
            nickname: this.$root.nickname,
          })
          .then((response) => {
            this.$toast.show(response.data.message, {
              position: "bottom",
              duration: 3500,
            });
          });
      }
    },
    pickFile() {
      this.$forceUpdate();
      let input = this.$refs.fileInput;
      let file = input.files;

      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
          this.$forceUpdate();
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
        this.$forceUpdate();
      }
    },
    // Add new method for handling spin completion
    handleSpin() {
      // Add fade out animation and close modal after spin
      setTimeout(() => {
        const modalEl = document.querySelector('.modal-c');
        if (modalEl) {
          modalEl.classList.add('fade-out');
          setTimeout(() => {
            this.$root.modal_open = false;
          }, 300);
        }
      }, 1500); // Wait for spin animation to complete
    },
    openSocialLoginPopup(provider) {
      const width = 500;
      const height = 600;
      const left = (window.screen.width / 2) - (width / 2);
      const top = (window.screen.height / 2) - (height / 2);
      
      // Get the auth URL from the server
      axios.get(`${this.$root.API_PREFIX}/api/auth/${provider}`)
        .then(response => {
          const authUrl = response.data.authUrl;
          const popup = window.open(
            authUrl,
            `${provider}Login`,
            `width=${width},height=${height},left=${left},top=${top}`
          );

          // Poll the popup to check when it's closed
          const pollTimer = window.setInterval(() => {
            if (popup.closed !== false) {
              window.clearInterval(pollTimer);
              // Check if login was successful by checking for token cookie
              if (this.$cookies.isKey("token")) {
                this.$root.modal_open = false;
                this.$root.logged_in = true;
                this.$root.fetch_user();
                this.$router.push("/");
              }
            }
          }, 200);
        })
        .catch(error => {
          console.error('Error initiating social login:', error);
          this.$toast.show(`Failed to initiate ${provider} login. Please try again.`, {
            position: "bottom",
            duration: 3500,
          });
        });
    },
  },
  mounted() {
    this.$watch("$root.modal_open", (newValue) => {
      if (newValue === "lock24" || newValue === "lock7" || newValue === "lock31") {
        this.countdownRunning = false;
        this.stopCountdown();
        this.countdown = 11;
      }
    });

    setTimeout(() => {
      this.temp_nickname = this.$root.nickname;
      this.temp_bio = this.$root.bio;
    }, 3000); // 1000 milliseconds = 1 second

    axios
      .post(`${this.$parent.API_PREFIX}/api/user_detailed`, {
        token: this.$cookies.get("token"),
      })
      .then((response) => {
        this.userdata = response.data;
        this.firstname = response.data.f_name;
        this.lastname = response.data.l_name;

        this.inVault = response.data.vault_bal;

        if (JSON.parse(response.data.address)) {
          this.address = {
            line1: JSON.parse(response.data.address).line1,
            line2: JSON.parse(response.data.address).line2,
            cityTown: JSON.parse(response.data.address).cityTown,
            postCode: JSON.parse(response.data.address).postCode,
            country: JSON.parse(response.data.address).country,
          };
        }

        //      console.log(response.data.address)

        let str = response.data.dob;
        if (str) {
          str = str.substring(0, str.length - 14);
          this.dob = str;
        }
      });
  },
};
</script>

<style scoped>
/* Base modal animations */
.modal-mask {
  opacity: 0;
  animation: fadeIn 0.3s ease-out forwards;
}

.modal-wrapper {
 
  opacity: 0;
  animation: modalEnter 0.3s ease-out 0.1s forwards;
}

.modal-c {
  transform: translateY(20px);
  opacity: 0;
  animation: slideUp 0.3s ease-out 0.2s forwards;
}

/* Field animations */
.ioxrnje, .ioxrnje22, .tilte, input, select, textarea {
  transition: all 0.2s ease-out;
}

.ioxrnje:hover, .ioxrnje22:hover, .tilte:hover, input:hover, select:hover, textarea:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 255, 0, 0.1);
}

.ioxrnje:focus, .ioxrnje22:focus, .tilte:focus, input:focus, select:focus, textarea:focus {
  transform: scale(1.01);
  box-shadow: 0 6px 16px rgba(0, 255, 0, 0.15);
  border-color: #42da3d;
}

/* Button animations */
.btn_create_newz, .sumbit_btn, .sumbit_btn2, .nekweBtn, .sbt {
  transition: all 0.2s ease-out;
}

.btn_create_newz:hover, .sumbit_btn:hover, .sumbit_btn2:hover, .nekweBtn:hover, .sbt:hover {
  transform: translateY(-2px) scale(1.02);
  box-shadow: 0 4px 12px rgba(66, 218, 61, 0.2);
}

.btn_create_newz:active, .sumbit_btn:active, .sumbit_btn2:active, .nekweBtn:active, .sbt:active {
  transform: translateY(1px);
}

/* Card animations */
.carddoe, .smal_card {
  transition: all 0.3s ease-out;
}

.carddoe:hover, .smal_card:hover {
  transform: translateY(-4px) scale(1.02);
  box-shadow: 0 8px 24px rgba(0, 255, 0, 0.15);
}

/* Keyframe animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modalEnter {
  from {

    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Fade transition */
.fade-enter-active, .fade-leave-active {
  transition: all 0.3s ease-out;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
  transition: all 0.3s  !important;
  transform: translateY(20px);
}

/* Existing styles */
.sbt {
  width: 100px;
  background-color: rgb(255 0 0);
  font-family: monospace;
  font-size: 18px;
  padding: 0px;
  color: rgb(255 255 255);
  font-weight: 900;
  border: 1px dashed #000000;
  cursor: pointer;
  border-radius: 10px;
  height: 32px;
}

.sumbit_btn2 {
  width: 50%;
  color: #42da3d;
  font-size: 13px;
  background: #053b24;
  border: 1px dashed #000000;
  cursor: pointer;
  border-radius: 10px;
  height: 32px;
  margin: 0px;
}

.form-group {
  display: flex;
}

.sumbit_btn {
  color: #ffd100;
  font-size: 16px;
  background: #ff900066;
  border: 1px dashed #242424;
  cursor: pointer;
  border-radius: 10px;
  padding: 10px;
  margin: 20px 0px;
}

.IMG_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.countingdonw_btn {
  color: #adadad;
  font-size: 13px;
  background: #2d2d2d;
  border: 1px dashed #000000;
  cursor: pointer;
  border-radius: 10px;
  height: 32px;
  margin: 0px;
  width: 100px;
}

.fancy_file {
  background: black;
  border: 1px solid #25c100;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  color: white;
  font-family: "Gilroy";
}

.flzexr {
  display: flex;
  flex-direction: row;
}

.grey_xt {
  font-size: 13px;
  color: #787878;
  font-style: italic;
  text-align: right;
}

.ioxrnje {
  resize: vertical;
  height: 80px;
  border: 1px solid #464646;
  border-radius: 5px;
  background: #000000;
  display: flex;
  align-items: center;
  color: white;
  margin: 0px;
  font-family: "Gilroy", cursive;
  margin-bottom: 20px;
  width: calc(100% - 40px);
  padding: 10px;
  font-size: 15px;
}

.ioxrnje22 {
  resize: vertical;
  height: 80px;
  border: 1px solid #464646;
  border-radius: 5px;
  background: #000000;
  display: flex;
  align-items: center;
  color: white;
  margin: 0px;
  font-family: "Gilroy", cursive;
  margin-bottom: 20px;
  width: calc(100% - 40px);
  padding: 10px;
  font-size: 15px;
  margin-bottom: 20px;
  width: calc(100% - 22px);
}

.other_logo_small {
  height: 15px;
  margin-left: 7px;
  margin-right: 5px;
}

.chat {
  height: calc(100vh - 50px + (env(safe-area-inset-bottom) * 2)) !important;
}

.eeq_wq {
  flex: auto;
  margin: 0px 20px;
  margin-left: 0px;
  display: flex;
  background: #050505;
  padding: 20px;
  border: 1px solid #898989;
  font-family: "Gilroy", cursive;
  font-size: 16px;
  color: #e1e1e1;
  font-weight: 100;
  resize: both;
}

.flex_bozerrr {
  width: 100%;
  display: flex;
  padding: 10px 0px;
}

.signupbox_ {
  border: 1px solid #575757;
  border-radius: 5px;
  height: 30px;
  background: #060a06;
  display: flex;
  align-items: center;
  color: white;
  font-size: 16px;
  padding: 0px 20px;
  justify-content: center;
  width: 100%;
  width: calc(100% - 30px);
}

.dlex_dipslau {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
}

.or_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile_display {
  display: none !important;
}

.new_mopdal_c {
  height: auto;
  border-radius: 2px;
  transition: all 0.3s ease;
  margin: 0;
  width: 650px;
}

.cc_header {
  width: 100%;
  height: 35px;
  align-items: center;
  justify-content: space-between;
  background-color: #060a06;
  flex-shrink: 0;
  box-sizing: border-box;
  touch-action: none;
  padding: 0px 40px;
  color: white !important;
  box-shadow: unset;
  display: flex;
  padding-top: 30px;
}

.maxBTUN {
  font-family: monospace;
  margin-left: 10px;
  width: 50px;
  cursor: pointer;
  padding: 10px;
  background: #4f9c276e;
  color: #ffffff;
  font-size: 18px;
  border: 0px;
  border-radius: 17px;
  border-top: 4px solid #6db6475e;
}

.chose_photo_box {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  margin-top: 20px;
}

.c_gamebtn_price_holder {
  margin-top: 10px;
  width: 100%;
  text-align: end;
}

.btn_price_cgame {
  color: #fff;
  background: #ffffff17;
  border: 1px dashed #b9b9b9;
  cursor: pointer;
  margin: 0px;
  margin-left: 0px;
  padding: 10px;
  font-size: 12px;
  font-family: monospace;
  margin-left: 10px;
  border-radius: 5px;
  width: 80px;
}

.new_iittx2 {
  font-family: "Gilroy", cursive;
  font-size: 16px;
  color: #ffffff;
  font-weight: 100;
  margin: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.b32eb3 {
  border: 1px solid #b32eb3;
}

.erwq {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 1.5px solid #780062;
  background: #000000;
  border-radius: 10px;
  padding: 20px;
}

.grem_photyo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  align-content: center;
}

.flexr_goldere {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.contents_CENYER {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100vh;
}

.login_btn {
  width: 120px;
  font-family: "Gilroy";
  color: #42da3b;
  font-size: 16px;
  background: #053b24;
  border: 1px dashed #000000;
  cursor: pointer;
  border-radius: 10px;
  margin: 0;
  height: 32px;
  padding: 0px;
}

.e35_con {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.hrhr {
  margin: 30px;
  width: 100%;
  height: 2px;
  background: rgb(17 27 17);
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-bottm {
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-left: 20px;
}

.carder_tpper {
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.card_name {
  font-size: 14px;
}

.smol-icone {
  width: 20px;
}

.smol-icon3e {
  width: 15px;
}

.smal_card {
  height: 100px;
  width: 159px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
  overflow-x: hidden;
  cursor: pointer;
  font-size: 10px;
  font-style: normal;
  text-decoration: none !important;
}

.completed {
  width: auto;
  background: #242424;
  padding: 20px;
  margin: 20px 0px;
  border: 1px solid green;
  border-radius: 10px;
}

.vue-advanced-cropper__background {
  background: #111b11;
}

.vue-advanced-cropper {
  max-height: 400px;
  max-width: 400px;
}

.mainboxbodu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.vault_actioon {
  color: #42da3b;
    font-size: 16px;
    background: #053b24;
    border: 1px dashed #000000;
    cursor: pointer;
    border-radius: 10px;
    margin: 0;

    padding: 10px;
    width: calc(100% - 80px);
}

.imagePreviewWrapper {
  width: 250px;
  height: 250px;
  display: block;
  cursor: pointer;
  margin: 0 auto 30px;
  background-size: cover;
  background-position: center center;
}

.new321231213 {
  background: #1c1c1c;
  padding: 10px;
  display: flex;
  color: #ffffff;
  border-radius: 5px;
  font-family: monospace;
  font-size: 18px;
  margin: 0px;
  margin-bottom: 20px;
  font-variant-caps: all-small-caps;
  outline: none !important;
  background: black;
}

.card_box {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  width: 100%;
}

.box_2fa4vvvv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  align-content: center;
}

.gunembrak {
  border: 1px solid #454545;
  border-radius: 5px px;
  height: 30px;
  background: #060a06;

  align-items: center;
  color: white;
  font-size: 18px;
  font-family: monospace;
  padding: 6px 10px;
  border-radius: 10px;
  justify-content: center;
  margin: 10px;
}

.none4 {
  color: #2dd107fa;
  background: none !important;
  border: none;
  cursor: pointer;
  margin: 0px;
  padding: 10px;
  width: 80px;
  font-size: 18px;
  font-family: monospace;
  margin-left: 10px;
  border-radius: 5px;
  width: 100%;
}

.con_box {
  padding: 20px;
  margin-bottom: 20px;
  background: #111b11;
  border: 1px solid #7a546b;
  color: #e8e8e8;
  display: flex;
  border-radius: 10px;
}

.b32eb_gen3 {
  border: 1px solid #2f6838;
}

.con_box5 {
  margin-bottom: 15px;
  border: 1px solid #262626;
  border-radius: 5px;
  height: 30px;
  background: #111b11;
  display: flex;
  align-items: center;
  color: white;
  font-size: 21px;
  font-family: "Gilroy";
  padding: 5px 20px;
  justify-content: center;
  width: calc(100% - 40px);
}

.widebodyBtn {
  margin-top: 10px !important;
  margin: 0px;
}

.none {
  border: none;
  background: none;
  color: white;
  font-size: 24px;
  width: 100%;
  outline: none !important;
}

.form-controlee {
  border: none;
  background: none;
  color: white;
  font-size: 24px;
  width: 100%;
  outline: none !important;
}

.carddoe {
  margin-bottom: 10px;

  padding: 5px;
  border-radius: 3px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}

.wdnhwdafis {
  border: 1px solid #25c100;
  border-radius: 5px;
  background: #111b11;
  display: flex;
  align-items: center;
  color: white;
  font-size: 18px;
  font-family: monospace;
  padding: 0px 20px;
  justify-content: center;
  margin: 0px;
  height: 45px;
  margin-top: 20px;
}

.boxjkr {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.add_new_addy {
  color: #42da3b;
  background: #268126;
  border: 1px dashed #242424;
  cursor: pointer;
  width: 105px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 18px;
  padding: 13px;
  margin: 0px;
  height: 45px;
  font-family: "Gilroy", cursive;
  margin-bottom: 20px;
}

.new_iittx {
  font-family: "Gilroy", cursive;
  font-size: 24px;
  color: #ffffff;
  font-weight: 100;
  margin: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.email_innner_box2 {
  width: 100%;
  padding: 0px;
  border-radius: 5px;
  height: 30px;
  background: #111b11;
  display: flex;
  align-items: center;
  color: white;
  font-size: 21px;
  font-family: "Gilroy";
  justify-content: center;
  border: none;
  font-size: 16px;
}

.vualtBtn {
  text-decoration: none;
  padding: 6px 25px;
  width: 122px;
  margin: 0px auto;
  margin-bottom: 30px;
  margin-top: 20px;
  color: #71d175;
  font-size: 16px;
  background: #5faa5821;
  border: 1px dashed #242424;
  cursor: pointer;
  outline: 3px solid;
  border-radius: 10px;
}

.cancel_game_idontwant {
  color: #000000;
  font-size: 16px;
  background: #c81c05;
  border: 1px dashed #242424;
  cursor: pointer;
  border-radius: 10px;
  width: 105px;
  margin: 0px;
  margin-left: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.iconz {
  width: 30px;
  height: 30px;
  margin: 7px;
  margin-left: -2px;
}

.pointer {
  cursor: pointer;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.what_is_2fa {
  display: flex;
  background: #363636;
  margin-bottom: 20px;
}

.btn_create_newz {
  color: #42da3b;
  font-size: 16px;
  background: #268126;
  border: 1px dashed #242424;
  cursor: pointer;
  border-radius: 10px;
  width: 105px;
  margin: 0px;
}

#Green {
  border: 1px solid #053b24 !important;
  background: #053b24;
  color: white;
}

#Purple {
  border: 1px solid #92198c !important;
  background: #580458;
  color: white;
}

#Pink {
  border: 1px solid deeppink;
  background: #000000;
}

#Orange {
  border: 1px solid #f59c1a !important;
  background: #a35200;
  color: white;
}

#Blue {
  border: 1px solid #0f1ea8 !important;
  background: #0c0e48;
  color: white;
}

#Black {
  border: 1px solid #707070 !important;
  background: #161616;
  color: white;
}

#White {
  border: 1px solid #a3a199 !important;
  background: #c5c5c5;
  color: black;
}

.modal-c {
  background: #060a06;
  width: auto;
  height: 100%;
  margin: 0px auto;
  border-radius: 2px;
  box-shadow: 0 0px 48px rgb(0 0 0 / 90%);
  transition: all 0.3s ease;

  max-width: 800px;
}

.login_btndew {
  font-family: "Gilroy";
  color: #42da3b;
  background: #053b24;
  border: 1px dashed #000000;

  font-size: 16px;

  cursor: pointer;
  border-radius: 10px;
  width: 105px;
  margin: 0px;
}

.modal-body {
  font-family: "Gilroy", cursive;
  font-size: 16px;
  color: #dedede;
  width: 90%;
  margin: 20px auto;
}

.new_iitt {
  font-family: "Gilroy", cursive;
  font-size: 24px;
  color: #ffffff;
  font-weight: 100;
  margin: 0px;
  display: flex;
}

.img_dp_2000x {
  height: 70px;
  width: 70px;
  background: rgb(44, 44, 44);
  border: 1px solid white;
  outline: 1px solid black;
}

.checkout {
  margin: 20px;
  padding-bottom: 20px;
  margin-left: 0px;
  text-align: center;
}

.view_boxy {
  margin-bottom: 5px;
  border: 1px solid #262626;
  border-radius: 5px;
  height: 30px;
  background: #000000;
  display: flex;
  align-items: center;
  color: #36d83b;
  font-family: "Gilroy";
  margin-left: 15px;
  justify-content: center;
  padding: 20px 20px;
  margin-right: 0px;
}

.icon_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.idcon_field2 {
  color: #fafafa;
  margin-top: auto;
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: stretch;
  flex-direction: row;
  align-content: flex-start;
  flex-wrap: wrap;
  width: 23%;
}

.title_tc2 {
  background-color: #111b11;
}

.box_ {
  height: 170px;
  width: 170px;
  margin-bottom: 20px;
  text-align: center;
}

.lesserBTN {
  color: #fff;
  background: #0000004f;
  border: 1px dashed #b9b9b9;
  cursor: pointer;
  margin: 0px;
  padding: 5px;
  font-size: 16px;
  font-family: monospace;
  margin-left: 10px;
  border-radius: 5px;
  width: 50px;
}

.tilte {
  border: 1px solid #25c100;
  border-radius: 5px;
  background: #111b11;
  display: flex;
  align-items: center;
  color: white;
  font-size: 18px;
  padding: 0px;
  padding-left: 20px;
  margin: 0px;
  height: 45px;
  font-family: "Gilroy", cursive;
  margin-bottom: 20px;
  width: calc(100% - 22px);
}

.h-bar-0-mobile {
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  background-color: #111b11;
  flex-shrink: 0;
  box-sizing: border-box;
  touch-action: none;
  padding: 0px 20px;
  color: white !important;
  box-shadow: unset;
  display: flex;
}

.secltor {
  opacity: 0.7;
  outline: none;background: #010201;
}

.x_btn {
  background: none;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
  color: #ffffff99;
}

.dk_m {
  display: flex;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  width: 25px;
  /* Set a specific slider handle width */
  height: 25px;
  /* Slider handle height */
  background: #268126;
  /* Green background */
  cursor: pointer;
  /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 25px;
  /* Set a specific slider handle width */
  height: 25px;
  /* Slider handle height */
  background: #268126;
  /* Green background */
  cursor: pointer;
  /* Cursor on hover */
}

.wityhfdraw {
  color: #42da3b;
  font-size: 16px;
  background: #268126;
  border: 1px dashed #242424;
  cursor: pointer;
  border-radius: 10px;
  margin: 0px;
  width: 130px !important;
}

.creedit_card {
  background: #053b24;
  height: 100px;
  width: 159px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
  border: 1px solid #053b24;
  margin-right: 20px;
  font-size: 10px;
  font-style: normal;
  text-decoration: none !important;
}

.modal-container {
  padding: 0px;
}

.realbar_address {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
}

.card-bottm {
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-left: 20px;
}

.carder_tpper {
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.smol-icone {
  width: 20px;
}

.nekweBtn {
  color: #000;
  font-size: 10.7px;
  background: #d8d8d8;
  border: 1px dashed #777;
  cursor: pointer;
  border-radius: 10px;
  margin: 0;
}

.smol-icon3e {
  width: 15px;
}

.fone {
  display: none;
}

.modal-c {
  background: #060a06;
  width: auto;
  height: auto;
  margin: 0px auto;
  border-radius: 2px;
  box-shadow: 0 0px 48px rgb(0 0 0 / 90%);
  transition: all 0.3s ease;

  max-width: 800px;
}

@media only screen and (max-width: 600px) {
  .lhm {
    line-height: 40px;
  }

  .dk_m {
    display: none;
  }

  .card_name {
    font-size: 10px;
  }

  .lesserBTN {
    padding: 3px;
    font-size: 13px;
    margin-left: 5px;
  }

  .btn_price_cgame {
    width: 50px;
    margin-left: 5px;
    font-size: 13px;
  }

  .fone {
    display: flex;
  }

  .modal-c {
    height: 100%;
  }

  .mobile_display {
    display: flex !important;
  }

  .other_text {
    display: none;
  }

  .icon_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 20px 0px;
  }

  .hifer {
    display: none;
  }

  .chat {
    height: calc(90vh - 50px + (env(safe-area-inset-bottom) * 2)) !important;
  }

  .idcon_field2 {
    color: #fafafa;
    margin-top: auto;
    display: flex;
    padding: 5px;
    justify-content: center;
    align-items: stretch;
    flex-direction: row;
    align-content: flex-start;
    flex-wrap: wrap;
    width: 45%;
  }
}

.boady {
  display: flex;
  flex-direction: column;
}

/* Glass effect for modal */
.glass-effect {
  background: rgba(17, 27, 17, 0.95);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(66, 218, 61, 0.1);
  box-shadow: 0 8px 32px rgba(0, 255, 0, 0.1);
}

/* Vault container styles */
.vault-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.vault-balance-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.vault-icon-container {
  position: relative;
  transition: all 0.3s ease;
}

.vault-icon {
  height: 80px;
  transition: all 0.3s ease;
}

.vault-icon:hover {
  transform: scale(1.05);
}

.pulse-animation {
  animation: pulse 2s infinite;
}

.balance-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.balance-label {
  color: #888;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.balance-amount {
  display: flex;
  align-items: center;
  gap: 8px;
  background: rgba(66, 218, 61, 0.1);
  padding: 12px 24px;
  border-radius: 12px;
  border: 1px solid rgba(66, 218, 61, 0.2);
}

.balance-number {
  font-size: 24px;
  font-weight: 600;
  color: #42da3d;
}

/* Action buttons */
.vault-actions {
  display: flex;
  gap: 12px;
  justify-content: center;
}

.action-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  border-radius: 8px;
  border: 1px solid rgba(66, 218, 61, 0.2);
  background: rgba(66, 218, 61, 0.05);
  color: #888;
  cursor: pointer;
  transition: all 0.3s ease;
}

.action-button:hover {
  background: rgba(66, 218, 61, 0.1);
  transform: translateY(-2px);
}

.action-button.active {
  background: rgba(66, 218, 61, 0.2);
  border-color: #42da3d;
  color: #42da3d;
}

/* Transaction section */
.transaction-section {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 20px;
  opacity: 0;
  transform: translateY(20px);
}

.transaction-section.slide-in {
  animation: slideIn 0.3s ease forwards;
}

.section-title {
  color: #42da3d;
  font-size: 18px;
  margin-bottom: 16px;
  text-align: center;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.quick-actions {
  display: flex;
  gap: 8px;
  justify-content: center;
}

.quick-action-btn {
  padding: 8px 16px;
  border-radius: 6px;
  border: 1px solid rgba(66, 218, 61, 0.2);
  background: rgba(66, 218, 61, 0.05);
  color: #888;
  cursor: pointer;
  transition: all 0.2s ease;
}

.quick-action-btn:hover {
  background: rgba(66, 218, 61, 0.1);
  transform: translateY(-2px);
}

.quick-action-btn.max-btn {
  background: rgba(66, 218, 61, 0.2);
  border-color: #42da3d;
  color: #42da3d;
}

.submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding: 14px;
  border-radius: 8px;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 16px;
}

.submit-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(66, 218, 61, 0.2);
}

.deposit-btn {
  background: #42da3d;
  border: none;
  color: #000;
}

.withdraw-btn {
  background: #111b11;
  border: 1px solid #42da3d;
  color: #42da3d;
}

/* Animations */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.shine-effect {
  position: relative;
  overflow: hidden;
}

.shine-effect::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: rotate(45deg);
  animation: shine 3s infinite;
}

@keyframes shine {
  0% {
    transform: translateX(-100%) rotate(45deg);
  }
  100% {
    transform: translateX(100%) rotate(45deg);
  }
}

.animated-icon {
  transition: all 0.3s ease;
}

.animated-icon:hover {
  transform: translateX(-4px);
  color: #42da3d;
}

.glow-text {
  text-shadow: 0 0 10px rgba(66, 218, 61, 0.5);
  animation: glow 2s infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px rgba(66, 218, 61, 0.5);
  }
  to {
    text-shadow: 0 0 20px rgba(66, 218, 61, 0.8);
  }
}

/* Existing styles */
.vault-icon-wrap {
  margin-bottom: 20px;
}

.vault-icon {
  height: 60px;
  transition: transform 0.2s ease;
}

.vault-icon:hover {
  transform: scale(1.05);
}

.vault-balance {
  display: inline-flex;
  align-items: center;
  background: #111b11;
  padding: 5px 10px;
  border-radius: 10px;
  border: 2px solid rgb(6, 10, 6);
}

.vault-actions {
  text-align: center;
  margin: 20px 0;
}

.transaction-form {
  margin-top: 20px;
}

.input-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input-label {
  display: block;
  margin-bottom: 5px;
}

.action-buttons {
  display: flex;
  margin-top: 20px;
}

.vault_actioon.withdraw {
  border-color: rgb(255, 141, 0);
  color: #ffffff;
  background: #060606;
  border: 1px dashed #777777;
}

/* Add new animations */
@keyframes fade-out {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
}

.fade-out {
  animation: fade-out 0.3s ease-out forwards;
}

/* Update existing transitions */
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scale(0.95);
}

/* Add these styles at the end of the style section */
.social-login-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 15px 0;
  width: 100%;
}

.social-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #262626;
  background: #111b11;
  color: #ffffff;
  font-family: "Gilroy";
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.social-btn:hover {
  background: #1a271a;
}

.social-btn i {
  font-size: 20px;
}

.google-btn {
  background: #111b11;
  border-color: #262626;
}

.google-btn:hover {
  background: #1a271a;
}

.steam-btn {
  background: #111b11;
  border-color: #262626;
}

.steam-btn:hover {
  background: #1a271a;
}

@media only screen and (max-width: 600px) {
  .social-login-buttons {
    gap: 8px;
  }
  
  .social-btn {
    padding: 8px;
    font-size: 14px;
  }
}
</style>