<template>
  <div class="oauth-callback">
    <div v-if="loading" class="loading-container">
      <div class="spinner"></div>
    </div>

    <div v-else-if="error" class="error-container">
      <h2>Error</h2>
      <p>{{ error }}</p>
      <button @click="$router.push('/')" class="retry-btn">Return to Home</button>
    </div>

    <div v-else-if="needsUsername" class="username-container">
      <div class="welcome-content">
        <div class="welcome-header">
          <h1>Welcome to CryptoFlip.gg!</h1>
          <div class="user-avatar">
            <span>{{ userInfo?.name?.charAt(0) || '?' }}</span>
          </div>
          <h2>Hi, {{ userInfo?.name }}!</h2>
        </div>

        <div class="welcome-message">
          <p>This is your first time here - let's get you set up!</p>
          <p class="subtitle">Choose a unique username that others will know you by</p>
        </div>
        
        <div class="input-group">
          <div class="input-wrapper">
            <i class="material-icons input-icon">person</i>
            <input 
              v-model="username"
              placeholder="Choose your username"
              :class="{'error': usernameError}"
              @input="usernameError = ''"
              @keyup.enter="createAccount"
            />
          </div>
          <p v-if="usernameError" class="error-text">
            <i class="material-icons error-icon">error_outline</i>
            {{ usernameError }}
          </p>
          <p class="input-help">Username can contain letters, numbers, and underscores</p>
        </div>

        <button 
          @click="createAccount"
          :disabled="!username || loading"
          class="submit-btn"
        >
          <span>Let's Play!</span>
          <i class="material-icons">arrow_forward</i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'OAuthCallback',
  data() {
    return {
      loading: true,
      error: '',
      needsUsername: false,
      username: '',
      usernameError: '',
      userInfo: null
    }
  },
  async created() {
    try {
      // Get URL parameters
      const params = new URLSearchParams(window.location.search);
      const code = params.get('code');
      const state = params.get('state');
      const scope = params.get('scope');
      const authuser = params.get('authuser');
      const prompt = params.get('prompt');
      
      // Parse the state JSON
      let parsedState;
      try {
        parsedState = JSON.parse(decodeURIComponent(state));
      } catch (e) {
        parsedState = { error: 'Invalid state parameter format' };
      }

      // Send OAuth data to backend
      const response = await axios.post(`${this.$root.API_PREFIX}/api/google_oauth_reply`, {
        code,
        state: parsedState,
        scope,
        authuser,
        prompt
      });
      
      if (response.data.success) {
        if (response.data.existing_user) {
          // Existing user - set token and redirect
          this.$cookies.set('token', response.data.token);
          this.$router.push('/');
          window.location.reload(); // Reload the page
        } else {
          // New user - need username
          this.needsUsername = true;
          this.userInfo = response.data.user_info;
        }
      } else {
        this.error = response.data.message || 'Failed to process login';
      }
    } catch (error) {
      console.error('Error in OAuth callback:', error);
      this.error = error.response?.data?.message || 'An error occurred during login';
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async createAccount() {
      if (!this.username) {
        this.usernameError = 'Username is required';
        return;
      }

      this.loading = true;
      try {
        const response = await axios.post(`${this.$root.API_PREFIX}/api/create_google_user`, {
          ...this.userInfo,
          username: this.username
        });

        if (response.data.success) {
          this.$cookies.set('token', response.data.token);
          this.$router.push('/');
          window.location.reload(); // Reload the page
        } else {
          this.usernameError = response.data.message;
        }
      } catch (error) {
        if (error.response?.data?.message === 'Username already taken') {
          this.usernameError = 'This username is already taken';
        } else {
          this.error = error.response?.data?.message || 'Failed to create account';
        }
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>
.oauth-callback {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(135deg, #1a1a1a 0%, #0a0a0a 100%);
  padding: 20px;
}

.loading-container,
.error-container,
.username-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(10px);
}

.welcome-content {
  background: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(20px);
  border-radius: 20px;
  padding: 40px;
  width: 100%;
  max-width: 600px;
  text-align: center;
  box-shadow: 
    0 8px 32px rgba(0, 0, 0, 0.2),
    0 0 0 1px rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.welcome-header {
  margin-bottom: 40px;
}

.welcome-header h1 {
  background: linear-gradient(135deg, #3ac634 0%, #2a8c25 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 2.5em;
  margin-bottom: 30px;
  font-weight: 700;
  text-shadow: none;
}

.user-avatar {
  width: 80px;
  height: 80px;
  background: linear-gradient(135deg, #3ac634 0%, #2a8c25 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
  font-size: 2.5em;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  box-shadow: 0 4px 20px rgba(58, 198, 52, 0.3);
  border: 2px solid rgba(255, 255, 255, 0.1);
}

.welcome-header h2 {
  color: #fff;
  font-size: 1.8em;
  margin-bottom: 10px;
  font-weight: 500;
}

.welcome-message {
  margin-bottom: 40px;
}

.welcome-message p {
  color: #fff;
  font-size: 1.2em;
  margin-bottom: 10px;
}

.welcome-message .subtitle {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1em;
}

.input-group {
  margin: 30px 0;
}

.input-wrapper {
  position: relative;
  margin-bottom: 10px;
}

.input-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #42da3b;
}

input {
  width: 100%;
  padding: 16px 16px 16px 48px;
  border: 2px solid rgba(66, 218, 59, 0.3);
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 1.1em;
  transition: all 0.3s ease;
}

input:focus {
  border-color: #42da3b;
  outline: none;
  box-shadow: 0 0 0 3px rgba(66, 218, 59, 0.2);
}

input.error {
  border-color: #ff4444;
}

.error-text {
  color: #ff4444;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 8px;
  font-size: 0.9em;
}

.error-icon {
  font-size: 18px;
}

.input-help {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.9em;
  margin-top: 8px;
}

.submit-btn {
  background: linear-gradient(135deg, #3ac634 0%, #2a8c25 100%);
  color: #fff;
  border: none;
  padding: 16px 40px;
  border-radius: 12px;
  font-size: 1.2em;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 0 auto;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 
    0 4px 15px rgba(58, 198, 52, 0.3),
    0 0 0 1px rgba(58, 198, 52, 0.5);
}

.submit-btn:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 
    0 6px 20px rgba(58, 198, 52, 0.4),
    0 0 0 1px rgba(58, 198, 52, 0.6);
}

.submit-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.loading-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(10px);
}

.spinner {
  border: 4px solid rgba(66, 218, 59, 0.1);
  border-left-color: #42da3b;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Loading and Error states */
.loading-container,
.error-container {
  text-align: center;
  padding: 40px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  backdrop-filter: blur(10px);
}

.retry-btn {
  background: #42da3b;
  color: #000;
  border: none;
  padding: 12px 30px;
  border-radius: 8px;
  font-size: 1.1em;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 20px;
}

.retry-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(66, 218, 59, 0.3);
}
</style> 