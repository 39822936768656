<template>
  <div class="steam-callback">
    <div v-if="loading" class="loading-container">
      <div class="spinner"></div>
    </div>

    <div v-else-if="error" class="error-container">
      <h2>Error</h2>
      <p>{{ error }}</p>
      <button @click="$router.push('/')" class="retry-btn">Return to Home</button>
    </div>

    <div v-else-if="showRegistrationForm" class="registration-container">
      <div class="welcome-content">
        <div class="welcome-header">
          <h1>Welcome to CryptoFlip.gg!</h1>
          <div class="user-avatar">
            <img :src="steamData.avatar" alt="Steam Avatar" />
          </div>
          <h2>Hi, {{ steamData.personaname }}!</h2>
        </div>

        <div class="welcome-message">
          <p>This is your first time here - let's get you set up!</p>
          <p class="subtitle">Choose a unique username that others will know you by</p>
        </div>
        
        <div class="input-group">
          <div class="input-wrapper">
            <i class="material-icons input-icon">person</i>
            <input 
              v-model="registrationForm.username"
              placeholder="Choose your username"
              :class="{'error': formErrors.username}"
              @input="formErrors.username = ''"
              @keyup.enter="handleRegistration"
            />
          </div>
          <p v-if="formErrors.username" class="error-text">
            <i class="material-icons error-icon">error_outline</i>
            {{ formErrors.username }}
          </p>
          <p class="input-help">Username can contain letters, numbers, and underscores</p>
        </div>

        <div class="input-group" v-if="registrationForm.email !== undefined">
          <div class="input-wrapper">
            <i class="material-icons input-icon">email</i>
            <input 
              v-model="registrationForm.email"
              placeholder="Email (Optional)"
              :class="{'error': formErrors.email}"
              type="email"
            />
          </div>
          <p v-if="formErrors.email" class="error-text">
            <i class="material-icons error-icon">error_outline</i>
            {{ formErrors.email }}
          </p>
        </div>

        <button 
          @click="handleRegistration"
          :disabled="!registrationForm.username || isRegistering"
          class="submit-btn"
        >
          <span>{{ isRegistering ? 'Creating Account...' : 'Let\'s Play!' }}</span>
          <i class="material-icons">arrow_forward</i>
        </button>
      </div>
    </div>

    <div v-else class="loading-container">
      <div class="spinner"></div>
      <p>Verifying Steam login...</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'SteamCallback',
  data() {
    return {
      openidMode: '',
      claimedId: '',
      identity: '',
      steamId: '',
      returnTo: '',
      responseNonce: '',
      assocHandle: '',
      signed: '',
      signature: '',
      showRegistrationForm: false,
      steamData: null,
      registrationForm: {
        username: '',
        email: ''
      },
      formErrors: {
        username: '',
        email: ''
      },
      isRegistering: false,
      loading: true,
      error: null
    }
  },
  watch: {
    'registrationForm.username'(newValue) {
      // Replace spaces and special characters with underscores
      const sanitized = newValue.replace(/[^a-zA-Z0-9]/g, '_');
      if (sanitized !== newValue) {
        this.registrationForm.username = sanitized;
      }
    }
  },
  created() {
    // Parse URL parameters
    const params = new URLSearchParams(window.location.search);
    
    // Extract OpenID parameters
    this.openidMode = params.get('openid.mode');
    this.claimedId = params.get('openid.claimed_id');
    this.identity = params.get('openid.identity');
    this.returnTo = params.get('openid.return_to');
    this.responseNonce = params.get('openid.response_nonce');
    this.assocHandle = params.get('openid.assoc_handle');
    this.signed = params.get('openid.signed');
    this.signature = params.get('openid.sig');

    // Extract Steam ID from claimed_id
    if (this.claimedId) {
      this.steamId = this.claimedId.split('/').pop();
    }

    // Automatically start authentication process
    this.handleSteamCallback();
  },
  methods: {
    validateForm() {
      let isValid = true;
      this.formErrors = {
        username: '',
        email: ''
      };

      // Check username length
      if (!this.registrationForm.username) {
        this.formErrors.username = 'Username is required';
        isValid = false;
      } else if (this.registrationForm.username.length < 3 || this.registrationForm.username.length > 20) {
        this.formErrors.username = 'Username must be between 3 and 20 characters';
        isValid = false;
      }

      // Validate email if provided
      if (this.registrationForm.email && !this.validateEmail(this.registrationForm.email)) {
        this.formErrors.email = 'Please enter a valid email address';
        isValid = false;
      }

      return isValid;
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return !email || re.test(String(email).toLowerCase());
    },
    async handleRegistration() {
      if (!this.validateForm()) return;

      this.isRegistering = true;
      try {
        const response = await axios.post(`${this.$root.API_PREFIX}/api/register_steam_user`, {
          username: this.registrationForm.username,
          email: this.registrationForm.email,
          steamId: this.steamData.steamId
        });

        if (response.data.success) {
          // Set authentication token
          this.$cookies.set('token', response.data.token);
          
          // Update root state and redirect without reload
          this.$root.logged_in = true;
          await this.$root.fetch_user();
          this.$router.replace('/');
        }
      } catch (error) {
        const errorMessage = error.response?.data?.message || 'Registration failed. Please try again.';
        this.$toast.show(errorMessage, {
          position: "bottom",
          duration: 3500,
        });
      } finally {
        this.isRegistering = false;
      }
    },
    async handleSteamCallback() {
      try {
        // Convert URL search params to an object and ensure all parameters are included
        const searchParams = new URLSearchParams(window.location.search);
        const params = {};
        
        // Extract all openid parameters
        for (const [key, value] of searchParams.entries()) {
          if (key.startsWith('openid.')) {
            params[key] = value;
          }
        }

        // Log the parameters being sent for debugging
        console.log('Sending Steam auth parameters:', params);
        
        // Check if we have the required OpenID parameters
        if (!params['openid.mode'] || !params['openid.claimed_id']) {
          throw new Error('Invalid Steam authentication parameters');
        }
        
        const response = await axios.post(`${this.$root.API_PREFIX}/api/auth_steam_callback`, params, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        
        console.log('Steam auth response:', response.data);
        
        if (response.data.success) {
          if (response.data.existing_user) {
            // Set authentication token for existing user
            this.$cookies.set('token', response.data.token);
            
            // Show success message
            this.$toast.show('Login successful!', {
              position: "bottom",
              duration: 3500,
            });
            
            // Update root state and redirect without reload
            this.$root.logged_in = true;
            await this.$root.fetch_user();
            this.$router.replace('/');
          } else {
            // Show registration form for new user
            this.steamData = response.data.steam_data;
            this.showRegistrationForm = true;
            // Pre-fill username with Steam username
            this.registrationForm.username = this.steamData.personaname;
          }
        } else {
          throw new Error(response.data.message || 'Authentication failed');
        }
      } catch (error) {
        console.error('Steam authentication error:', error);
        console.error('Error details:', error.response?.data);
        this.$toast.show(error.response?.data?.error || error.message || 'Authentication failed. Please try again.', {
          position: "bottom",
          duration: 3500,
        });
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>
.steam-callback {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(135deg, #1a1a1a 0%, #0a0a0a 100%);
  padding: 20px;
}

.loading-container,
.error-container,
.registration-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(10px);
}

.welcome-content {
  background: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(20px);
  border-radius: 20px;
  padding: 40px;
  width: 100%;
  max-width: 600px;
  text-align: center;
  box-shadow: 
    0 8px 32px rgba(0, 0, 0, 0.2),
    0 0 0 1px rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.welcome-header {
  margin-bottom: 40px;
}

.welcome-header h1 {
  background: linear-gradient(135deg, #3ac634 0%, #2a8c25 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 2.5em;
  margin-bottom: 30px;
  font-weight: 700;
  text-shadow: none;
}

.user-avatar {
  width: 80px;
  height: 80px;
  margin: 0 auto 20px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(58, 198, 52, 0.3);
  border: 2px solid rgba(255, 255, 255, 0.1);
}

.user-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.welcome-header h2 {
  color: #fff;
  font-size: 1.8em;
  margin-bottom: 10px;
  font-weight: 500;
}

.welcome-message {
  margin-bottom: 40px;
}

.welcome-message p {
  color: #fff;
  font-size: 1.2em;
  margin-bottom: 10px;
}

.welcome-message .subtitle {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1em;
}

.input-group {
  margin: 30px 0;
}

.input-wrapper {
  position: relative;
  margin-bottom: 10px;
}

.input-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #42da3b;
}

input {
width: -webkit-fill-available;
  padding: 16px 16px 16px 48px;
  border: 2px solid rgba(66, 218, 59, 0.3);
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 1.1em;
  transition: all 0.3s ease;
}

input:focus {
  border-color: #42da3b;
  outline: none;
  box-shadow: 0 0 0 3px rgba(66, 218, 59, 0.2);
}

input.error {
  border-color: #ff4444;
}

.error-text {
  color: #ff4444;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 8px;
  font-size: 0.9em;
}

.error-icon {
  font-size: 18px;
}

.input-help {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.9em;
  margin-top: 8px;
}

.submit-btn {
  background: linear-gradient(135deg, #3ac634 0%, #2a8c25 100%);
  color: #fff;
  border: none;
  padding: 16px 40px;
  border-radius: 12px;
  font-size: 1.2em;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 0 auto;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 
    0 4px 15px rgba(58, 198, 52, 0.3),
    0 0 0 1px rgba(58, 198, 52, 0.5);
}

.submit-btn:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 
    0 6px 20px rgba(58, 198, 52, 0.4),
    0 0 0 1px rgba(58, 198, 52, 0.6);
}

.submit-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.spinner {
  border: 4px solid rgba(66, 218, 59, 0.1);
  border-left-color: #42da3b;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.loading-container,
.error-container {
  text-align: center;
  padding: 40px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  backdrop-filter: blur(10px);
}

.retry-btn {
  background: #42da3b;
  color: #000;
  border: none;
  padding: 12px 30px;
  border-radius: 8px;
  font-size: 1.1em;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 20px;
}

.retry-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(66, 218, 59, 0.3);
}
</style> 