<template>
  <div class="homer">
    <div class="h-bar-0-mobile">
      <h1 class="new_top_head" style="text-decoration: none">Signup</h1>
    </div>

    <img
      src="../assets/Welcome.png"
      class="m_welcome"
      style="width: 135px; margin-bottom: 10px"
    />
    <div class="welcome-text" v-if="$root.step === 1">
      <h2>Welcome!</h2>
      <p>Enter your email and username to get started</p>
    </div>

    <div v-if="$root.step == 2" class="buccbutton" @click="$root.step = 1">
      <i class="material-icons hifer">arrow_back</i>Back
    </div>
    <div v-if="$root.step === 1">
      <div style="color: white; filter: grayscale(0.5)">
        <div class="con_box" style="background: black">
          <i class="material-icons">email</i>
          <input
            style="background: none !important"
            class="email_innner_box"
            v-model="$root.credentials.email"
            type="text"
            placeholder="Email"
            name="eml"
            required
          />
        </div>
      </div>

      <div class="con_box" style="background: black">
        <i class="material-icons">account_box</i>
        <input
          style="background: none"
          class="email_innner_box"
          v-model="$root.credentials.username"
          type="text"
          placeholder="Username"
          name="uname"
          required
        />
      </div>

      <button 
        class="login_btn1" 
        @click="nextStep"
        :disabled="isLoading"
      >
        <span>Next</span>
        <span v-if="isLoading" class="spinner"></span>
      </button>
      
      <div class="social-login-container">
        <div class="divider">
          <span>or continue with</span>
        </div>
        <div class="social-buttons-row">
          <button 
            type="button"
            class="social-btn google-btn" 
            @click.prevent="$root.handleSocialLogin('google')"
          >
            <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=" alt="Google" class="social-icon"/>
            Continue with Google
          </button>
          <button 
            type="button"
            class="social-btn steam-btn" 
            @click.prevent="$root.handleSocialLogin('steam')"
          >
            <img src="https://upload.wikimedia.org/wikipedia/commons/8/83/Steam_icon_logo.svg" alt="Steam" class="social-icon"/>
            Continue with Steam
          </button>
        </div>
      </div>

      <div class="irp">
        <span>Have an account?</span>
        <a class="log" @click="$root.modal_open = 'login'">Login</a>
      </div>
    </div>

    <div v-if="$root.step === 2">
      <!-- Username and password screen -->

      <div class="name-container">
        <div class="name-field">
          <input
            class="con_box"
            v-model="$root.credentials.f_name"
            type="text"
            placeholder="First name"
            required
          />
        </div>
        <div class="name-field">
          <input
            class="con_box"
            v-model="$root.credentials.l_name"
            type="text"
            placeholder="Last name"
            required
          />
        </div>
      </div>

      <div class="con_box" style="    background: rgb(48, 53, 51);
    outline: none !important;">
        D.o.B:
        <input
          class="email_innner_box2"
          style="
            padding-right: 0px;
            margin-left: 20px;
            width: 60px;
            outline: none !important;
          "
    
          placeholder="Date"
          name="dob"
          v-model="$root.credentials.day_birth"
          @change="checkAge"
          required
        />
        <select
          style="outline: none !important"
          v-model="$root.credentials.month_birth"
          class="email_innner_box2"
          @change="checkAge"
        >
          <option
            style="background-color: #f0f0f0; color: #000"
            value=""
            disabled
            selected
          >
            Month
          </option>
          <option style="background-color: #f0f0f0; color: #000" value="Jan">
            January
          </option>
          <option style="background-color: #f0f0f0; color: #000" value="Feb">
            February
          </option>
          <option style="background-color: #f0f0f0; color: #000" value="Mar">
            March
          </option>
          <option style="background-color: #f0f0f0; color: #000" value="Apr">
            April
          </option>
          <option style="background-color: #f0f0f0; color: #000" value="May">
            May
          </option>
          <option style="background-color: #f0f0f0; color: #000" value="Jun">
            June
          </option>
          <option style="background-color: #f0f0f0; color: #000" value="Jul">
            July
          </option>
          <option style="background-color: #f0f0f0; color: #000" value="Aug">
            August
          </option>
          <option style="background-color: #f0f0f0; color: #000" value="Sep">
            September
          </option>
          <option style="background-color: #f0f0f0; color: #000" value="Oct">
            October
          </option>
          <option style="background-color: #f0f0f0; color: #000" value="Nov">
            November
          </option>
          <option style="background-color: #f0f0f0; color: #000" value="Dec">
            December
          </option>
        </select>
        <input
          class="email_innner_box2"
          v-model="$root.credentials.year_birth"
          style="
            outline: none !important;
            margin-left: 10px;
            padding-right: 0px;
            width: 70px;
          "
      
          placeholder="Year"
          name="dob"
          @change="checkAge"
          required
        />
      </div>
      <div v-if="dobError" class="error-message">
        You must be 18 years or older to create an account
      </div>

      <div class="dweeew">
        <div class="password-field">
          <label class="password-label">Password:</label>
          <div class="password-input-container">
            <input
              class="con_box"
              v-model="$root.password"
              :type="show_passworde ? 'text' : 'password'"
              style="background: rgb(48, 53, 51); outline: none !important;"
              placeholder="●●●●●●●"
              @input="validatePassword"
              required
            />
            <button class="view_boxy" @click="toggle_password_show">
              <i v-if="!show_passworde" class="material-icons">remove_red_eye</i>
              <i v-else class="material-icons">visibility_off</i>
            </button>
          </div>
        </div>
      </div>

      <div
        class="wrong_password_box2"
        v-if="$root.password_check.length != 0 && $root.password"
      >
        <a style="color: rgb(207, 15, 15)">Password needs:</a
        ><a
          style="color: rgb(231, 172, 8)"
          v-for="check in $root.password_check"
          v-bind:key="check.id"
          >{{ check[0] }}</a
        >
      </div>

      <!-- reCAPTCHA container -->
      <div class="recaptcha-wrapper">
        <div 
          id="create-account-recaptcha"
          class="g-recaptcha"
          data-sitekey="6LdsKf0qAAAAAF7j40ocbMTpNseApSy1NEuD0pl6"
          data-theme="dark"
          data-callback="onRecaptchaComplete"
        ></div>
      </div>

      <div style="margin-bottom: 15px" class="txt__">
        <div class="checkbox-container">
          <input
            type="checkbox"
            id="jack"
            value="Jack"
            style="cursor: pointer"
            v-model="$root.credentials.accept_terms"
          />
          <label class="check_font" for="jack"
            >I Agree To
            <router-link
              style="
                background-color: black !important;
                color: white !important;
              "
              to="/terms-of-service"
              >Terms of Service</router-link
            > </label
          >
        </div>
        <div class="checkbox-container">
          <input
            type="checkbox"
            style="cursor: pointer"
            id="john"
            value="John"
            v-model="$root.credentials.over_18"
          />
          <label class="check_font" for="john">I am 18+ years of age</label>
        </div>
      </div>

      <button
        class="login_btn1"
        @click="nextStep"
        :disabled="isLoading"
        v-if="$root.step === 1"
      >
        <span>Next</span>
        <span v-if="isLoading" class="spinner"></span>
      </button>

      <button
        class="login_btn1"
        style="margin-bottom: 10px"
        @click="create_account()"
        :disabled="isLoading || (password_check.length != 0 && $root.password)"
        v-if="$root.step === 2"
        type="submit"
      >
        <span>Create Account</span>
        <span v-if="isLoading" class="spinner"></span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "CreateAComponent",
  data() {
    return {
      password_check: [],
      checkedNames: [],
      show_passworde: false,
      recaptchaResponse: null,
      isLoading: false,
      dobError: false
    };
  },
  watch: {
    '$root.step': {
      handler(newVal) {
        if (newVal === 2) {
          this.$nextTick(() => {
            this.loadRecaptcha();
          });
        }
      },
      immediate: true
    }
  },
  created() {
    // Add the callback to window object
    window.onRecaptchaComplete = (token) => {
      console.log('reCAPTCHA callback received');
      if (token && typeof token === 'string') {
        this.recaptchaResponse = token;
        console.log('Valid token received, length:', token.length);
      } else {
        console.error('Invalid token received');
        this.recaptchaResponse = null;
      }
    };
  },
  methods: {
    loadRecaptcha() {
      // Remove any existing reCAPTCHA scripts and elements
      const existingScript = document.querySelector('script[src*="recaptcha"]');
      if (existingScript) {
        existingScript.remove();
      }

      // Create and load the reCAPTCHA script
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js';
      script.async = true;
      script.defer = true;
      script.onload = () => {
        console.log('reCAPTCHA script loaded successfully');
      };
      document.head.appendChild(script);

      // Add callback for reCAPTCHA response
      window.recaptchaCallback = (response) => {
        console.log('reCAPTCHA verified');
        this.recaptchaResponse = response;
      };
    },
    validatePassword() {
      if (this.$root.password && this.$root.password_check.length === 0) {
        // Password is valid
      }
    },
    toggle_password_show() {
      this.show_passworde = !this.show_passworde;
    },
    async nextStep() {
      this.isLoading = true;
      try {
        // Check if all fields in step 1 are filled
        if (!this.$root.credentials.username || !this.$root.credentials.email) {
          this.$toast.show("Please fill in all the details", {
            position: "bottom",
            duration: 1500,
          });
          this.isLoading = false;
          return;
        }

        if (!/^[a-zA-Z0-9_]*$/.test(this.$root.credentials.username)) {
          this.$toast.show(
            "Invalid Username, Please use only letters, numbers, and underscores for the username. You may NOT use '-'",
            {
              position: "bottom",
              duration: 3500,
            }
          );
          this.isLoading = false;
          return;
        }

        if (this.$root.validateEmail(this.$root.credentials.email)) {
          this.$toast.show(`This is not a valid email.`, {
            position: "bottom",
            duration: 1500,
          });
          this.isLoading = false;
          return;
        }

        if (this.$root.credentials.username.length < 5) {
          this.$toast.show(`You must have more than 4 letters in your username`, {
            position: "bottom",
            duration: 1500,
          });
          this.isLoading = false;
          return;
        }

        if (this.$root.credentials.username.length > 20) {
          this.$toast.show(
            `You must can't have more than 20 letters in your username`,
            { position: "bottom", duration: 1500 }
          );
          this.isLoading = false;
          return;
        }

        // Send request to check username and email
        const response = await axios.post(`${this.$root.API_PREFIX}/api/validate_userandEmail`, {
          username: this.$root.credentials.username,
          email: this.$root.credentials.email
        });

        if (response.data.message === "valid") {
          this.$root.step = 2; // Proceed to the next step
          // Initialize reCAPTCHA after step change
          this.$nextTick(() => {
            this.loadRecaptcha();
          });
        } else {
          this.$toast.show(response.data.message, {
            position: "bottom",
            duration: 1500,
          });
        }
      } catch (error) {
        console.error('Validation error:', error);
        this.$toast.show(error.response?.data?.message || 'An error occurred during validation. Please try again.', {
          position: "bottom",
          duration: 3500,
        });
      } finally {
        this.isLoading = false;
      }
    },
    isUpper(str) {
      return !/[A-Z]/.test(str);
    },
    checkAge() {
      if (this.$root.credentials.day_birth && this.$root.credentials.month_birth && this.$root.credentials.year_birth) {
        const birthDate = new Date(
          this.$root.credentials.year_birth,
          this.getMonthNumber(this.$root.credentials.month_birth),
          this.$root.credentials.day_birth
        );
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        
        this.dobError = age < 18;
        if (this.dobError) {
          this.$root.credentials.over_18 = false;
        } else {
          this.$root.credentials.over_18 = true;
        }
      }
    },
    async create_account() {
      try {
        if (!this.recaptchaResponse) {
          this.$toast.show('Please complete the reCAPTCHA verification', {
            position: "bottom",
            duration: 3000
          });
          return;
        }

        const birthday =
          this.$root.credentials.day_birth +
          "/" +
          this.$root.credentials.month_birth +
          "/" +
          this.$root.credentials.year_birth;

        if (this.$root.credentials.email == null) {
          this.$toast.show(`Kindly provide an email address.`, {
            position: "bottom",
            duration: 1500,
          });
        } else if (this.$root.credentials.username == null) {
          this.$toast.show(`Please enter an username`, {
            position: "bottom",
            duration: 1500,
          });
        } else if (this.$root.password == null) {
          this.$toast.show(`Please enter an password`, {
            position: "bottom",
            duration: 1500,
          });
        } else if (
          this.$root.credentials.day_birth == null ||
          this.$root.credentials.year_birth == null ||
          this.$root.credentials.month_birth == ""
        ) {
          this.$toast.show(`Kindly provide an Date of Birth.`, {
            position: "bottom",
            duration: 1500,
          });
        } else if (
          this.$root.credentials.day_birth == "" ||
          this.$root.credentials.year_birth == "" ||
          this.$root.credentials.month_birth == ""
        ) {
          this.$toast.show(`Kindly provide an Date of Birth.`, {
            position: "bottom",
            duration: 1500,
          });
        } else if (this.$root.credentials.f_name == null) {
          this.$toast.show(`Please enter a First Name`, {
            position: "bottom",
            duration: 1500,
          });
        } else if (this.$root.credentials.l_name == null) {
          this.$toast.show(`Please enter a Last Name`, {
            position: "bottom",
            duration: 1500,
          });
        } else if (!/^[a-zA-Z0-9_]*$/.test(this.$root.credentials.username)) {
          this.$toast.show(
            "Invalid Username, Please use only letters, numbers, and underscores for the username. You may NOT use '-'",
            {
              position: "bottom",
              duration: 3500,
            }
          );
        } else if (this.$root.credentials.username.length < 5) {
          this.$toast.show(`You must have more than 4 letters in your username`, {
            position: "bottom",
            duration: 1500,
          });
        } else if (this.$root.credentials.username.length > 20) {
          this.$toast.show(
            `You must can't have more than 20 letters in your username`,
            { position: "bottom", duration: 1500 }
          );
        } else if (
          this.$root.credentials.day_birth < 0 ||
          this.$root.credentials.day_birth > 31
        ) {
          this.$toast.show(`The provided birthday date is invalid.`, {
            position: "bottom",
            duration: 1500,
          });
        } else if (
          this.$root.credentials.year_birth < 1915 ||
          this.$root.credentials.year_birth > 2017
        ) {
          this.$toast.show(`This is not a valid birth year.`, {
            position: "bottom",
            duration: 1500,
          });
        } else if (this.$root.validateEmail(this.$root.credentials.email)) {
          this.$toast.show(`This is not a valid email.`, {
            position: "bottom",
            duration: 1500,
          });
        } else if (this.password_check != "") {
          this.$toast.show(`Password not strong enough ${this.password_check}`, {
            position: "bottom",
            duration: 1500,
          });
        } else if (!this.$root.password) {
          this.$toast.show("You must enter a password", {
            position: "bottom",
            duration: 1500,
          });
        } else if (this.$root.credentials.accept_terms == false) {
          this.$toast.show(
            `Please accept the terms of service to make an account`,
            { position: "bottom", duration: 1500 }
          );
        } else if (this.$root.credentials.over_18 == false) {
          this.$toast.show(
            `You must be 18+, and not from USA, UK, Australia or New Zealand`,
            { position: "bottom", duration: 1500 }
          );
        } else {
          axios
            .post(`${this.$root.API_PREFIX}/api/create_account`, {
              email: this.$root.credentials.email,
              username: this.$root.credentials.username,
              password: this.$root.password,
              f_name: this.$root.credentials.f_name,
              l_name: this.$root.credentials.l_name,
              dob: birthday,
              recaptchaToken: this.recaptchaResponse
            })
            .then((response) => {
              // Reset reCAPTCHA after attempt
              if (window.grecaptcha) {
                window.grecaptcha.reset();
              }
              
              if (response.data.token) {
                this.$cookies.set("token", response.data.token);
                this.$root.logged_in = true;
                this.$root.fetch_user();
                this.$router.push({ path: "/coinflip" });
              }
              if (response.data.error) {
                this.$toast.show(response.data.error, {
                  position: "bottom",
                  duration: 1500,
                });
                if (response.data.error == "Sorry but this username is taken") {
                  this.$root.credentials.username = "";
                }
              } else {
                this.$root.modal_open = false;
                this.$router.push({ path: "/coinflip" });
                window.location.reload();
              }
            });
          this.$root.credentials.password = "";
        }
      } catch (error) {
        console.error('Error creating account:', error);
        this.$toast.show('Failed to create account. Please try again.', {
          position: "bottom",
          duration: 3500,
        });
        // Reset reCAPTCHA on error
        if (window.grecaptcha) {
          window.grecaptcha.reset();
        }
      }
    },
    getMonthNumber(monthStr) {
      const months = {
        'Jan': 0, 'Feb': 1, 'Mar': 2, 'Apr': 3, 'May': 4, 'Jun': 5,
        'Jul': 6, 'Aug': 7, 'Sep': 8, 'Oct': 9, 'Nov': 10, 'Dec': 11
      };
      return months[monthStr] || 0;
    },
    async createAccount() {
      if (!this.recaptchaResponse) {
        this.$toast.show('Please complete the reCAPTCHA verification', {
          position: "bottom",
          duration: 3000
        });
        return;
      }

      try {
        const response = await axios.post("/api/create_account", {
          ...this.credentials,
          recaptchaResponse: this.recaptchaResponse
        });
        
        if (response.data.error) {
          this.$toast.show(response.data.error, {
            position: "bottom",
            duration: 3000
          });
          return;
        }

        // Handle successful account creation
        this.$root.token = response.data.token;
        this.$root.logged_in = true;
        this.$root.closeModal();
      } catch (error) {
        this.$toast.show(error.response?.data?.error || 'Error creating account', {
          position: "bottom",
          duration: 3000
        });
      }
    }
  },
  beforeUnmount() {
    // Clean up
    delete window.onRecaptchaComplete;
    const recaptchaElement = document.getElementById('create-account-recaptcha');
    if (recaptchaElement) {
      recaptchaElement.innerHTML = '';
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.loadRecaptcha();
    });
  }
};
</script>

<style scoped>
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.warning_txtt {
  font-size: 12px;
  color: grey;
  font-style: oblique;
}

.dweeew {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.email_innner_box {
  border-radius: 5px;
  height: 30px;
  background: #111b11;
  display: flex;
  align-items: center;
  color: white !important;
  font-size: 21px;
  font-family: "Gilroy";
  padding: 5px 20px;
  justify-content: center;
  width: calc(100% - 40px);
  border: none;
  outline: none;
}
.homer {
  max-width: 1280px;
  margin: 0px;
  width: 100%;
}
.check_font {
  color: #d2d2d2 !important;

  font-family: "Gilroy";
  font-size: 16px;
}
.irp {
  border: 1px solid #575757;
  border-radius: 5px;
  height: 30px;
  background: #060a06;
  display: flex;
  align-items: center;
  color: white;
  font-size: 16px;
  padding: 0px 20px;
  justify-content: center;
}
.new_top_head {
  font-family: "Gilroy", cursive;
  font-weight: 100;
  font-style: normal;
  letter-spacing: 0px;
  font-size: 24px;
  color: #ffffff;
}

.h-bar-0-mobile {
  display: none;
}
@media only screen and (max-width: 600px) {
  .h-bar-0-mobile {
    height: 50px;
    align-items: center;
    justify-content: center;
    background-color: #111b11;
    flex-shrink: 0;
    box-sizing: border-box;
    touch-action: none;
    padding: 0px 20px;
    color: white !important;
    box-shadow: unset;
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
  }
  .homer {
    max-width: 1280px;
    margin: auto;
    width: 90%;
  }
  ._bisde {
    margin: 0px;
  }
}

.big_wrapper {
  display: flex;
  align-self: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  margin-top: -50px;
}
.btn_wrapper {
  width: 1600px !important;
  padding: 0px;
  margin: auto;
  margin-bottom: auto;
  margin-bottom: 25px;
}

.btn_style {
  text-align: center;
  padding: 10px 30px;
  font-family: monospace;
  color: #00ffdb;
  background: #202020;
  border: 1px solid white;
}

.main_text2 {
  color: aliceblue;
  font-family: monospace;
  font-variant: petite-caps;
  font-size: 120px;
  font-style: oblique;
  text-decoration: blink;
  background: -webkit-linear-gradient(#fafafa, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
  margin: 15px 0px;
}
.a_ref_cf {
  width: 100%;
  text-align: center;
  display: block;
}
.logozw {
  width: 90%;
  margin-top: 30px;
  padding: 20px;
  background: #373737;
  box-shadow: 5px 16px 150px 5px #050b1047;
  color: white;
  border: 1px solid #281616;
  border-radius: 8px;
  max-width: 350px;
}
.wrappere {
  display: flex;
  justify-content: space-between;
}
.home {
  max-width: 1280px;
  margin: auto;
  margin-top: auto;
  margin-bottom: auto;
  width: 80%;
  margin-bottom: 50px;
}

.e2side {
  width: 100%;
}

.txt__ {
  font-size: 15px;
  font-family: monospace;
  color: white;
}
.container {
  display: flex;
  flex-direction: row;
  max-width: 920px;
  width: 100%;
  justify-content: space-between;
}
.looloo {
  width: 470px;
}
._bisde {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 49%;
}

.buccbutton {
  display: flex;
  background: #171917;
  cursor: pointer;
  justify-content: center;
  width: 100px;
  margin: 20px;
  padding: 10px 7px;
  margin-left: 0px;
  margin-top: 0px;
  border-radius: 12px;
  border: 2px dashed grey;
}
.eqw__holder {
  flex: none;
  background: #8c8c8c;
  outline: 3px solid #2d2d2d;
  box-shadow: 5px 16px 150px 5px #050b10;
  color: white;
  width: 1600px;
  padding: 0px;
  margin: auto;
}
.text_for_h2 {
  padding-bottom: 10px;
  font-family: monospace;
}
.wrong_password_box2 {
  margin: 0px;
  background: #3838383d;
  color: #686868;
  border: 1px solid;
  padding: 4px;
  font-family: "Gilroy", cursive;
  text-align: center;
  font-size: 14px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}
b {
  color: white;
}
.con_box {
  margin-bottom: 15px;
  border: 1px solid #262626;
  border-radius: 5px;
  height: 30px;
  background: #111b11;
  display: flex;
  align-items: center;
  color: #a8a8a8;
  font-size: 21px;
  font-family: "Gilroy";
  padding: 5px 20px;
  justify-content: center;
  width: calc(100% - 40px);

  font-size: 18px;
}
.login_btn {
  color: aliceblue;
  font-family: monospace;
  width: 100%;
  background: #228d04;
  padding: 19px 0px;
  text-align: center;
  margin-top: 20px;
  border: 1px solid;
  border-radius: 11px;
  width: 280px;
}
.log {
  color: #66d345;
}

.login_btn1 {
  position: relative;
  width: 100%;
  padding: 14px;
  background: linear-gradient(135deg, #42da3d 0%, #2b8f28 100%);
  border: none;
  border-radius: 4px;
  color: white;
  font-family: "Gilroy", sans-serif;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: 20px 0;
}

.login_btn1:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background: linear-gradient(135deg, #3ab936 0%, #246f22 100%);
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 8px;
  vertical-align: middle;
  border: 3px solid #ffffff;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.main_text {
  color: aliceblue;
  font-family: monospace;
  background: #686868;
  padding: 20px 20px;
  margin-top: 20px;
  margin-top: 0px;
}

.links {
  color: lightgrey !important;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-direction: row;
}
.router-link-exact-active {
  font-weight: 500;
  background-color: #4da24d !important;
  color: #8cff64 !important;
}
.btn__ {
  color: #000000;
  font-size: 13px;
  background: #d8d8d8;
  border: 1px dashed #777777;
  cursor: pointer;
  border-radius: 10px;
  padding: 10px;
  text-decoration: none;
  margin-right: 10px;
}
.h_link {
  color: #b8b8b8;
}
.logoz {
  width: 51%;
}

.email_innner_box2 {
  width: 100%;
  padding: 0px;
  border-radius: 5px;
  height: 30px;
  background: #111b11;
  display: flex;
  align-items: center;
  color: white;
  font-size: 21px;
  font-family: "Gilroy";
  justify-content: center;
  border: none;
  font-size: 16px;    background: inherit;
  color: #8d8d8d;
}

.spacer {
  color: aliceblue;
  font-family: monospace;
  padding: 14px;
}
.l_bar {
  width: 100%;
  height: 2px;
  background: #42b983;
  margin: 10px 0px;
  margin-top: 30px;
}

.view_boxy {
  margin-bottom: 5px;
  border: 1px solid #262626;
  border-radius: 5px;
  height: 30px;
  background: #000000;
  display: flex;
  cursor: pointer;
  align-items: center;
  color: #36d83b;
  font-family: "Gilroy";
  margin-left: 15px;
  justify-content: center;
  padding: 20px 20px;
  margin-right: 0px;
}
.l2_bar {
  width: 100%;
  height: 2px;
  background: #939393;
  margin: 10px 0px;
  margin-top: 30px;
}
.links2 {
  color: lightgrey !important;
  justify-content: space-evenly;
  margin: auto;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;
}

h1 {
  margin: 40px 0 0;
  text-align: center;
  display: flex;
  font-size: 30px;
  font-family: monospace;
  color: #9c9c9c;
  font-style: oblique;
  text-decoration: underline;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.legal_tc {
  margin-top: 20px;
  font-family: monospace;
  color: #bcbcbc;
  width: 733px;
}
.hello {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .hello {
    display: flex;
    margin-top: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
  .m_welcome {
    width: 105px !important;
    text-align: center;
    margin-top: 20px;

  }

  .btn__ {
    margin: 0;
    margin-bottom: 20px;
    padding: 6px;
    font-size: 12px;
  }
  .weq_eqw {
    margin-top: 20px;
  }
}
.home {
  margin-top: 0px !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
}

.social-login-container {
  margin: 20px 0;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 15px 0;
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #262626;
}

.divider span {
  padding: 0 10px;
  color: #8d8d8d;
  font-size: 14px;
  font-family: "Gilroy";
}

.social-buttons-row {
  display: flex;
  gap: 12px;
  width: 100%;
}

.social-btn {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 16px;
  border-radius: 4px;
  background: #111b11;
  border: 1px solid #262626;
  color: #ffffff;
  font-family: "Gilroy", sans-serif;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 140px;
}

.social-btn:hover {
  background: #1a271a;
  border-color: #42da3d;
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(66, 218, 61, 0.1);
}

.social-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.name-container {
  display: flex;
  gap: 12px;
  width: 100%;
  margin-bottom: 15px;
}

.name-field {
  flex: 1;
}

.con_box {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #42da3d;
  border-radius: 4px;
  background: #1a1e1a;
  color: white;
  font-size: 16px;
  font-family: "Gilroy", sans-serif;
  transition: all 0.2s ease;
}

.con_box:focus {
  outline: none;
  border-color: #5aff54;
  box-shadow: 0 0 0 2px rgba(90, 255, 84, 0.1);
  background: #1f231f;
}

.login_btn1 {
  width: 100%;
  padding: 14px;
  background: linear-gradient(135deg, #42da3d 0%, #2b8f28 100%);
  border: none;
  border-radius: 4px;
  color: white;
  font-family: "Gilroy", sans-serif;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: 20px 0;
}

.login_btn1:hover {
  background: linear-gradient(135deg, #4aef44 0%, #32a52f 100%);
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(66, 218, 61, 0.2);
}

.login_btn1:active {
  transform: translateY(0);
}

.irp {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px;
  border: 1px solid #262626;
  border-radius: 4px;
  background: #111b11;
  margin-top: 20px;
}

.irp span {
  color: #8d8d8d;
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
}

.log {
  color: #42da3d;
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  cursor: pointer;
  transition: color 0.2s ease;
}

.log:hover {
  color: #4aef44;
}

.recaptcha-wrapper {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #111b11;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid #262626;
}

/* Dark theme overrides for reCAPTCHA */
:deep(.grecaptcha-badge) {
  filter: invert(0.9) hue-rotate(180deg);
}

:deep(.rc-anchor-dark) {
  background-color: #111b11 !important;
  border: 1px solid #42da3d !important;
}

:deep(.rc-anchor-dark-text) {
  color: #ffffff !important;
}

:deep(.rc-anchor-logo-img) {
  filter: invert(0.9) hue-rotate(180deg);
}

:deep(.rc-anchor-logo-text) {
  color: #ffffff !important;
}

.welcome-text {
  text-align: center;
  margin-bottom: 20px;
}

.welcome-text h2 {
  color: #ffffff;
  font-family: "Gilroy", sans-serif;
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 8px 0;
}

.welcome-text p {
  color: #8d8d8d;
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  margin: 0;
}

.error-message {
  color: #ff4444;
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  margin: -10px 0 15px 0;
  text-align: center;
}

.password-field {
  width: 100%;
}

.password-label {
  display: block;
  color: #d2d2d2;
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  margin-bottom: 8px;
}

.password-input-container {
  display: flex;
  align-items: stretch;
  width: 100%;
}

.dweeew {
  margin-bottom: 15px;
}

.dweeew .con_box {
  flex: 1;
  margin-bottom: 0;
}

.g-recaptcha {
  margin: 20px auto;
  display: flex;
  justify-content: center;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkbox-container input[type="checkbox"] {
  margin-right: 10px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.check_font {
  color: #d2d2d2 !important;
  font-family: "Gilroy";
  font-size: 16px;
  cursor: pointer;
}
</style>
